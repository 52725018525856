import * as React from 'react'

import { cn } from '@/shared/utils/cn'
import { Button } from '@/shared/ui/Button'
import { InChevronDown } from '@/shared/ui/Icons/InChevronDown'
import { useState } from 'react'
import { generatePages } from '@/shared/ui/Pagination/lib/generatePages'
import { Select, SelectOption } from '@/shared/ui/Select'
import { FaEllipsis } from 'react-icons/fa6'

const PaginationRoot = ({ className, ...props }: React.ComponentProps<'nav'>) => (
    <nav role="navigation" aria-label="pagination" className={cn('flex w-full', className)} {...props} />
)
PaginationRoot.displayName = 'PaginationRoot'

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
    ({ className, ...props }, ref) => (
        <ul ref={ref} className={cn('flex flex-row items-center gap-1', className)} {...props} />
    ),
)
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({ className, ...props }, ref) => (
    <li ref={ref} className={className} {...props} />
))
PaginationItem.displayName = 'PaginationItem'

const PaginationPrevious = ({ className, ...props }: React.ComponentProps<typeof Button>) => (
    <Button
        aria-label="Go to previous page"
        variant="ghost"
        className={cn('p-0 m-0 h-9 w-9 border border-border bg-background-secondary text-foreground-secondary', className)}
        {...props}
    >
        <InChevronDown size={12} className="rotate-90" />
    </Button>
)
PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationJumpFirst = ({ className, ...props }: React.ComponentProps<typeof Button>) => (
    <Button
        aria-label="Go to first page"
        variant="ghost"
        className={cn('p-0 m-0 h-9 w-9 border border-border bg-background-secondary text-foreground-secondary', className)}
        {...props}
    >
        <InChevronDown size={12} className="rotate-90 translate-x-1/4" />
        <InChevronDown size={12} className="rotate-90 -translate-x-1/4" />
    </Button>
)
PaginationJumpFirst.displayName = 'PaginationJumpFirst'

const PaginationNext = ({ className, ...props }: React.ComponentProps<typeof Button>) => (
    <Button
        aria-label="Go to next page"
        variant="ghost"
        className={cn('p-0 m-0 h-9 w-9 border border-border bg-background-secondary text-foreground-secondary', className)}
        {...props}
    >
        <InChevronDown size={12} className="-rotate-90" />
    </Button>
)
PaginationNext.displayName = 'PaginationNext'

const PaginationJumpLast = ({ className, ...props }: React.ComponentProps<typeof Button>) => (
    <Button
        aria-label="Go to last page"
        variant="ghost"
        className={cn('p-0 m-0 h-9 w-9 border border-border bg-background-secondary text-foreground-secondary', className)}
        {...props}
    >
        <InChevronDown size={12} className="-rotate-90 translate-x-1/4" />
        <InChevronDown size={12} className="-rotate-90 -translate-x-1/4" />
    </Button>
)
PaginationJumpLast.displayName = 'PaginationJumpLast'

type PaginationPageProps = {
    page: number
    isActive?: boolean
} & React.ComponentProps<typeof Button>

const PaginationPage = ({ className, isActive, page, ...props }: PaginationPageProps) => (
    <Button
        aria-current={isActive ? 'page' : undefined}
        className={cn('p-0 m-0 h-9 w-9', className)}
        variant={isActive ? 'default' : 'ghost'}
        {...props}
    >
        {page}
    </Button>
)
PaginationPage.displayName = 'PaginationPage'

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
    <span aria-hidden className={cn('flex h-9 w-9 items-center justify-center', className)} {...props}>
        <span>...</span>
        <span className="sr-only">More pages</span>
    </span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'

const PAGINATION_LIMIT_OPTIONS: SelectOption[] = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '30', label: '30' },
    { value: '40', label: '40' },
    { value: '50', label: '50' },
]

const PaginationLimit = ({
    options,
    defaultValue = '10',
    wrapperClassName,
    triggerClassName,
    iconWrapperClassName,
    ...props
}: React.ComponentProps<typeof Select>) => {
    return (
        <Select
            options={options || PAGINATION_LIMIT_OPTIONS}
            wrapperClassName={cn('shrink-0 w-16', wrapperClassName)}
            triggerClassName={cn('px-2', triggerClassName)}
            iconWrapperClassName={cn('pointer-events-none', iconWrapperClassName)}
            iconClassName="mr-2"
            defaultValue={defaultValue}
            {...props}
        />
    )
}
PaginationLimit.displayName = 'PaginationLimit'

export type PaginationProps = {
    totalPages: number

    defaultPageIndex?: number
    pageIndex?: number
    onPageChange?: (pageIndex: number) => void

    siblings?: number
    disabled?: boolean

    rootClassName?: string
    rootProps?: Omit<React.ComponentProps<typeof PaginationRoot>, 'className'>

    contentClassName?: string
    contentProps?: Omit<React.ComponentProps<typeof PaginationContent>, 'className'>

    itemClassName?: string
    /** Temporarily removed, to add later */
    // itemPropsBuilder?: (item: number | 'ellipsis' | 'next' | 'prev') => React.ComponentProps<typeof PaginationItem>

    prevClassName?: string
    prevProps?: Omit<React.ComponentProps<typeof PaginationPrevious>, 'className'>

    nextClassName?: string
    nextProps?: Omit<React.ComponentProps<typeof PaginationNext>, 'className'>

    ellipsisClassName?: string
    ellipsisPropsBuilder?: (index: number) => React.ComponentProps<typeof PaginationEllipsis>

    pageClassName?: string
    pagePropsBuilder?: (page: number, index: number) => Omit<React.ComponentProps<typeof PaginationPage>, 'page'>
}

const Pagination = ({
    totalPages,

    defaultPageIndex,
    pageIndex,
    onPageChange,

    siblings = 1,
    disabled,

    rootClassName,
    rootProps,

    contentClassName,
    contentProps,

    itemClassName,

    prevClassName,
    prevProps,

    nextClassName,
    nextProps,

    ellipsisClassName,
    ellipsisPropsBuilder,

    pageClassName,
    pagePropsBuilder,
}: PaginationProps) => {
    const [innerPageIndex, setInnerPageIndex] = useState(defaultPageIndex ?? 0)
    const onInnerPageChange = (newPage: number) => {
        if (pageIndex === undefined) {
            setInnerPageIndex(newPage)
        }
        onPageChange?.(newPage)
    }
    const actualPageIndex = pageIndex !== undefined ? pageIndex : innerPageIndex

    const onPrevious = () => {
        if (actualPageIndex > 0) {
            onInnerPageChange(actualPageIndex - 1)
        }
    }

    const onNext = () => {
        if (actualPageIndex < totalPages - 1) {
            onInnerPageChange(actualPageIndex + 1)
        }
    }

    return (
        <PaginationRoot className={rootClassName} {...rootProps}>
            <PaginationContent className={contentClassName} {...contentProps}>
                <PaginationItem className={itemClassName}>
                    <PaginationPrevious
                        onClick={onPrevious}
                        className={prevClassName}
                        {...prevProps}
                        disabled={prevProps?.disabled ?? disabled}
                    />
                </PaginationItem>

                {generatePages(actualPageIndex, totalPages, siblings).map((item, index) => {
                    if (item === 'ellipsis') {
                        const ellipsisProps = ellipsisPropsBuilder?.(index)
                        return (
                            <PaginationItem key={index} className={itemClassName}>
                                <PaginationEllipsis
                                    {...ellipsisProps}
                                    className={cn(ellipsisClassName, ellipsisProps?.className)}
                                />
                            </PaginationItem>
                        )
                    }

                    const pageProps = pagePropsBuilder?.(item, index)

                    return (
                        <PaginationItem key={index} className={itemClassName}>
                            <PaginationPage
                                {...pageProps}
                                page={item}
                                onClick={(e) => {
                                    onInnerPageChange?.(item - 1)
                                    pageProps?.onClick?.(e)
                                }}
                                isActive={pageProps?.isActive ?? actualPageIndex + 1 === item}
                                disabled={pageProps?.disabled ?? disabled}
                                className={cn(pageClassName, pageProps?.className)}
                            />
                        </PaginationItem>
                    )
                })}

                <PaginationItem className={itemClassName}>
                    <PaginationNext
                        onClick={onNext}
                        className={nextClassName}
                        {...nextProps}
                        disabled={nextProps?.disabled ?? disabled}
                    />
                </PaginationItem>
            </PaginationContent>
        </PaginationRoot>
    )
}
Pagination.displayName = 'Pagination'

export {
    Pagination,
    PaginationRoot,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationJumpFirst,
    PaginationPrevious,
    PaginationNext,
    PaginationJumpLast,
    PaginationLimit,
}
