import React from 'react'
import { SWRConfig } from 'swr'
import { HttpV2 } from '@shared/apiv2/api'

type SWRProviderProps = {
    children?: React.ReactNode
}

export const SWRFetcher = (url: string, params?: any) => HttpV2.get(url, { params }).then((res) => res.data)

export const SWRProvider = ({ children }: SWRProviderProps) => {
    return (
        <SWRConfig
            value={{
                fetcher: SWRFetcher,
                revalidateOnFocus: false,
                onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                    if (error.response?.status === 500) return
                    if (retryCount >= 3) return
                    setTimeout(() => revalidate({ retryCount }), 1000)
                },
            }}
        >
            {children}
        </SWRConfig>
    )
}
