import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing } from '@/entities/rfq/model'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'
import { StandardQuoteRevisedValues } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised/ui'
import { getRevisedChanges } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised'
import { cn } from '@/shared/utils/cn'

export const ReworkBreakdownComponent = ({ service, revisedService }: ServiceBreakdownComponentProps) => {
    const changes = getRevisedChanges({
        service,
        revisedService,
        pricing: RfqServicePricing.Rework,
    })

    return (
        <div className="flex flex-col gap-3">
            <div className="flex flex-col">
                {service.pricings[RfqServicePricing.Rework]?.price ? (
                    <div className="flex flex-col sm:flex-row gap-0 sm:gap-2 sm:items-center w-full">
                        <CurrencyDisplay
                            value={service.pricings[RfqServicePricing.Rework]!.price}
                            className={cn('text-xl leading-6 text-primary font-semibold', {
                                'text-success': changes?.price?.status === -1,
                                'text-danger': changes?.price?.status === 1,
                            })}
                        />
                        <div className="flex gap-1 sm:gap-2 items-center">
                            <span className="text-xl shrink-0 text-foreground-secondary hidden sm:inline">/</span>
                            <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 sm:hidden">
                                per
                            </span>
                            <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                Pallet (including materials)
                            </span>
                        </div>
                    </div>
                ) : (
                    <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                )}

                <StandardQuoteRevisedValues changes={changes} fields={['price']} className="mt-1 sm:mt-0" />
            </div>
        </div>
    )
}
