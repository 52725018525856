import React, { useEffect } from 'react'
import { LoadBoardList } from './LoadBoardList'
import { LoadBoardTable } from './LoadBoardTable'
import { useLazyGetLoadBoardQuery } from '@/entities/loadboard-orders/api'
import { LoadboardCategory } from '@/entities/loadboard-orders/model'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { Section } from '@/shared/ui/Section'
import { Loader } from '@/shared/ui/Loader'
import { toast } from 'react-toastify'
import { useAppSelector } from '@/app/store'
import { useScreen } from '@/shared/hooks/useScreen'
import { parse } from 'date-fns'

type LoadBoardMainProps = {
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
}

export const LoadBoardMain = ({ params, setParams }: LoadBoardMainProps) => {
    const { isReadyToFetchOrders, filters } = useAppSelector((state) => state.loadboard)

    const [trigger, { data, error, isFetching }] = useLazyGetLoadBoardQuery()

    useEffect(() => {
        if (isReadyToFetchOrders) {
            trigger({
                pagination: {
                    pageIndex: params.pageIndex,
                    pageSize: params.pageSize,
                },
                filters: {
                    category: params.category as LoadboardCategory,
                    search: params.search ?? undefined,
                    hideQuoted: params.hideQuoted ?? undefined,

                    services: (params.services as string[]) ?? undefined,
                    freightEnvironment: params.freightEnvironment ?? undefined,
                    certifications: (params.certifications as string[]) ?? undefined,

                    startDate: filters?.startDate
                        ? parse(filters.startDate, 'MM/dd/yyyy', new Date()).toDateString()
                        : undefined,
                    endDate: filters?.endDate
                        ? parse(filters.endDate, 'MM/dd/yyyy', new Date()).toDateString()
                        : undefined,

                    quotesWarehouse:
                        params.category === 'new-opportunities' || !params.warehouse ? undefined : params.warehouse,

                    lat:
                        params.category !== LoadboardCategory.New || !filters?.lat || !filters?.lng
                            ? undefined
                            : filters.lat,
                    lng:
                        params.category !== LoadboardCategory.New || !filters?.lat || !filters?.lng
                            ? undefined
                            : filters.lng,
                    distance:
                        params.category !== LoadboardCategory.New || !filters?.lat || !filters?.lng
                            ? undefined
                            : params.locationDistance ?? 50,
                },
                sorting: !params.sortBy
                    ? undefined
                    : {
                          id: params.sortBy,
                          desc: params.sortOrder !== 'ASC',
                      },
            })
        }
    }, [isReadyToFetchOrders, params])

    useEffect(() => {
        if (error) {
            toast('An error occurred while attempting to load the orders.', { type: 'error' })
        }
    }, [error])

    useEffect(() => {
        if (data) {
            const totalPages = Math.ceil(data.count / params.pageSize)
            if (params.pageIndex > totalPages - 1) {
                setParams({ pageIndex: 0 })
            }
        }
    }, [data])

    const isXLScreen = useScreen('xl')

    if (error) {
        return (
            <Section>
                <Loader />
            </Section>
        )
    }

    return isXLScreen ? (
        <LoadBoardTable data={data} params={params} setParams={setParams} isLoading={isFetching} />
    ) : (
        <LoadBoardList data={data} params={params} setParams={setParams} isLoading={isFetching} />
    )
}
