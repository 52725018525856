import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { Separator } from '@/shared/ui/Separator'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'
import { getRevisedChanges } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised'
import { StandardQuoteRevisedValues } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised/ui'
import { cn } from '@/shared/utils/cn'

const NON_ACCESSORIAL_PRICINGS = [RfqServicePricing.Drayage, RfqServicePricing.LineHaul, RfqServicePricing.Chassis]

export const DrayageBreakdownComponent = ({ service, revisedService }: ServiceBreakdownComponentProps) => {
    if (service.type === 'allIn') {
        const changes = getRevisedChanges({
            service,
            revisedService,
            pricing: RfqServicePricing.Drayage,
        })

        return (
            <div className="flex flex-col gap-3">
                <div className="flex flex-col">
                    {service.pricings[RfqServicePricing.Drayage]?.price ? (
                        <CurrencyDisplay
                            value={service.pricings[RfqServicePricing.Drayage]!.price}
                            className="text-xl leading-6 text-primary font-semibold"
                        />
                    ) : (
                        <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                    )}

                    <StandardQuoteRevisedValues changes={changes} fields={['price']} />
                </div>
            </div>
        )
    }

    const lineHaulChanges = getRevisedChanges({
        service,
        revisedService,
        pricing: RfqServicePricing.LineHaul,
    })

    const chassisChanges = getRevisedChanges({
        service,
        revisedService,
        pricing: RfqServicePricing.Chassis,
    })

    const accessorialEntries = Object.entries(service.pricings).filter(
        ([key]) => !NON_ACCESSORIAL_PRICINGS.includes(key as RfqServicePricing),
    )
    const hasAccessorialWithPrice = accessorialEntries.some(([_, value]) => value.price)

    return (
        <div className="flex flex-col gap-3 w-full">
            <div className="flex flex-col">
                <div className="text-sm leading-[18px] text-foreground-secondary font-medium">
                    {RfqServicePricingLabel[RfqServicePricing.LineHaul]}
                </div>
                {service.pricings[RfqServicePricing.LineHaul]?.price ? (
                    <div className="flex gap-2 items-center w-full">
                        <CurrencyDisplay
                            value={service.pricings[RfqServicePricing.LineHaul]!.price}
                            className={cn('text-xl leading-6 text-primary font-semibold', {
                                'text-success': lineHaulChanges?.price?.status === -1,
                                'text-danger': lineHaulChanges?.price?.status === 1,
                            })}
                        />
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                            40’HC
                        </span>
                    </div>
                ) : (
                    <div className="text-sm text-foreground-secondary font-medium">No Price Given</div>
                )}

                <StandardQuoteRevisedValues changes={lineHaulChanges} fields={['price']} />
            </div>

            <div className="flex flex-col">
                <div className="text-sm leading-[18px] text-foreground-secondary font-medium">
                    {RfqServicePricingLabel[RfqServicePricing.Chassis]}
                </div>
                {service.pricings[RfqServicePricing.Chassis]?.price ? (
                    <div className="flex gap-2 items-center w-full">
                        <CurrencyDisplay
                            value={service.pricings[RfqServicePricing.Chassis]!.price}
                            className={cn('text-xl leading-6 text-primary font-semibold', {
                                'text-success': chassisChanges?.price?.status === -1,
                                'text-danger': chassisChanges?.price?.status === 1,
                            })}
                        />
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                            Day
                        </span>
                    </div>
                ) : (
                    <div className="text-sm text-foreground-secondary font-medium">No Price Given</div>
                )}

                <StandardQuoteRevisedValues changes={chassisChanges} fields={['price']} />
            </div>

            {hasAccessorialWithPrice && <Separator />}

            {accessorialEntries.map(([key, value], index, array) => {
                if (!value.price) return null

                const pricingName = key as RfqServicePricing

                const pricingChanges = getRevisedChanges({
                    service,
                    revisedService,
                    pricing: pricingName,
                })

                return (
                    <div
                        key={index}
                        className={cn('flex flex-col gap-3 w-fit', index !== array.length - 1 && 'border-b pb-2')}
                    >
                        <div className="flex gap-2">
                            <div className="w-[154px] text-sm leading-6 text-foreground-secondary font-medium">
                                {RfqServicePricingLabel[pricingName]}
                            </div>
                            <div className="flex flex-col">
                                {value.price ? (
                                    <CurrencyDisplay
                                        value={value.price}
                                        className={cn('text-xl leading-6 text-primary font-semibold', {
                                            'text-success': pricingChanges?.price?.status === -1,
                                            'text-danger': pricingChanges?.price?.status === 1,
                                        })}
                                    />
                                ) : (
                                    <div className="text-sm text-foreground-secondary font-medium">No Price Given</div>
                                )}

                                <StandardQuoteRevisedValues changes={pricingChanges} fields={['price']} />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
