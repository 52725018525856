import React from 'react'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { ServiceBreakdownComponentProps } from '../index'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'
import { HintArrow, HintContent, HintRoot, HintTrigger } from '@/shared/ui/Hint'
import { FaInfoCircle } from 'react-icons/fa'
import { cn } from '@/shared/utils/cn'
import { getRevisedChanges } from '../RenderRevised'
import { StandardQuoteRevisedValues } from '../RenderRevised/ui'

export const CrossDockingBreakdownComponent = ({ service, revisedService }: ServiceBreakdownComponentProps) => {
    if (service.type === 'allIn') {
        const changes = getRevisedChanges({
            service,
            revisedService,
            pricing: RfqServicePricing.CrossDocking,
        })

        return (
            <div className="flex flex-col gap-3">
                <span className="font-medium leading-5">Floor Loaded</span>
                <div className="flex flex-col">
                    {service.pricings[RfqServicePricing.CrossDocking]?.price ? (
                        <CurrencyDisplay
                            value={service.pricings[RfqServicePricing.CrossDocking]!.price}
                            className={cn('text-xl leading-6 text-primary font-semibold', {
                                'text-success': changes?.price?.status === -1,
                                'text-danger': changes?.price?.status === 1,
                            })}
                        />
                    ) : (
                        <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                    )}

                    <StandardQuoteRevisedValues changes={changes} fields={['price']} />
                </div>
            </div>
        )
    }

    const changes = getRevisedChanges({
        service,
        revisedService,
        pricing: RfqServicePricing.HandlingTotal,
    })

    return (
        <div className="flex flex-col gap-3">
            <span className="font-medium leading-5">Palletized</span>
            <div className="flex flex-col">
                <div className="text-sm leading-[18px] text-foreground-secondary font-medium flex mb-1">
                    {RfqServicePricingLabel[RfqServicePricing.HandlingTotal]}
                    <HintRoot>
                        <HintTrigger asChild>
                            <div className="ml-1 -mt-[1px]">
                                <FaInfoCircle size={16} />
                            </div>
                        </HintTrigger>
                        <HintContent sideOffset={-3}>
                            <HintArrow /> Combined In and Out Charges per Unit
                        </HintContent>
                    </HintRoot>
                </div>
                {service.pricings[RfqServicePricing.HandlingTotal]?.price ? (
                    <CurrencyDisplay
                        value={service.pricings[RfqServicePricing.HandlingTotal]!.price}
                        className={cn('text-xl leading-6 text-primary font-semibold', {
                            'text-success': changes?.price?.status === -1,
                            'text-danger': changes?.price?.status === 1,
                        })}
                    />
                ) : (
                    <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                )}

                <StandardQuoteRevisedValues changes={changes} fields={['price']} />
            </div>
        </div>
    )
}
