import React, { ChangeEvent } from 'react'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { Input } from '@/shared/ui/Input'
import { InSearch } from '@/shared/ui/Icons/InSearch'
import { debounce } from 'lodash'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'

type LoadBoardSearchProps = {
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
}

const setSearchParam = (value: string, setParams: SetQuery<GetLoadboardParams>) => {
    setParams({ search: value })
}

const debouncedSetSearchParam = debounce(setSearchParam, 300)

export const ControlsSearch = ({ params, setParams }: LoadBoardSearchProps) => {
    const dispatch = useAppDispatch()
    const search = useAppSelector((state) => state.loadboard.filters?.search)

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(loadboardActions.setFilters({ search: e.target.value }))
        debouncedSetSearchParam(e.target.value, setParams)
    }

    return (
        <Input
            wrapperClassName="w-full"
            className="h-11 shadow-shadow rounded-lg"
            placeholder="Search"
            icon={<InSearch size={16} className="text-foreground-tertiary mr-3" />}
            value={search ?? ''}
            onChange={onChange}
        />
    )
}
