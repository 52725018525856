export enum LoadboardCategory {
    New = 'new-opportunities',
    Quoted = 'quoted',
    Accepted = 'accepted',
    Lost = 'lost',
    NotInterested = 'not-interested',
}

export const LoadboardCategoryLabel = {
    [LoadboardCategory.New]: 'New Opportunities',
    [LoadboardCategory.Quoted]: 'Quoted',
    [LoadboardCategory.Accepted]: 'Accepted',
    [LoadboardCategory.Lost]: 'Lost',
    [LoadboardCategory.NotInterested]: 'Not Interested',
}

export enum LoadboardOrderStatus {
    DRAFT = 0,
    ACTIVE = 1,
    BID_ACCEPTED = 2,
    CLOSED = 3,
    ARCHIVED = 4,
}
