import React from 'react'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { Navigate } from 'react-router-dom'

export const LoadBoardGate = () => {
    const { state: userState } = useUserState()

    if (userState.userInfo?.role !== UserRole.WAREHOUSE_MANAGER) {
        return <Navigate to="/login" />
    }

    return null
}
