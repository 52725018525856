import { range } from 'lodash'

export const generatePages = (pageIndex: number, totalPages: number, siblings: number): (number | 'ellipsis')[] => {
    /** 5 is a magical number that SOMEHOW makes pagination look good. I have no idea why exactly 5, but it works lol */
    const totalPageNoInArray = 5 + siblings * 2
    if (totalPageNoInArray >= totalPages) {
        return range(1, totalPages + 1)
    }

    const leftSiblingIndex = Math.max(pageIndex - siblings + 1, 1)
    const rightSiblingIndex = Math.min(pageIndex + siblings + 1, totalPages)

    const showLeftDots = leftSiblingIndex > 3
    const showRightDots = rightSiblingIndex < totalPages - 2

    if (!showLeftDots && showRightDots) {
        const leftItemsCount = 3 + 2 * siblings
        const leftRange = range(1, leftItemsCount + 1)
        return [...leftRange, 'ellipsis', totalPages]
    }

    if (showLeftDots && !showRightDots) {
        const rightItemsCount = 3 + 2 * siblings
        const rightRange = range(totalPages - rightItemsCount + 1, totalPages + 1)
        return [1, 'ellipsis', ...rightRange]
    }

    const middleRange = range(leftSiblingIndex, rightSiblingIndex + 1)
    return [1, 'ellipsis', ...middleRange, 'ellipsis', totalPages]
}
