import React from 'react'
import { LoadboardOrder, LoadboardOrderStatus } from '@/entities/loadboard-orders/model'

type LoadBoardItemSubProps = {
    order: LoadboardOrder
}

export const LoadBoardItemSub = ({ order }: LoadBoardItemSubProps) => {
    const showRequoteRequested = order.requoteRequested
    const showIsQuoted = order.isQuoted
    const showUnread = !order.isRead && order.status !== LoadboardOrderStatus.CLOSED

    return showRequoteRequested ? (
        <div className="text-xs font-normal text-warning leading-4">Requote Requested</div>
    ) : showIsQuoted ? (
        <div className="text-xs font-normal text-success leading-4">Quoted</div>
    ) : showUnread ? (
        <div className="text-xs font-normal text-danger leading-4">Unread</div>
    ) : null
}
