import * as React from 'react'

import { cn } from '@/shared/utils/cn'
import { useComposedRefs } from '@/shared/hooks/useComposedRefs'
import { v4 } from 'uuid'
import { Label } from '@/shared/ui/Label'
import { ErrorMessage } from '@/shared/ui/ErrorMessage'
import { Slot } from '@radix-ui/react-slot'
import { autoUpdate, size, useFloating } from '@floating-ui/react'

const InputWrapper = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return <div className={cn('h-fit relative', className)} ref={ref} {...props} />
    },
)
InputWrapper.displayName = 'InputWrapper'

export type InputFieldProps = React.InputHTMLAttributes<HTMLInputElement>

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(({ className, type, ...props }, ref) => {
    return (
        <input
            type={type}
            className={cn(
                'peer flex h-9 w-full text-sm rounded-md border border-border ring-none outline-none focus:border-primary hover:border-primary disabled:hover:border-border transition-colors duration-75 bg-background-secondary px-3 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-foreground-tertiary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                className,
            )}
            ref={ref}
            {...props}
        />
    )
})
InputField.displayName = 'InputField'

const InputLabel = React.forwardRef<React.ElementRef<typeof Label>, React.ComponentPropsWithoutRef<typeof Label>>(
    ({ className, ...props }, ref) => {
        return (
            <Label
                className={cn(
                    'bg-white px-[6px] text-foreground-secondary font-normal absolute z-[1] top-0 -translate-y-1/2 left-[6px] peer-disabled:cursor-not-allowed peer-disabled:opacity-50',
                    className,
                )}
                ref={ref}
                {...props}
            />
        )
    },
)
InputLabel.displayName = 'InputLabel'

export type InputIconProps = React.HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
}

const InputIcon = React.forwardRef<HTMLDivElement, InputIconProps>(({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'

    return (
        <Comp
            className={cn(
                'absolute right-0 top-0 h-full w-fit flex items-center bg-transparent text-foreground-tertiary peer-disabled:cursor-not-allowed peer-disabled:pointer-events-none peer-disabled:opacity-50',
                className,
            )}
            ref={ref}
            {...props}
        />
    )
})
InputIcon.displayName = 'InputIcon'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    wrapperClassName?: string
    wrapperProps?: Omit<React.ComponentProps<typeof InputWrapper>, 'className'>

    label?: React.ReactNode
    labelClassName?: string
    labelProps?: Omit<React.ComponentProps<typeof InputLabel>, 'className'>

    icon?: React.ReactNode
    iconWrapperClassName?: string
    iconWrapperProps?: Omit<React.ComponentProps<typeof InputIcon>, 'className'>

    error?: React.ReactNode
    errorClassName?: string
    errorProps?: Omit<React.ComponentProps<typeof ErrorMessage>, 'className'>
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            wrapperClassName,
            wrapperProps,

            label,
            labelClassName,
            labelProps,

            icon,
            iconWrapperClassName,
            iconWrapperProps,

            error,
            errorClassName,
            errorProps,

            id,
            className,

            ...inputProps
        },
        ref,
    ) => {
        const actualId = React.useRef(id ?? v4())

        const { refs } = useFloating({
            whileElementsMounted: autoUpdate,
            middleware: [
                size({
                    apply: ({ elements, rects }) => {
                        const { width: inputWidth, height: inputHeight } = rects.reference
                        const contentStyle = elements.floating.style
                        contentStyle.setProperty('--input-field-width', `${inputWidth}px`)
                        contentStyle.setProperty('--input-field-height', `${inputHeight}px`)
                    },
                }),
            ],
        })
        const composedInputRef = useComposedRefs(ref, (node) => refs.setReference(node))
        const composedWrapperRef = useComposedRefs(iconWrapperProps?.ref, (node) => refs.setFloating(node))

        return (
            <InputWrapper className={wrapperClassName} {...wrapperProps} ref={composedWrapperRef}>
                {label !== undefined ? (
                    <InputLabel
                        htmlFor={actualId.current}
                        className={cn(!!error && 'text-danger', labelClassName)}
                        {...labelProps}
                    >
                        {label}
                    </InputLabel>
                ) : null}

                <InputField
                    ref={composedInputRef}
                    id={actualId.current}
                    className={cn(!!error && 'border-danger', className)}
                    {...inputProps}
                />

                {icon !== undefined ? (
                    <InputIcon
                        className={cn(
                            'h-[var(--input-field-height)] left-[var(--input-field-width)] -translate-x-full',
                            iconWrapperClassName,
                        )}
                        {...iconWrapperProps}
                    >
                        {icon}
                    </InputIcon>
                ) : null}

                <ErrorMessage className={cn('ml-3 mt-0.5', errorClassName)} {...errorProps}>
                    {error}
                </ErrorMessage>
            </InputWrapper>
        )
    },
)
Input.displayName = 'Input'

export { InputWrapper, InputField, InputLabel, InputIcon, Input }
