import * as React from 'react'

import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from '@/shared/ui/Command'
import { InputIcon, InputLabel, InputWrapper } from '@/shared/ui/Input'
import { PopoverArrow, PopoverContent, PopoverRoot } from '@/shared/ui/Popover'
import { useGoogleMapsPlaces } from '@/shared/hooks/useGoogleMapsPlaces'
import { InLocation } from '@/shared/ui/Icons/InLocation'

import * as PopoverPrimitive from '@radix-ui/react-popover'
import { getDetails, HookReturn as PlacesAutocomplete, Suggestions } from 'use-places-autocomplete'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { cn } from '@/shared/utils/cn'
import { ErrorMessage } from '@/shared/ui/ErrorMessage'

// eslint-disable-next-line no-undef
export type Suggestion = google.maps.places.AutocompletePrediction
// eslint-disable-next-line no-undef
export type Location = string | google.maps.places.PlaceResult

type TLocationInputContext = {
    places: PlacesAutocomplete
    value: string
    onValueChange: (value: string) => void
    onSelect: (suggestion: Suggestion) => void
    isSelectingRef: React.MutableRefObject<boolean>
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const LocationInputContext = React.createContext<TLocationInputContext>(undefined)
const useLocationInputContext = () => React.useContext(LocationInputContext)

export type LocationInputRootCustomProps = {
    value?: string
    defaultValue?: string
    onValueChange?: (value: string) => void
    onSelect?: (suggestion: Suggestion) => void
    shouldFetchSuggestions?: boolean

    shouldSelectInitially?: boolean
    onInitialSelectFail?: (result: Suggestions) => void

    shouldChangeValueOnSelect?: boolean
    shouldFetchLocationOnSelect?: boolean

    getLocationFields?: string[]
    onLocationFetchSuccess?: (result: Location) => void
    onLocationFetchError?: (error: any) => void
    onLocationFetchFinally?: () => void

    ariaLabel?: string
}

export type LocationInputRootCommandProps = Omit<
    React.ComponentProps<typeof Command>,
    'value' | 'defaultValue' | 'onValueChange' | 'onSelect' | 'shouldFilter' | 'filter' | 'label'
>

export type LocationInputRootProps = LocationInputRootCommandProps & LocationInputRootCustomProps

const LocationInputRoot = React.forwardRef<
    React.ElementRef<typeof Command>,
    React.PropsWithoutRef<LocationInputRootProps>
>(
    (
        {
            children,

            value,
            defaultValue,
            onValueChange,
            onSelect,
            shouldFetchSuggestions = true,

            shouldSelectInitially = false,
            onInitialSelectFail,

            shouldChangeValueOnSelect = false,
            shouldFetchLocationOnSelect = false,

            getLocationFields,
            onLocationFetchSuccess,
            onLocationFetchError,
            onLocationFetchFinally,

            ariaLabel,
            className,

            ...props
        },
        ref,
    ) => {
        const places = useGoogleMapsPlaces(
            {
                types: ['geocode'],
                language: 'en',
            },
            () =>
                new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(14.0, -169.0),
                    new window.google.maps.LatLng(85.0, -52.0),
                ),
        )

        /** Set places value from passed defaultValue prop, if value prop is undefined */
        React.useEffect(() => {
            if (value === undefined && defaultValue) {
                places.setValue(defaultValue, false)
            }
        }, [])

        /** Sync external value with places hook value */
        React.useEffect(() => {
            if (value !== undefined) {
                places.setValue(value, shouldFetchSuggestions)
            }
        }, [value])

        /** Calculate what to pass as value to the Input Field */
        const actualValue = value !== undefined ? value : places.value

        /** === Start of autoselection logic === */
        const isInitialRef = React.useRef<boolean>(true)

        React.useEffect(() => {
            if (shouldSelectInitially && isInitialRef.current && places.value && places.ready) {
                places.setValue(places.value, true)
            }
        }, [shouldSelectInitially, places.ready])

        React.useEffect(() => {
            if (shouldSelectInitially && isInitialRef.current && places.ready) {
                if (places.suggestions.status === 'OK' && places.suggestions.data.length) {
                    onSelectSuggestion(places.suggestions.data[0])
                } else {
                    onInitialSelectFail?.(places.suggestions)
                }
            }
        }, [places.suggestions.status])
        /** === End of autoselection logic === */

        const onChange = (newValue: string) => {
            /** If symbol is entered, set isInitial as false, so autoselect won't happen */
            isInitialRef.current = false

            onValueChange?.(newValue)
            if (value === undefined) {
                places.setValue(newValue, shouldFetchSuggestions)
            }
        }

        /** Ref value to prevent double selects when "shouldSelectOnBlur" flag is true */
        const isSelectingRef = React.useRef<boolean>(false)

        const onSelectSuggestion = (suggestion: Suggestion) => {
            /** If any suggestion is picked, set isInitial as false, so autoselect won't happen */
            isInitialRef.current = false
            isSelectingRef.current = true

            onSelect?.(suggestion)
            if (value === undefined) {
                places.setValue(suggestion.description, false)
            }
            if (shouldChangeValueOnSelect) {
                onValueChange?.(suggestion.description)
            }
            if (shouldFetchLocationOnSelect) {
                getLocationDetails(suggestion).then(() => {
                    isSelectingRef.current = false
                })
            } else {
                isSelectingRef.current = false
            }
        }

        /** Autofetch function. Used when "shouldFetchLocationOnSelect" flag is true */
        const getLocationDetails = React.useCallback(
            (suggestion: Suggestion) =>
                getDetails({
                    placeId: suggestion.place_id,
                    fields: getLocationFields ?? ['address_components', 'geometry', 'name'],
                    language: 'en',
                })
                    .then((result) => {
                        onLocationFetchSuccess?.(result)
                    })
                    .catch((err) => {
                        onLocationFetchError?.(err)
                    })
                    .finally(() => {
                        onLocationFetchFinally?.()
                    }),
            [onLocationFetchSuccess, onLocationFetchError, onLocationFetchFinally],
        )

        return (
            <LocationInputContext.Provider
                value={{
                    places: places,
                    value: actualValue,
                    onValueChange: onChange,
                    onSelect: onSelectSuggestion,
                    isSelectingRef,
                }}
            >
                <Command
                    ref={ref}
                    className={cn('overflow-visible', className)}
                    shouldFilter={false}
                    label={ariaLabel}
                    {...props}
                >
                    {children}
                </Command>
            </LocationInputContext.Provider>
        )
    },
)

export type LocationInputFieldCustomProps = {
    wrapperClassName?: string
    wrapperProps?: Omit<React.ComponentProps<typeof InputWrapper>, 'className'>

    label?: React.ReactNode
    labelClassName?: string
    labelProps?: Omit<React.ComponentProps<typeof InputLabel>, 'className'>

    icon?: React.ReactNode
    iconClassName?: string
    iconWrapperClassName?: string
    iconWrapperProps?: Omit<React.ComponentProps<typeof InputIcon>, 'className'>

    error?: React.ReactNode
    errorClassName?: string
    errorProps?: Omit<React.ComponentProps<typeof ErrorMessage>, 'className'>
}

export type LocationInputFieldCommandProps = Omit<
    React.ComponentPropsWithoutRef<typeof CommandInput>,
    'value' | 'onValueChange' | 'defaultValue'
>

export type LocationInputFieldProps = LocationInputFieldCustomProps & LocationInputFieldCommandProps

const LocationInputField = React.forwardRef<React.ElementRef<typeof CommandInput>, LocationInputFieldProps>(
    ({ disabled, className, wrapperClassName, iconClassName, error, onFocus, icon, ...props }, ref) => {
        const context = useLocationInputContext()

        return (
            <CommandInput
                ref={ref}
                value={context.value}
                onValueChange={context.onValueChange}
                onFocus={(e) => {
                    context.places.setValue(context.value, true)
                    onFocus?.(e)
                }}
                className={cn(
                    'flex h-9 w-full text-sm rounded-md border border-border ring-none outline-none focus:border-primary hover:border-primary disabled:hover:border-border transition-colors duration-75 bg-background-secondary px-3 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-foreground-tertiary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                    !!error && 'border-danger',
                    className,
                )}
                wrapperClassName={cn('p-0 border-0', wrapperClassName)}
                disabled={!context.places.ready || disabled}
                icon={
                    !context.places.ready ? (
                        <ImSpinner8 className={cn('animate-spin mr-2', iconClassName)} />
                    ) : (
                        icon ?? (
                            <InLocation size={16} className={cn('mr-2 text-foreground-tertiary', iconClassName)} />
                        )
                    )
                }
                error={error}
                {...props}
            />
        )
    },
)

export type LocationInputListCustomProps = {
    loaderWrapperClassName?: string
    loaderWrapperProps?: Omit<React.ComponentProps<typeof CommandEmpty>, 'children' | 'className'>
    renderLoader?: () => React.ReactNode

    emptyWrapperClassName?: string
    emptyWrapperProps?: Omit<React.ComponentPropsWithRef<typeof CommandEmpty>, 'children' | 'className'>
    renderEmpty?: () => React.ReactNode

    itemClassName?: string
    itemPropsBuilder?: (
        suggestion: Suggestion,
    ) => Omit<React.ComponentProps<typeof CommandItem>, 'className' | 'onSelect'>
    renderItemContent?: (suggestion: Suggestion) => React.ReactNode
}

export type LocationInputListProps = React.ComponentPropsWithRef<typeof CommandList> & LocationInputListCustomProps

const LocationInputList = ({
    loaderWrapperClassName,
    loaderWrapperProps,
    renderLoader,

    emptyWrapperClassName,
    emptyWrapperProps,
    renderEmpty,

    itemClassName,
    itemPropsBuilder,
    renderItemContent,

    ...props
}: LocationInputListProps) => {
    const context = useLocationInputContext()

    const suggestions = context.places.suggestions

    return (
        <CommandList {...props}>
            {suggestions.loading ? (
                <CommandEmpty className={loaderWrapperClassName} {...loaderWrapperProps}>
                    {renderLoader ? (
                        renderLoader()
                    ) : (
                        <ImSpinner8 className="animate-spin text-foreground-tertiary" />
                    )}
                </CommandEmpty>
            ) : suggestions.status === 'ZERO_RESULTS' ? (
                <CommandEmpty className={emptyWrapperClassName} {...emptyWrapperProps}>
                    {renderEmpty ? renderEmpty() : 'No options found.'}
                </CommandEmpty>
            ) : (
                suggestions.data.map((suggestion) => (
                    <CommandItem
                        key={suggestion.place_id}
                        className={cn('min-h-8 h-auto leading-[1rem] py-1.5', itemClassName)}
                        onSelect={() => {
                            context.onSelect(suggestion)
                        }}
                        {...itemPropsBuilder?.(suggestion)}
                    >
                        {renderItemContent ? renderItemContent(suggestion) : suggestion.description}
                    </CommandItem>
                ))
            )}
        </CommandList>
    )
}

export type LocationPopoverInputProps = Omit<LocationInputFieldCustomProps, 'wrapperProps'> & {
    disabled?: boolean
    placeholder?: string
    inputClassName?: string
    inputProps?: Omit<LocationInputFieldCommandProps, 'className' | 'placeholder' | 'disabled'>

    wrapperProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>
}

export type LocationPopoverListProps = LocationInputListCustomProps & {
    listClassName?: string
    listProps?: Omit<React.ComponentProps<typeof CommandList>, 'className'>
}

export type LocationPopoverBaseProps = {
    open: boolean
    onOpenChange: (open: boolean) => void
    modal?: boolean

    shouldSelectOnBlur?: boolean

    popoverContentClassName?: string
    popoverContentProps?: Omit<React.ComponentProps<typeof PopoverContent>, 'className'>

    hidePopoverArrow?: boolean
    popoverArrowClassName?: string
    popoverArrowProps?: Omit<React.ComponentProps<typeof PopoverArrow>, 'className'>
}

export type LocationInputPopoverProps = LocationPopoverBaseProps & LocationPopoverInputProps & LocationPopoverListProps

export const LocationInputPopover = ({
    disabled,

    open,
    onOpenChange,
    modal,

    shouldSelectOnBlur = false,

    placeholder,
    inputClassName,
    inputProps,

    wrapperClassName,
    wrapperProps,

    label,
    labelClassName,
    labelProps,

    icon,
    iconClassName,
    iconWrapperClassName,
    iconWrapperProps,

    error,
    errorClassName,
    errorProps,

    listClassName,
    listProps,

    loaderWrapperClassName,
    loaderWrapperProps,
    renderLoader,

    emptyWrapperClassName,
    emptyWrapperProps,
    renderEmpty,

    itemClassName,
    itemPropsBuilder,
    renderItemContent,

    popoverContentClassName,
    popoverContentProps,

    hidePopoverArrow,
    popoverArrowClassName,
    popoverArrowProps,
}: LocationInputPopoverProps) => {
    const context = useLocationInputContext()

    const onClose = () => {
        onOpenChange(false)

        if (!shouldSelectOnBlur || context.isSelectingRef.current) {
            return
        }

        if (context.places.suggestions.data.length > 0) {
            if (context.places.suggestions.data[0].description !== context.value) {
                context.onSelect(context.places.suggestions.data[0])
            }
        } else {
            if (context.value) {
                context.places.setValue('')
                context.onValueChange('')
            }
        }
    }

    const ref = React.useRef<HTMLDivElement | null>(null)

    const shouldRenderContent =
        context.places.ready &&
        context.places.value &&
        !(
            !context.places.suggestions.loading &&
            context.places.suggestions.status === '' &&
            context.places.suggestions.data.length < 1
        )

    return (
        <PopoverRoot open={open} onOpenChange={(o) => !o && onClose()} modal={modal}>
            <PopoverPrimitive.Anchor asChild onClick={(e) => e.preventDefault()}>
                <LocationInputField
                    {...inputProps}
                    onFocus={(e) => {
                        onOpenChange(true)
                        inputProps?.onFocus?.(e)
                    }}
                    placeholder={placeholder}
                    className={inputClassName}
                    wrapperClassName={wrapperClassName}
                    wrapperProps={{ ref, ...wrapperProps }}
                    label={label}
                    labelClassName={labelClassName}
                    labelProps={labelProps}
                    icon={icon}
                    iconClassName={iconClassName}
                    iconWrapperClassName={iconWrapperClassName}
                    iconWrapperProps={iconWrapperProps}
                    error={error}
                    errorClassName={errorClassName}
                    errorProps={errorProps}
                    disabled={disabled}
                />
            </PopoverPrimitive.Anchor>

            {shouldRenderContent ? (
                <PopoverContent
                    className={cn('w-[var(--radix-popover-trigger-width)]', popoverContentClassName)}
                    {...popoverContentProps}
                    onOpenAutoFocus={(e) => {
                        e.preventDefault()
                        popoverContentProps?.onOpenAutoFocus?.(e)
                    }}
                    onFocusOutside={(e) => {
                        e.preventDefault()
                        popoverContentProps?.onFocusOutside?.(e)
                    }}
                    onInteractOutside={(e) => {
                        if (ref.current && e.target && ref.current.contains(e.target as Node)) {
                            e.preventDefault()
                        }
                        popoverContentProps?.onInteractOutside?.(e)
                    }}
                >
                    {hidePopoverArrow ? null : (
                        <PopoverArrow className={popoverArrowClassName} {...popoverArrowProps} />
                    )}
                    <LocationInputList
                        className={listClassName}
                        loaderWrapperClassName={loaderWrapperClassName}
                        loaderWrapperProps={loaderWrapperProps}
                        renderLoader={renderLoader}
                        emptyWrapperClassName={emptyWrapperClassName}
                        emptyWrapperProps={emptyWrapperProps}
                        renderEmpty={renderEmpty}
                        itemClassName={itemClassName}
                        itemPropsBuilder={itemPropsBuilder}
                        renderItemContent={renderItemContent}
                        {...listProps}
                    />
                </PopoverContent>
            ) : null}
        </PopoverRoot>
    )
}

export type LocationInputProps = Omit<LocationInputRootCustomProps, 'open'> &
    Omit<LocationInputPopoverProps, 'open' | 'onOpenChange'> & {
        onValueChange?: (value: string) => void

        rootClassName?: string
        rootProps?: Omit<LocationInputRootCommandProps, 'className' | 'children'>
    }

const LocationInput = ({
    value,
    defaultValue,
    onValueChange,
    onSelect,

    shouldSelectInitially,
    onInitialSelectFail,

    shouldChangeValueOnSelect,
    shouldFetchLocationOnSelect,

    getLocationFields,
    onLocationFetchSuccess,
    onLocationFetchError,
    onLocationFetchFinally,

    ariaLabel,
    rootClassName,
    rootProps,
    ...rest
}: LocationInputProps) => {
    const [innerOpen, setInnerOpen] = React.useState(false)

    const changeOpen = (newOpen: boolean) => {
        setInnerOpen(newOpen)
    }

    const onInnerSelect = (suggestion: Suggestion) => {
        setInnerOpen(false)
        onSelect?.(suggestion)
    }

    return (
        <LocationInputRoot
            value={value}
            defaultValue={defaultValue}
            onValueChange={onValueChange}
            onSelect={onInnerSelect}
            shouldFetchSuggestions={innerOpen}
            shouldSelectInitially={shouldSelectInitially}
            onInitialSelectFail={onInitialSelectFail}
            shouldChangeValueOnSelect={shouldChangeValueOnSelect}
            shouldFetchLocationOnSelect={shouldFetchLocationOnSelect}
            getLocationFields={getLocationFields}
            onLocationFetchSuccess={onLocationFetchSuccess}
            onLocationFetchError={onLocationFetchError}
            onLocationFetchFinally={onLocationFetchFinally}
            className={rootClassName}
            ariaLabel={ariaLabel}
            {...rootProps}
        >
            <LocationInputPopover open={innerOpen} onOpenChange={changeOpen} {...rest} />
        </LocationInputRoot>
    )
}

export { LocationInputRoot, LocationInputField, LocationInputList, LocationInput }
