import React from 'react'
import { getCoreRowModel, getPaginationRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import { GetLoadboardOrdersResponse } from '@/entities/loadboard-orders/model/response'
import { Section } from '@/shared/ui/Section'
import { getLoadBoardTableColumns } from './LoadBoardTableColumns'
import { DataTable } from '@/shared/ui/DataTable'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'
import { debounce } from 'lodash'
import { LoadboardCategory } from '@/entities/loadboard-orders/model'

type LoadBoardTableProps = {
    data: GetLoadboardOrdersResponse | undefined
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
    isLoading: boolean
}

const setQueryParams = (sorting: SortingState, setParams: SetQuery<GetLoadboardParams>) => {
    const columnSorting = sorting[0]
    if (columnSorting) {
        setParams({ sortBy: columnSorting.id, sortOrder: columnSorting.desc ? 'DESC' : 'ASC' })
    } else {
        setParams({ sortBy: undefined, sortOrder: undefined })
    }
}

const debouncedSetQueryParams = debounce(setQueryParams, 300)

export const LoadBoardTable = ({ data, params, setParams, isLoading }: LoadBoardTableProps) => {
    const dispatch = useAppDispatch()

    const pagination = { pageIndex: params.pageIndex, pageSize: params.pageSize }

    const sorting = useAppSelector((state) => state.loadboard.sorting)

    const columns = getLoadBoardTableColumns(params.category as LoadboardCategory, data?.orders)
    const table = useReactTable({
        columns: columns,
        data: data?.orders ?? [],
        getCoreRowModel: getCoreRowModel(),

        manualPagination: true,
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: (updater) => {
            if (typeof updater === 'function') {
                setParams(updater(pagination))
            } else {
                setParams(updater)
            }
            document.querySelector('#app')?.scroll({ top: 0 })
        },

        onSortingChange: (updater) => {
            if (typeof updater === 'function') {
                dispatch(loadboardActions.setSorting(updater(sorting)))
                debouncedSetQueryParams(updater(sorting), setParams)
            } else {
                dispatch(loadboardActions.setSorting(updater))
                debouncedSetQueryParams(updater, setParams)
            }
        },

        state: {
            pagination: pagination,
            sorting: sorting,
        },
    })

    return (
        <Section className="!p-0 overflow-hidden rounded-lg">
            <DataTable
                table={table}
                totalItems={data?.count}
                isLoading={isLoading}
                rootClassName="table-fixed"
                hideTablePagination={!data?.count}
            />
        </Section>
    )
}
