import { useMediaQuery } from '@/shared/hooks/useMediaQuery'

type Screen = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

const screenToMediaQuery: Record<Screen, string> = {
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 948px)',
    xl: '(min-width: 1188px)',
    '2xl': '(min-width: 1451px)',
}

export const useScreen = (resolution: Screen) => {
    return useMediaQuery(screenToMediaQuery[resolution])
}
