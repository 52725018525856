import {
    BooleanParam,
    DelimitedArrayParam,
    NumberParam,
    QueryParamConfig,
    StringParam,
    useQueryParams,
    withDefault,
} from 'use-query-params'
import { LoadboardCategory } from '@/entities/loadboard-orders/model'
import { isArray, uniq } from 'lodash'
import { RfqCertification, RfqFreightEnv, RfqService } from '@/entities/rfq/model'

const CategoryParam: QueryParamConfig<string | null | undefined, string> = {
    encode: (value) => value,
    decode: (value) => {
        if (!value || typeof value !== 'string') return 'new-opportunities'
        return Object.values(LoadboardCategory).includes(value as any) ? value : 'new-opportunities'
    },
}

const PageIndexParam: QueryParamConfig<number | null | undefined, number> = {
    encode: NumberParam.encode,
    decode: (value) => {
        const decoded = NumberParam.decode(value)
        if (typeof decoded !== 'number' || isNaN(decoded)) return 0
        if (decoded < 0) return 0
        return decoded
    },
}

const PageSizeParam: QueryParamConfig<number | null | undefined, number> = {
    encode: NumberParam.encode,
    decode: (value) => {
        const decoded = NumberParam.decode(value)
        if (typeof decoded !== 'number' || isNaN(decoded)) return 10
        const availableSizes = [10, 20, 30, 40, 50]
        if (!availableSizes.includes(decoded)) {
            const closest = availableSizes.reduce(function (prev, curr) {
                return Math.abs(curr - decoded) < Math.abs(prev - decoded) ? curr : prev
            })
            return closest
        }
        return decoded
    },
}

const RfqServicesParam: typeof DelimitedArrayParam = {
    encode: DelimitedArrayParam.encode,
    decode: (value) => {
        const decoded = DelimitedArrayParam.decode(value)
        if (decoded === null || decoded === undefined) return decoded
        const availableServices = Object.values(RfqService).filter((service) => service !== RfqService.Handling)
        const filteredServices = decoded.filter((s) => !!s && availableServices.includes(s as any)) as string[]
        return uniq(filteredServices)
    },
}

const RfqFreightEnvParam: typeof StringParam = {
    encode: StringParam.encode,
    decode: (value) => {
        const decoded = StringParam.decode(value)
        if (decoded === null || decoded === undefined) return decoded
        const availableFreightEnvs = Object.values(RfqFreightEnv)
        const trimmedFreightEnvironment = decoded.trim()
        if (!trimmedFreightEnvironment) return undefined
        return availableFreightEnvs.includes(trimmedFreightEnvironment as any) ? trimmedFreightEnvironment : undefined
    },
}

const RfqCertificationsParam: typeof DelimitedArrayParam = {
    encode: DelimitedArrayParam.encode,
    decode: (value) => {
        const decoded = DelimitedArrayParam.decode(value)
        if (decoded === null || decoded === undefined) return decoded
        const availableCertifications = Object.values(RfqCertification)
        const filteredCertifications = decoded.filter(
            (c) => !!c && availableCertifications.includes(c as any),
        ) as string[]
        return uniq(filteredCertifications)
    },
}

const DistanceParam: typeof NumberParam = {
    encode: NumberParam.encode,
    decode: (value) => {
        const decoded = NumberParam.decode(value)
        if (decoded === null || decoded === undefined) return decoded
        if (isNaN(decoded)) return undefined
        if (decoded < 1 || decoded > 100) return 50
        return decoded
    },
}

export type GetLoadboardParams = {
    category: typeof CategoryParam
    hideQuoted: typeof BooleanParam

    /** Pagination */
    pageIndex: typeof PageIndexParam
    pageSize: typeof PageSizeParam

    /** Sorting */
    sortBy: QueryParamConfig<string | null | undefined, string>
    sortOrder: QueryParamConfig<string | null | undefined, string>

    /** Params for all categories */
    search: typeof StringParam
    services: typeof RfqServicesParam
    freightEnvironment: typeof RfqFreightEnvParam
    certifications: typeof RfqCertificationsParam
    startDate: typeof StringParam
    endDate: typeof StringParam

    /** Params unique for non "new-opportunities" categories */
    warehouse: typeof StringParam

    /** Params unique for "new-opportunities" category */
    useWHLocation: typeof BooleanParam
    locationWarehouse: typeof StringParam
    locationSearch: typeof StringParam
    locationDistance: typeof DistanceParam
}

export const useLoadboardQueryParams = () =>
    useQueryParams<GetLoadboardParams>({
        category: CategoryParam,
        hideQuoted: BooleanParam,

        /** Pagination */
        pageIndex: PageIndexParam,
        pageSize: PageSizeParam,

        sortBy: withDefault(StringParam, 'startDate'),
        sortOrder: withDefault(StringParam, 'DESC'),

        /** Params for all categories */
        search: StringParam,
        services: RfqServicesParam,
        freightEnvironment: RfqFreightEnvParam,
        certifications: RfqCertificationsParam,
        startDate: StringParam,
        endDate: StringParam,

        /** Params unique for non "new-opportunities" categories */
        warehouse: StringParam,

        /** Params unique for "new-opportunities" category */
        useWHLocation: BooleanParam,
        locationWarehouse: StringParam,
        locationSearch: StringParam,
        locationDistance: DistanceParam,
    })
