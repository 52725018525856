import React from 'react'
import {
    StandardQuoteRevisedChanges,
    StandardQuoteRevisedField,
    StandardQuoteRevisedModifier,
    StandardQuoteRevisedType,
} from '../model'
import { RenderField } from './RenderField'
import { cn } from '@/shared/utils/cn'

type StandardQuoteRevisedValuesProps = {
    changes: StandardQuoteRevisedChanges | null
    fields: StandardQuoteRevisedField[]

    className?: string
    renderRevisedType?: (type: StandardQuoteRevisedType | undefined) => React.ReactNode
    renderRevisedModifier?: (modifier: StandardQuoteRevisedModifier | undefined) => React.ReactNode
}

export const StandardQuoteRevisedValues = ({
    changes,
    fields,

    className,
    renderRevisedModifier,
    renderRevisedType,
}: StandardQuoteRevisedValuesProps) => {
    if (!changes) return null

    return (
        <div className={cn('flex gap-1 items-center text-foreground-tertiary', className)}>
            {fields.map((field, index) => (
                <RenderField
                    key={index}
                    changes={changes}
                    field={field}
                    renderRevisedType={renderRevisedType}
                    renderRevisedModifier={renderRevisedModifier}
                />
            ))}
        </div>
    )
}
