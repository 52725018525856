import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useGetRelatedAccountsQuery } from '@/entities/accounts/api'
import { Select, SelectOption } from '@/shared/ui/Select'
import { LocationInput, Suggestion, Location } from '@/shared/ui/LocationInput'
import { Label } from '@/shared/ui/Label'
import { Checkbox } from '@/shared/ui/Checkbox'
import { CheckedState } from '@radix-ui/react-checkbox'
import { Button } from '@/shared/ui/Button'
import { InClose } from '@/shared/ui/Icons/InClose'
import { FilterParams } from '@/pages/LoadBoard/model/types'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'
import { toast } from 'react-toastify'

type FilterLocationProps = {
    onParamsChanged: (
        params: Partial<FilterParams>,
        options?: {
            invokeImmediately?: boolean
            skipSettingParams?: boolean
        },
    ) => void
}

export const FilterLocation = ({ onParamsChanged }: FilterLocationProps) => {
    const dispatch = useAppDispatch()
    const isReadyToFetchOrders = useAppSelector((state) => state.loadboard.isReadyToFetchOrders)
    const useWHLocation = useAppSelector((state) => state.loadboard.filters!.useWHLocation)
    const locationWarehouse = useAppSelector((state) => state.loadboard.filters!.locationWarehouse)
    const locationSearch = useAppSelector((state) => state.loadboard.filters!.locationSearch)
    const lastFetchedLocationName = useAppSelector((state) => state.loadboard.lastFetchedLocationName)

    const onUseWHLocationChange = (v: CheckedState) => {
        const value = typeof v === 'string' ? false : v

        if (value) {
            const skipSettingParams = !!locationWarehouse || !locationSearch
            onParamsChanged({ useWHLocation: true, lat: null, lng: null }, { skipSettingParams: skipSettingParams })

            if (locationWarehouse) {
                onSelectWarehouse(locationWarehouse)
            }
        } else {
            if (lastFetchedLocationName !== locationSearch) {
                isAllowedToAutoFetchRef.current = true
            }

            const skipSettingParams = !!locationSearch || !locationWarehouse
            onParamsChanged({ useWHLocation: false, lat: null, lng: null }, { skipSettingParams: skipSettingParams })
        }
    }

    /** Logic for Warehouse Location below */
    /** ================================== */
    const { data, error, isFetching } = useGetRelatedAccountsQuery()

    const warehousesOptions = useMemo(() => {
        const result: SelectOption[] = []
        if (!data) return result
        result.push(...data.map((w) => ({ value: w.id, label: w.name })))
        return result
    }, [data])

    /** Try to get coordinates if there is initial locationWarehouse from params */
    useEffect(() => {
        if (useWHLocation && locationWarehouse) {
            if (!isFetching) {
                if (data) {
                    onSelectWarehouse(locationWarehouse)
                } else if (error) {
                    if (!isReadyToFetchOrders) dispatch(loadboardActions.setIsReadyForFetching(true))
                }
            }
        }
    }, [data, error, isFetching])

    const onSelectWarehouse = (value: string) => {
        const existingWarehouse = data?.find((w) => w.id === value)
        if (existingWarehouse?.apiLocation) {
            onParamsChanged(
                {
                    locationWarehouse: existingWarehouse.id,
                    lat: existingWarehouse.apiLocation.latitude,
                    lng: existingWarehouse.apiLocation.longitude,
                },
                {
                    invokeImmediately: !isReadyToFetchOrders,
                },
            )
        }

        /** If there is locationWarehouse in query params - "isReadyForFetching" initially has value of "false".
         *  It's done so Loadboard won't be fetched until initial Location Coordinates are set. */
        if (!isReadyToFetchOrders) dispatch(loadboardActions.setIsReadyForFetching(true))
    }

    /** Logic for Location Search below */
    /** =============================== */
    const isAllowedToAutoFetchRef = useRef(!isReadyToFetchOrders)
    const [isSelectingLocation, setIsSelectingLocation] = useState(false)

    const onSelectSuggestion = (suggestion: Suggestion) => {
        setIsSelectingLocation(true)
        onParamsChanged(
            { locationSearch: suggestion.description },
            {
                invokeImmediately: !isReadyToFetchOrders,
            },
        )
    }

    const onLocationFetchSuccess = (location: Location) => {
        if (typeof location === 'string') return
        const lat = location.geometry?.location?.lat()
        const lng = location.geometry?.location?.lng()

        if (lat && lng) {
            if (location.name) {
                dispatch(loadboardActions.setLastFetchedLocationName(location.name))
            }
            onParamsChanged({ lat, lng })
        } else {
            onParamsChanged({ locationSearch: null, lat: null, lng: null })
            toast('Could not get location', { type: 'error' })
        }
    }

    const onLocationFetchFinally = () => {
        setIsSelectingLocation(false)
        isAllowedToAutoFetchRef.current = false

        /** If there is locationSearch in query params - "isReadyForFetching" initially has value of "false".
         *  It's done so Loadboard won't be fetched until initial Location Coordinates are set. */
        if (!isReadyToFetchOrders) dispatch(loadboardActions.setIsReadyForFetching(true))
    }

    return (
        <div className="px-3 flex flex-col gap-4">
            {useWHLocation ? (
                <div>
                    <Select
                        label="Warehouse"
                        disabled={isFetching}
                        options={warehousesOptions}
                        value={locationWarehouse ?? ''}
                        onValueChange={onSelectWarehouse}
                        icon={
                            warehousesOptions.some((w) => w.value === locationWarehouse) ? (
                                <Button
                                    variant="ghost"
                                    className="h-full rounded-l-none text-foreground-tertiary"
                                    onClick={(e) => {
                                        onParamsChanged({ locationWarehouse: null, lat: null, lng: null })
                                    }}
                                >
                                    <InClose size={12} />
                                </Button>
                            ) : undefined
                        }
                        triggerClassName="h-11 md:h-9 pr-7 [&>span]:text-ellipsis [&>span]:overflow-hidden [&>span]:block"
                    />
                </div>
            ) : (
                <LocationInput
                    disabled={isSelectingLocation}
                    value={locationSearch ?? ''}
                    onValueChange={(v) => {
                        if (!v) {
                            onParamsChanged({ locationSearch: v, lat: null, lng: null })
                        } else {
                            onParamsChanged({ locationSearch: v }, { skipSettingParams: true })
                        }
                    }}
                    onSelect={onSelectSuggestion}
                    shouldSelectInitially={isAllowedToAutoFetchRef.current && !useWHLocation && !!locationSearch}
                    onInitialSelectFail={(suggestions) => {
                        if (suggestions.status && !isReadyToFetchOrders) {
                            onParamsChanged(
                                { locationSearch: null, lat: null, lng: null },
                                {
                                    invokeImmediately: true,
                                },
                            )
                            dispatch(loadboardActions.setIsReadyForFetching(true))
                        }
                    }}
                    shouldFetchLocationOnSelect
                    shouldSelectOnBlur
                    getLocationFields={['geometry.location', 'name']}
                    onLocationFetchSuccess={onLocationFetchSuccess}
                    onLocationFetchFinally={onLocationFetchFinally}
                    label="Service Location"
                    inputClassName="pr-7 h-11 md:h-9"
                    iconWrapperClassName="mr-3"
                    icon={
                        locationSearch ? (
                            <Button
                                variant="ghost"
                                className="text-foreground-tertiary rounded-l-none"
                                onClick={() => onParamsChanged({ locationSearch: null, lat: null, lng: null })}
                            >
                                <InClose size={12} />
                            </Button>
                        ) : undefined
                    }
                />
            )}
            <div className="w-fit flex gap-2 items-center">
                <Checkbox
                    id="useWHLocationCheckbox"
                    disabled={isSelectingLocation}
                    checked={useWHLocation ?? false}
                    onCheckedChange={onUseWHLocationChange}
                />
                <Label htmlFor="useWHLocationCheckbox" className="font-normal text-sm">
                    Use warehouse location
                </Label>
            </div>
        </div>
    )
}
