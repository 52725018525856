import React from 'react'
import { LoadboardOrder, LoadboardOrderStatus } from '@/entities/loadboard-orders/model'
import { Quote, QuoteStatus } from '@/entities/quotes/model'
import { OrderQuoteDemandList } from './OrderQuoteDemandList'
import { OrderQuoteBreakdown } from '../OrderQuoteBreakdown'
import { useAppSelector } from '@/app/store'
import { AlertDescription, AlertRoot } from '@/shared/ui/Alert'
import { FaArrowsRotate } from 'react-icons/fa6'

export const OrderQuoteDemand = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const quote: Quote = order.bids[0]

    if (order.bids.length <= 0) {
        return (
            <div className="space-y-4">
                <span className="text-2xl font-semibold">Quotes</span>
                <div className="border border-border bg-background-tertiary rounded-lg flex items-center justify-center py-3">
                    You have not received a quote
                </div>
            </div>
        )
    }

    if (order.status === LoadboardOrderStatus.CLOSED && order.bids[0].status !== QuoteStatus.ACCEPTED) {
        return (
            <div className="space-y-4">
                <span className="text-2xl font-semibold">Quotes</span>
                <div className="border border-border bg-background-tertiary rounded-lg flex items-center justify-center py-3">
                    You have not received a quote
                </div>
            </div>
        )
    }

    if (order.bids[0].status === QuoteStatus.ACCEPTED) {
        return (
            <div className="space-y-4">
                <span className="text-2xl font-semibold">Quote</span>
                {quote.warehouseAddress && (
                    <div className="!mt-2">
                        <div className="text-sm text-foreground-secondary font-medium">Location</div>
                        <div>{quote.warehouseAddress}</div>
                    </div>
                )}
                <div className="border border-border rounded-xl bg-background-secondary p-3 mt-3">
                    <OrderQuoteBreakdown quote={quote} />
                </div>
            </div>
        )
    }

    if (order.bids[0].status === QuoteStatus.REVISED || order.bids[0].status === QuoteStatus.REQUOTE) {
        return (
            <div className="space-y-4">
                <span className="text-2xl font-semibold">Quotes</span>
                <AlertRoot variant="warning">
                    <AlertDescription className="flex gap-2.5 justify-center items-center">
                        <FaArrowsRotate size={24} className="text-warning" />
                        <span className="font-semibold">Requote in Process...</span>
                    </AlertDescription>
                </AlertRoot>
            </div>
        )
    }

    return <OrderQuoteDemandList />
}
