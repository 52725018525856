/* eslint-disable */
import * as React from 'react'

import { cn } from '@/shared/utils/cn'
import { getTrackBackground, Range as SliderRoot } from 'react-range'
import { useMemo, useState } from 'react'
import { IRenderMarkParams, IRenderThumbParams, IRenderTrackParams } from 'react-range/lib/types'
import { useComposedRefs } from '@/shared/hooks/useComposedRefs'
import { range } from 'lodash'

export type SliderTrackProps = React.HTMLAttributes<HTMLDivElement> & {
    extraRef?: React.ForwardedRef<HTMLDivElement>
    isDragged?: boolean
    disabled?: boolean
}

const SliderTrack = React.forwardRef<HTMLDivElement, SliderTrackProps>(
    ({ className, extraRef, isDragged, disabled, ...props }, ref) => {
        const composedRef = useComposedRefs(ref, extraRef)

        return (
            <div
                ref={composedRef}
                className={cn(
                    'relative h-1.5 w-full grow rounded-full bg-accent-light',
                    disabled && 'opacity-50',
                    className,
                )}
                {...props}
            />
        )
    },
)
SliderTrack.displayName = 'SliderTrack'

export type SliderThumbProps = React.HTMLAttributes<HTMLDivElement> & {
    extraRef?: React.ForwardedRef<HTMLDivElement>
    value?: string
    isDragged?: boolean
    disabled?: boolean
}

const SliderThumb = React.forwardRef<HTMLDivElement, SliderThumbProps>(
    ({ className, extraRef, value, isDragged, disabled, ...props }, ref) => {
        const composedRef = useComposedRefs(ref, extraRef)

        return (
            <div
                ref={composedRef}
                className={cn(
                    'h-5 w-5 rounded-full border-2 border-primary bg-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
                    className,
                )}
                {...props}
            />
        )
    },
)
SliderThumb.displayName = 'SliderThumb'

type OmitSliderRootProperties = 'values' | 'onChange' | 'onFinalChange' | 'renderTrack' | 'renderThumb' | 'renderMark'

export type SliderProps = {
    value?: number[]
    onValueChange?: (value: number[]) => void
    onValueCommit?: (value: number[]) => void
    defaultValue?: number[]

    trackClassName?: string
    trackProps?: Omit<React.ComponentPropsWithRef<typeof SliderTrack>, 'className'>
    renderTrack?: (params: IRenderTrackParams) => React.ReactNode

    thumbClassName?: string
    thumbPropsBuilder?: (
        value: number,
        index: number,
        isDragged: boolean,
    ) => React.ComponentPropsWithRef<typeof SliderThumb>
    renderThumb?: (params: IRenderThumbParams) => React.ReactNode

    renderMark?: (params: IRenderMarkParams) => React.ReactNode
} & Partial<Omit<React.ComponentPropsWithRef<typeof SliderRoot>, OmitSliderRootProperties>>

const Slider = ({
    value,
    onValueChange,
    onValueCommit,
    defaultValue,

    min,
    max,

    disabled,

    trackClassName,
    trackProps,
    renderTrack,

    thumbClassName,
    thumbPropsBuilder,
    renderThumb,

    renderMark,

    ...props
}: SliderProps) => {
    const [innerValue, setInnerValue] = useState(() => {
        if (defaultValue) return defaultValue
        if (min) return [min]
        return [0]
    })
    const innerOnValueChange = (v: number[]) => {
        if (!value) {
            setInnerValue(v)
        }
        onValueChange?.(v)
    }
    const actualValue = value ?? innerValue

    const trackColors = useMemo(() => {
        if (actualValue.length === 1) {
            return ['rgb(var(--t-primary))', 'rgb(var(--t-accent-light))']
        }
        if (actualValue.length === 2) {
            return ['rgb(var(--t-accent-light))', 'rgb(var(--t-primary))', 'rgb(var(--t-accent-light))']
        }
        if (actualValue.length > 2) {
            return range(actualValue.length + 1).map((v) => {
                if (v === 0 || v === actualValue.length) {
                    return 'rgb(var(--t-accent-light))'
                }
                return 'rgb(var(--t-primary))'
            })
        }
        return []
    }, [actualValue.length])

    return (
        <SliderRoot
            values={actualValue}
            onChange={innerOnValueChange}
            onFinalChange={onValueCommit}
            min={min ?? 0}
            max={max ?? 100}
            disabled={disabled}
            renderTrack={({ props, children, isDragged, disabled }) => {
                if (renderTrack) {
                    return renderTrack({ props, children, isDragged, disabled })
                }

                return (
                    <SliderTrack
                        className={trackClassName}
                        {...trackProps}
                        ref={props.ref}
                        extraRef={trackProps?.ref}
                        style={{
                            ...props.style,
                            background: getTrackBackground({
                                values: actualValue,
                                colors: trackColors,
                                min: min ?? 0,
                                max: max ?? 100,
                            }),
                            ...trackProps?.style,
                        }}
                        onMouseDown={(e) => {
                            props.onMouseDown(e)
                            trackProps?.onMouseDown?.(e)
                        }}
                        onTouchStart={(e) => {
                            props.onTouchStart(e)
                            trackProps?.onTouchStart?.(e)
                        }}
                        isDragged={isDragged}
                        disabled={disabled}
                    >
                        {children}
                    </SliderTrack>
                )
            }}
            renderThumb={({ props, value: v, isDragged, index }) => {
                if (renderThumb) {
                    return renderThumb({ props, value: v, isDragged, index })
                }

                const thumbProps = thumbPropsBuilder?.(v, index, isDragged)

                return (
                    <SliderThumb
                        {...thumbProps}
                        {...props}
                        ref={props.ref}
                        extraRef={thumbProps?.ref}
                        className={cn(thumbClassName, thumbProps?.className)}
                        style={{
                            ...props.style,
                            ...thumbProps?.style,
                        }}
                        tabIndex={thumbProps?.tabIndex ?? props.tabIndex}
                        draggable={thumbProps?.draggable ?? props.draggable}
                        role={thumbProps?.role ?? props.role}
                        onKeyDown={(e) => {
                            props.onKeyDown(e)
                            thumbProps?.onKeyDown?.(e)
                        }}
                        onKeyUp={(e) => {
                            props.onKeyUp(e)
                            thumbProps?.onKeyUp?.(e)
                        }}
                    />
                )
            }}
            renderMark={renderMark}
            {...props}
        />
    )
}

export { SliderTrack, SliderThumb, Slider }
