import React, { useEffect } from 'react'
import { Section } from '@/shared/ui/Section'
import { LoadBoardFilters } from './LoadBoardFilters'
import { debounce } from 'lodash'
import { store, useAppDispatch } from '@/app/store'
import { useScreen } from '@/shared/hooks/useScreen'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'
import { LoadboardCategory } from '@/entities/loadboard-orders/model'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { FilterParams } from '@/pages/LoadBoard/model/types'

type LoadBoardSidebarProps = {
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
}

const setQueryParams = (setParams: SetQuery<GetLoadboardParams>) => {
    const filters = store.getState().loadboard.filters
    if (filters) {
        const { lat, lng, search, ...rest } = filters
        setParams({ ...rest, pageIndex: 0 })
    }
}

const debouncedSetQueryParams = debounce(setQueryParams, 300)

export const LoadBoardSidebar = ({ params, setParams }: LoadBoardSidebarProps) => {
    const dispatch = useAppDispatch()

    const onParamsChanged = (
        newParams: Partial<FilterParams>,
        options?: {
            invokeImmediately?: boolean
            skipSettingParams?: boolean
        },
    ) => {
        dispatch(loadboardActions.setFilters(newParams))

        if (options?.skipSettingParams) {
            return
        }

        debouncedSetQueryParams(setParams)

        /** Small hack for initial LoadBoard fetching deduplication.
         *  When query params have locationSearch and/or locationWarehouse, async fetching
         *  for these params causes double GET Loadboard calls. This thing below prevents it.  */
        if (options?.invokeImmediately) {
            debouncedSetQueryParams.flush()
        }
    }

    const onClear = () => {
        /** On reset cancel previously called debounced setter,
         *  so it will not update after reset */
        debouncedSetQueryParams.cancel()

        const newFilters = {
            search: null,
            hideQuoted: null,

            services: null,
            freightEnvironment: null,
            certifications: null,
            startDate: null,
            endDate: null,

            /** Params unique for non "new-opportunities" tab */
            warehouse: null,

            /** Params unique for "new-opportunities" tab */
            useWHLocation: null,
            locationWarehouse: null,
            locationSearch: null,
            lat: null,
            lng: null,
            locationDistance: null,
        }

        dispatch(loadboardActions.setFilters(newFilters))
        const { lat, lng, ...newParams } = newFilters
        setParams({ ...newParams })
    }

    /** Cancel any called debounced setter,
     *  so it won't set query params after user has left the page. */
    useEffect(() => {
        return () => {
            debouncedSetQueryParams.cancel()
        }
    }, [])

    const isLG = useScreen('lg')

    return (
        <Section className="shrink-0 w-[260px] h-fit !p-0 hidden md:block border border-border">
            <LoadBoardFilters
                category={params.category as LoadboardCategory}
                onParamsChanged={onParamsChanged}
                onClear={onClear}
                showResetButton
                showSearchInput={!isLG}
            />
        </Section>
    )
}
