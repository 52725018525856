export interface ChatMessageAttachmentDto {
    id: number
    key: string
    name: string
    isImage: boolean
    thumb?: ArrayBuffer
    mimeType: string
}

export interface ChatMessageDto {
    id: number
    managerId: number
    chatId?: number
    message: string
    reference: string
    mode: ChatMessagingMode
    createdAt: Date
    name: string
    nameShort: string
    me?: boolean
    olimp?: boolean
    attachments?: ChatMessageAttachmentDto[]
    seenBy?: number[]
}
export interface ChatParticipantDto {
    id: number
    managerId: number
    nameShort: string
}
export interface MessageBoardChatDto {
    id: number
    entity_id: string
    account_name?: string
    account_id?: string
    message_created_at: Date
    unseen_messages_count: number
    psm_viewed: boolean
}
export interface MessageBoardDto {
    id: number
    sf_opportunity_id: string
    account_name: string
    rfq_key: string
    psm_email: string
    psm_first_name: string
    psm_last_name: string
    demand_contact_id: string
    demand_first_name?: string
    demand_last_name?: string
    demand_email: string
    created_at: Date
    message_created_at: Date
    unseen: number
    psm_viewed: number
    chats: MessageBoardChatDto[]
}

export interface MessageBoardFilters {
    myChats?: boolean
    hasChats?: boolean
    serviceDateOut?: string
    search: string
}

export enum ChatMessagingMode {
    // Only Supply and PSM talking
    SUPPLY_PSM = 'SUPPLY_PSM',
    // Only Supply and Demand talking
    DEMAND_PSM = 'DEMAND_PSM',
    // All participants talking
    ALL = 'ALL',
}
