import React, { useEffect } from 'react'
import { LoadboardOrder, LoadboardOrderStatus } from '@/entities/loadboard-orders/model'
import { OrderQuoteSubmit, OrderQuoteSubmitted } from './ui'
import { Loader } from '@/components/Loader'
import { TabsList, TabsRoot, TabsTrigger } from '@/shared/ui/Tabs'
import { Badge } from '@/shared/ui/Badge'
import { Select } from '@/shared/ui/Select'
import { QuoteType } from '@/entities/quotes/model'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { revertToInitial, setQuoteType } from './model'
import { OrderStageSelect } from './ui/OrderStageSelect'
import { cn } from '@/shared/utils/cn'
import { useOrderQuoteWarehouses } from './lib/useOrderQuoteWarehouses'
import { OrderQuoteHistory } from '@/pages/Order/ui/OrderQuote/ui/OrderQuoteSupply/ui/OrderQuoteHistory'

export const OrderQuoteSupply = () => {
    const dispatch = useAppDispatch()
    const { isLoading, error, warehousesOptions, onWarehouseSelect } = useOrderQuoteWarehouses()

    /** Revert to initial state once the page is unloaded */
    useEffect(() => {
        return () => {
            dispatch(revertToInitial())
        }
    }, [])

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const quoteType = useAppSelector((state) => state.orderQuoteSupply.quoteType)
    const quoteMode = useAppSelector((state) => state.orderQuoteSupply.quoteMode)

    const warehouses = useAppSelector((state) => state.orderQuoteSupply.warehouses)
    const selectedWarehouse = useAppSelector((state) => state.orderQuoteSupply.selectedWarehouse)

    const activeQuote = useAppSelector((state) => state.orderQuoteSupply.activeQuote)
    const isStandardQuote = activeQuote?.type === QuoteType.STANDARD

    /** UI */
    /** ================ */
    if (isLoading || error || !warehouses) {
        return <Loader />
    }

    return (
        <div className="space-y-5">
            <div className={cn('flex md:flex-row gap-5', activeQuote ? 'flex-col' : 'flex-col-reverse')}>
                {order.status !== LoadboardOrderStatus.CLOSED &&
                order.status !== LoadboardOrderStatus.BID_ACCEPTED &&
                !activeQuote ? (
                    <TabsRoot value={quoteType} onValueChange={(v) => dispatch(setQuoteType(v))} className="shrink-0">
                        <TabsList className="w-full md:w-auto" variant="filled">
                            <TabsTrigger value="standard" className="w-full md:w-auto gap-2">
                                <div>
                                    Standard
                                    <span className="hidden sm:inline">&nbsp;Quote</span>
                                </div>
                                <Badge className="bg-background-secondary text-foreground-primary px-1 h-[22px] rounded-lg border border-primary hover:bg-background-secondary">
                                    New
                                </Badge>
                            </TabsTrigger>
                            <TabsTrigger value="allIn" className="w-full md:w-auto">
                                All In
                                <span className="hidden sm:inline">&nbsp;Quote</span>
                            </TabsTrigger>
                        </TabsList>
                    </TabsRoot>
                ) : null}

                <Select
                    options={warehousesOptions ?? []}
                    label="Viewing"
                    value={selectedWarehouse?.id ?? ''}
                    onValueChange={onWarehouseSelect}
                    wrapperClassName="w-full"
                    triggerClassName="pr-7 [&>span]:text-ellipsis [&>span]:overflow-hidden [&>span]:block"
                />

                {order.status !== LoadboardOrderStatus.CLOSED && activeQuote ? <OrderStageSelect /> : null}
            </div>

            {!selectedWarehouse ? (
                <Loader />
            ) : (
                <>
                    {{
                        default:
                            order.status !== LoadboardOrderStatus.ACTIVE || activeQuote ? null : (
                                <div>
                                    <p className="text-2xl font-semibold">Submit your Quote</p>
                                    <p>Add your pricing for the service(s) requested below</p>
                                </div>
                            ),
                        edit: !activeQuote ? null : <div className="text-2xl font-semibold">Update your Quote</div>,
                        revise: !activeQuote ? null : <div className="text-2xl font-semibold">Revise your Quote</div>,
                    }[quoteMode] ?? null}

                    {activeQuote ? <OrderQuoteSubmitted /> : <OrderQuoteSubmit />}

                    <OrderQuoteHistory />
                </>
            )}
        </div>
    )
}
