import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { DropzoneFile } from 'dropzone'
import * as Yup from 'yup'
import { HttpV2 } from '@shared/apiv2/api'
import { ResultPhoneProps } from '@/components/InputPhone/InputPhone'
import useNavHelper from '@/hooks/useNavHelper'
import { useUserState } from '@/store/user'

interface UseRfqValidationSchemaProps {
    clientPhoneValidation: ResultPhoneProps | undefined
    driverPhoneValidation: ResultPhoneProps | undefined
}

const useRfqValidationSchema = ({ clientPhoneValidation, driverPhoneValidation }: UseRfqValidationSchemaProps) => {
    const {
        pages: { isGeneralRfqPage, isOnDemandRfqPage },
    } = useNavHelper()
    const location = useLocation()
    const { errorMessage: clientPhoneErrorMessage } = clientPhoneValidation || {}
    const { errorMessage: driverPhoneErrorMessage } = driverPhoneValidation || {}

    const { state } = useUserState()
    const { isLoggedIn } = state

    Yup.addMethod(Yup.string, 'trim', function () {
        return this.transform((value) => (typeof value === 'string' ? value.trim() : value))
    })

    return Yup.object({
        serviceLocation: Yup.object({
            formattedAddress: Yup.string().required('Type and select a location'),
        }),
        commodity: Yup.string().max(255, 'Max 255 characters'),
        serviceDate: Yup.object({
            start: Yup.string().required('Field is required'),
            end: Yup.string()
                .notRequired()
                .test('is-after-start', 'End date must not precede start date', function (value) {
                    return validateDates(this.parent.start, value)
                }),
        }),
        servicesNeeded: Yup.array().required().min(1, 'Select at least one option'),
        freightEnvironment: Yup.string().required('Select an option'),
        certifications: Yup.array(),
        unitType: Yup.string().required('Field is required'),
        numberOfUnits: Yup.number().typeError('Must be a number').required('Field is required').min(1),
        company: Yup.string().required('Field is required'),
        firstName: Yup.string().required('Field is required'),
        lastName: Yup.string().required('Field is required'),
        phone: Yup.string()
            .required('Field is required')
            .test('Validation error', function (value) {
                const { path, createError } = this

                if (value && clientPhoneErrorMessage) {
                    return createError({
                        path,
                        message: clientPhoneErrorMessage,
                    })
                } else {
                    return true
                }
            }),
        phoneExtention: Yup.string().max(255, 'Max 255 characters'),
        email: Yup.string()
            .required('Field is required')
            .email('Please enter a valid email address')
            .test('Validation error', async function (clientEmail) {
                const { path, createError } = this

                if (!clientEmail) return false

                if (isGeneralRfqPage || isOnDemandRfqPage) {
                    const isMatched = await matchEmailWithCustomSite(clientEmail)
                    if (isMatched?.name) {
                        return createError({
                            path,
                            message: 'Use your corporate RFQ page',
                        })
                    }
                } else {
                    const isCorporate = await isCorporateEmail(clientEmail)
                    if (!isCorporate && !isLoggedIn) {
                        return createError({
                            path,
                            message:
                                'Please use your corporate email or visit our <a href="/rfq" style="color: #2887FA">General RFQ</a>',
                        })
                    }
                }

                return true
            }),
        files: Yup.array().test('valid-file', 'Please ensure your files meet the upload criteria', (files) => {
            return files ? files.every((file: DropzoneFile) => file.accepted !== false) : true
        }),
        lowTempControlled: Yup.number().when('freightEnvironment', {
            is: 'Specific',
            then: Yup.number().required('Field is required'),
            otherwise: Yup.number().notRequired(),
        }),
        highTempControlled: Yup.number().when('freightEnvironment', {
            is: 'Specific',
            then: Yup.number().required('Field is required'),
            otherwise: Yup.number().notRequired(),
        }),
        hazmatClasses: Yup.array().when('certifications', {
            is: (certifications: string[]) => certifications.includes('Hazmat'),
            then: Yup.array().required().min(1, 'Select at least one option'),
            otherwise: Yup.array().notRequired(),
        }),
        loadNumber: Yup.string()
            .max(255, 'Max 255 characters')
            .test('required', 'Field is required', (value) => {
                return location.pathname.includes('arrive') ? !!value : true
            }),
        truckNumber: Yup.string().max(255, 'Max 255 characters'),
        driverName: Yup.string().max(255, 'Max 255 characters'),
        driverPhone: Yup.string().test('Validation error', function (value) {
            const { path, createError } = this

            if (value && driverPhoneErrorMessage) {
                return createError({
                    path,
                    message: driverPhoneErrorMessage,
                })
            } else {
                return true
            }
        }),
    })
}

export const isCorporateEmail = async (email: string | undefined): Promise<boolean> => {
    if (!email || !email?.includes('@')) {
        return false
    }

    const { data } = await HttpV2.get(`/salesforce/ncd/${email}/check`)
    return data === false
}

interface MathcedClient {
    name: string
    location: string
}

export const matchEmailWithCustomSite = async (clientEmail: string): Promise<MathcedClient> => {
    const emailDomain = clientEmail.split('@')[1]

    if (!emailDomain) {
        return { name: '', location: '' }
    }

    let matchedClient = { name: '', location: '' }
    try {
        const { data } = await HttpV2.get(`/accounts/microsite/by-domain?domain=${emailDomain}`)
        // const data = { name: 'C.H.Robinson', slug: 'chr' } //Test data

        if (data.site && data.site !== 'on-demand-warehousing') {
            matchedClient = {
                name: data.accountName || '',
                location: `/ms/${data.site}/rfq${window.location.search}` || '',
            }
        }
    } catch (e) {
        console.error(e)
    }

    return matchedClient
}

function validateDates(start?: string, value?: string): boolean {
    if (!start || !value) {
        return true
    }

    const startDate = new Date(start)
    const endDate = new Date(value)

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return false
    }

    return endDate >= startDate
}

export default useRfqValidationSchema
