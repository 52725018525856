import { FormValues } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'
import { ToasterWarning } from '@/pages/RFQPortal/parts/Sidebar/SidebarButtons'

export function generateOpportunityId() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    for (let i = 0; i < 8; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length))
    }
    return result
}

// export function checkIfAllStringsEmpty(obj: any): boolean {
//     if (typeof obj === 'string') {
//         return obj === ''
//     }

//     if (typeof obj !== 'object' || obj === null) {
//         return true // If it's not an object or string, consider it valid (i.e., empty).
//     }

//     if (Array.isArray(obj)) {
//         for (const item of obj) {
//             if (!checkIfAllStringsEmpty(item)) {
//                 return false
//             }
//         }
//         return true
//     }

//     for (const key in obj) {
//         if (Object.prototype.hasOwnProperty.call(obj, key) && !checkIfAllStringsEmpty(obj[key])) {
//             return false
//         }
//     }
//     return true
// }

// eslint-disable-next-line complexity
// export const areRequiredFieldsFilled = (
//     formValues: FormValues,
//     requiredClientFields: RfqErrorFields,
//     requiredOpportunityFields: OpportunityError,
// ): boolean => {
//     // Check required fields in clientData
//     for (const field in requiredClientFields) {
//         if (field !== 'opportunities' && !formValues.clientData[field as keyof typeof formValues.clientData]) {
//             return false
//         }
//     }

//     // Ensure there's at least one opportunity
//     if (formValues.project.data.length === 0) {
//         return false
//     }

//     // Check required fields in opportunities
//     for (const opportunity of formValues.project.data) {
//         for (const field in requiredOpportunityFields) {
//             if (field === 'serviceDate') {
//                 // Special handling for nested serviceDate fields
//                 const serviceDate = opportunity.serviceDate
//                 if (!serviceDate || !serviceDate.start || !serviceDate.end) {
//                     return false
//                 }
//             } else {
//                 // Check other fields
//                 const value = opportunity[field as keyof OpportunityError]
//                 if (!value || (Array.isArray(value) && value.length === 0)) {
//                     return false
//                 }
//             }
//         }
//     }

//     return true
// }

export const trimStringFields = (obj: any): any => {
    if (obj === null || obj === undefined) {
        return obj
    }

    if (typeof obj === 'string') {
        return obj.trim()
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => trimStringFields(item))
    }

    if (typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            acc[key] = trimStringFields(obj[key])
            return acc
        }, {} as any)
    }

    return obj
}

export const removeEmptyStrings = (obj: any): any => {
    if (obj === null || obj === undefined) {
        return obj
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => removeEmptyStrings(item))
    }

    if (typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const value = removeEmptyStrings(obj[key])
            if (typeof value !== 'string' || (typeof value === 'string' && value.trim() !== '')) {
                acc[key] = value
            }
            return acc
        }, {} as any)
    }

    return obj
}

export const removeUndefinedProperties = (obj: any): any => {
    if (obj === null || obj === undefined) {
        return obj
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => removeUndefinedProperties(item)).filter((item) => item !== undefined)
    }

    if (typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const value = removeUndefinedProperties(obj[key])
            if (value !== undefined) {
                acc[key] = value
            }
            return acc
        }, {} as any)
    }

    return obj
}

export const removeEmptyObjectsAndArrays = (obj: any): any => {
    if (obj === null || obj === undefined) {
        return obj
    }

    if (Array.isArray(obj)) {
        const newArr = obj.map((item) => removeEmptyObjectsAndArrays(item)).filter((item) => item !== undefined)
        if (newArr.length === 0) {
            return undefined
        }
        return newArr
    }

    if (typeof obj === 'object') {
        const newObj = Object.keys(obj).reduce((acc, key) => {
            const value = removeEmptyObjectsAndArrays(obj[key])
            if (value !== undefined) {
                acc[key] = value
            }
            return acc
        }, {} as any)

        if (Object.keys(newObj).length === 0) {
            return undefined
        }
        return newObj
    }

    return obj
}

export const convertArraysToStrings = (obj: any): any => {
    if (obj === null || obj === undefined) {
        return obj
    }

    if (Array.isArray(obj)) {
        return obj.join(', ') // Converts array to comma-separated string
    }

    if (typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            acc[key] = convertArraysToStrings(obj[key])
            return acc
        }, {} as any)
    }

    return obj
}

export const appendToFormData = (form: FormData, key: string, value: any) => {
    if (value === null || value === undefined) {
        return
    }

    if (typeof value === 'object' && !(value instanceof Blob)) {
        // Blob is for File or similar
        if (Array.isArray(value)) {
            value.forEach((v, i) => {
                appendToFormData(form, `${key}[${i}]`, v)
            })
        } else {
            for (const subKey in value) {
                appendToFormData(form, `${key}.${subKey}`, value[subKey])
            }
        }
    } else {
        form.append(key, value)
    }
}

interface SubmitData {
    data: any // old formData object but without files and with filesMapping property
    files: File[]
}

export const extractFilesFromFormData = (formdata: FormValues): SubmitData => {
    const data: any = { ...formdata } // Clone the formdata object
    const files: File[] = [] // Initialize the files array

    // Initialize the filesMapping array
    const filesMapping: number[] = []

    // Counter to keep track of the opportunity index
    let opportunityIndex = 0

    // Iterate through the opportunities in the FormValues
    data.project.data.forEach((opportunity: any) => {
        const opportunityFiles = opportunity.files // Assuming 'files' is the property containing the files

        if (
            opportunityFiles &&
            opportunityFiles instanceof Array &&
            opportunityFiles.length > 0 &&
            opportunityFiles[0] instanceof File
        ) {
            // Add the files to the files array
            files.push(...opportunityFiles)

            // Add the opportunity index to the filesMapping array for each file
            for (let i = 0; i < opportunityFiles.length; i++) {
                filesMapping.push(opportunityIndex)
            }

            // Increment the opportunity index
            opportunityIndex++

            // Remove the 'files' property from the opportunity object
            delete opportunity.files
        }
    })

    // Add the filesMapping property to the formData object
    data.filesMapping = filesMapping

    return {
        data,
        files,
    }
}

export const deleteSymbolsDotsSpacesHyphens = (string: string): string => {
    return string.replace(/[\s.-]/g, '').toLowerCase() // Clean it from symbols, dots, spaces, and hyphens
}

//WP Navigation
export interface WP_navigation {
    action: 'navigate'
    url: string
}

export const navigateToWpPage = (relativeUrl: string) => {
    const message: WP_navigation = {
        action: 'navigate',
        url: relativeUrl,
    }

    window.parent.postMessage(message, '*')
}

export const reloginNeeded = () => {
    const message = {
        action: 'reloginNeeded',
    }

    window.parent.postMessage(message, '*')
}
export const wpTokenRefresh = (newToken: string) => {
    const message = {
        action: 'tokenRefresh',
        token: newToken,
    }

    window.parent.postMessage(message, '*')
}
//

const checkIfIsProdEnv = (): boolean => {
    return process.env.REACT_APP_PROD_HOST === window.location.origin
}

export const isProdEnv = window.location.hostname === 'app.olimpwarehousing.com'
export const isIframe = window !== window.top

export const trimField = (field: string, maxSymbols = 255): string | null => {
    if (field) {
        return field.length > maxSymbols ? field.substring(0, maxSymbols) : field
    }
    return null
}

export const handleToasterWarning = (errorMessages: any) => {
    if (!errorMessages) {
        return []
    }

    const toasterData: ToasterWarning[] = []

    if (errorMessages) {
        const pushDataToToaster = (errorObject: any, fieldname?: string, oppId = '') => {
            if (!fieldname) {
                toasterData.push({
                    errorLabel: generateErrorLabel(errorObject.fieldLabel, errorObject.projectLocationNumber),
                    wrapperEl: errorObject.wrapperEl,
                    oppId: oppId,
                })
            } else {
                toasterData.push({
                    errorLabel: generateErrorLabel(
                        errorObject[fieldname].fieldLabel,
                        errorObject[fieldname].projectLocationNumber,
                    ),
                    wrapperEl: errorObject[fieldname].wrapperEl,
                    oppId: oppId,
                })
            }
        }

        const generateErrorLabel = (fieldLabel: string, projectLocationNumber: string | undefined): string => {
            if (!projectLocationNumber || projectLocationNumber === '') {
                return fieldLabel
            } else {
                return `Location ${projectLocationNumber} - ${fieldLabel}`
            }
        }

        Object.keys(errorMessages).forEach((key) => {
            if (key !== 'opportunities' && errorMessages[key]?.message) {
                pushDataToToaster(errorMessages, key)
            }
        })

        const oppsObject: any = errorMessages?.opportunities

        Object.keys(oppsObject).forEach((opsKey) => {
            const oppObject = oppsObject[opsKey]

            Object.keys(oppObject).forEach((oppKey) => {
                if (oppKey !== 'serviceDate' && oppObject[oppKey]?.message) {
                    pushDataToToaster(oppObject, oppKey, opsKey)
                }

                if (oppKey === 'serviceDate') {
                    const { start: serviceDateStartObject = '', end: serviceDateEndObject = '' } = oppObject[oppKey]

                    if (serviceDateStartObject?.message) {
                        pushDataToToaster(serviceDateStartObject, undefined, opsKey)
                    }

                    if (serviceDateEndObject?.message) {
                        pushDataToToaster(serviceDateEndObject, undefined, opsKey)
                    }
                }
            })
        })

        return toasterData
    }
}

export const generateRandomId = () => {
    return Math.random().toString(36).substring(2, 15)
}

export const getLoadNumberLabel = (slug: string) => {
    switch (slug) {
        case 'arrive':
            return 'Load Number*'
        case 'werner':
            return 'Reference Number'
        default:
            return 'Load Number'
    }
}
