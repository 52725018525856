import React, { useMemo } from 'react'
import { Quote, QuoteType } from '@/entities/quotes/model'
import { Separator } from '@/shared/ui/Separator'
import { standardQuoteService, getSQValuesFromQuote } from '@/pages/Order/ui/OrderQuote/lib'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { useAppSelector } from '@/app/store'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { RfqService, RfqServicePricing } from '@/entities/rfq/model'
import { getRevisedMinimumCharge } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised/lib/getRevisedMinimumCharge'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'
import { cn } from '@/shared/utils/cn'

type OrderQuoteBreakdownProps = {
    quote: Quote
}

export const OrderQuoteBreakdown = ({ quote }: OrderQuoteBreakdownProps) => {
    const { state: userState } = useUserState()
    const isSupplyAccount = userState.userInfo?.role === UserRole.WAREHOUSE_MANAGER
    const isDemandAccount = userState.userInfo?.role === UserRole.FREIGHT_BROKER_MANAGER

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const isStandardQuote = quote.type === QuoteType.STANDARD

    const services = useMemo(() => {
        if (!isStandardQuote) return null
        return getSQValuesFromQuote({
            order,
            quote,
            userType: isSupplyAccount ? 'supply' : 'demand',
            mode: 'read',
        })
    }, [order, quote])

    const quotePrice = isSupplyAccount ? quote.price : quote.sellPrice

    /** If current quote is Standard Quote, and it has previously revised Standard Quote,
     *  calculate services for later comparison */
    const revisedServices = useMemo(() => {
        if (!isDemandAccount) return null
        if (!isStandardQuote) return null
        if (!quote.revisedQuote) return null
        if (quote.revisedQuote.type === QuoteType.ALLIN) return null
        return getSQValuesFromQuote({
            order,
            quote: quote.revisedQuote,
            userType: isSupplyAccount ? 'supply' : 'demand',
            mode: 'read',
        })
    }, [quote])

    if (!isStandardQuote)
        return (
            <div className="space-y-3">
                <div className="flex items-center w-full md:w-fit">
                    <span className="text-sm text-foreground-secondary font-normal">Estimated Total:</span>
                    {quotePrice ? (
                        <CurrencyDisplay
                            value={quotePrice}
                            className="ml-1 text-xl leading-6 text-primary font-semibold"
                        />
                    ) : (
                        <span className="text-sm ml-1 text-foreground-secondary font-medium">No Price Given</span>
                    )}
                </div>
                <Separator />
                <div className="break-word">
                    {quote.notes?.split('\n').map((s, index) => (
                        <p key={index} className="min-h-[1.5rem] text-base">
                            {s}
                        </p>
                    ))}
                </div>
            </div>
        )

    const revisedMinimumCharge = getRevisedMinimumCharge({ quote, revisedQuote: quote.revisedQuote })

    return (
        <div className="sm:space-y-3">
            <div className="hidden sm:flex flex-row gap-3 gap-y-0 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                <div className="sm:w-[100px] lg:w-[170px] flex items-center text-sm font-medium shrink-0 self-start">
                    Service Requested
                </div>
                <Separator orientation="vertical" className="block h-auto self-stretch" />
                <div className="text-sm font-medium">Pricing</div>
            </div>

            {Object.entries(services!).map(([key, service], index) => {
                const Comp = standardQuoteService[key as RfqService]?.breakdownComponent
                if (!Comp) return null

                const revisedService = revisedServices?.[key as RfqService]

                return (
                    <React.Fragment key={index}>
                        <div className="flex flex-col sm:flex-row gap-3 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                            <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                                {service.name}
                            </div>
                            <Separator orientation="vertical" className="hidden sm:block h-auto self-stretch" />
                            <Comp order={order} service={service} revisedService={revisedService} />
                        </div>
                        <Separator />
                    </React.Fragment>
                )
            })}

            <div className="flex flex-col sm:flex-row gap-3 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                    Minimum Charge
                </div>
                <Separator orientation="vertical" className="hidden sm:block h-auto self-stretch" />
                <div className="flex flex-col">
                    {quotePrice ? (
                        <CurrencyDisplay
                            value={quotePrice}
                            className={cn('text-xl leading-6 text-primary font-semibold', {
                                'text-success': revisedMinimumCharge?.status === -1,
                                'text-danger': revisedMinimumCharge?.status === 1,
                            })}
                        />
                    ) : (
                        <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                    )}

                    {revisedMinimumCharge && (
                        <CurrencyDisplay
                            value={revisedMinimumCharge.value}
                            className={cn('text-base font-semibold text-foreground-tertiary', revisedMinimumCharge.status !== 0 && 'line-through')}
                        />
                    )}
                </div>
            </div>

            {quote.notes ? (
                <>
                    <Separator />
                    <div className="flex flex-col sm:flex-row gap-x-3 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                        <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                            Notes
                        </div>
                        <Separator orientation="vertical" className="hidden sm:block h-auto self-stretch" />
                        <div className="mt-2 break-word">
                            {quote.notes?.split('\n').map((s, index) => (
                                <p key={index} className="min-h-[1.5rem] text-base">
                                    {s}
                                </p>
                            ))}
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}
