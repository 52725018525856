import React from 'react'
import { Separator } from '@/shared/ui/Separator'
import { Badge } from '@/shared/ui/Badge'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { format } from 'date-fns'
import {
    RfqCertification,
    RfqCertificationLabel,
    RfqFreightEnv,
    RfqFreightEnvLabel,
    RfqService,
    serviceIcons,
} from '@/entities/rfq/model'

type OrdersDetailsServicesProps = {
    order: LoadboardOrder
}

export const OrderDetailsServices = ({ order }: OrdersDetailsServicesProps) => {
    const services = order.services.split(',')
    const certifications = order.certifications?.split(';')
    const hazmatClasses = order.hazmatClasses?.join(', ')

    const freightEnvTemp =
        order.lowTempControlled !== null && order.highTempControlled !== null
            ? ` (${order.lowTempControlled} ° - ${order.highTempControlled} °)`
            : ''

    return (
        <div className="flex gap-5 flex-col lg:flex-row">
            <div className="grid grid-cols-2 auto-cols-max md:grid-cols-[160px_auto] w-full gap-y-5 gap-x-2 h-fit text-sm">
                <div className="text-sm text-foreground-secondary">Service Location</div>
                <div className="font-medium">{order.address}</div>
                <div className="text-sm text-foreground-secondary">Created Date</div>
                <div className="font-medium">{format(new Date(order.createdAt), 'MM/dd/yyyy')}</div>
                {order.contactEmail && order.contactFullName ? (
                    <>
                        <div className="text-sm text-foreground-secondary">Created By</div>
                        <div className="overflow-hidden">
                            <div className="text-ellipsis overflow-hidden text-base text-nowrap">
                                {order.contactFullName}
                            </div>
                            <div className="text-ellipsis overflow-hidden font-medium">{order.contactEmail}</div>
                        </div>
                    </>
                ) : null}
                <div className="col-span-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-2">
                    <div className="text-sm text-foreground-secondary sm:col-span-2 lg:col-span-1">Services Needed</div>
                    {services.map((service, index) => {
                        const Icon = serviceIcons[service as RfqService]
                        return (
                            <Badge
                                key={index}
                                variant="secondary"
                                className="w-full px-3 min-h-10 rounded-lg text-base font-semibold flex items-center gap-3 bg-transparent hover:bg-background-tertiary"
                            >
                                {Icon ? <Icon size={24} className="text-foreground-tertiary" /> : null}
                                {service}
                            </Badge>
                        )
                    })}
                </div>
            </div>
            <Separator orientation="horizontal" className="block lg:hidden" />
            <Separator orientation="vertical" className="h-auto hidden lg:block" />
            <div className="grid grid-cols-2 auto-cols-max md:grid-cols-[160px_auto] w-full gap-y-5 gap-x-2 h-fit text-sm">
                <div className="text-foreground-secondary">Service Date(s)</div>
                <div className="font-medium">
                    {format(new Date(order.startDate), 'MM/dd/yyyy')} - {format(new Date(order.endDate), 'MM/dd/yyyy')}
                </div>
                <div className="text-foreground-secondary">Freight Environment</div>
                <div className="font-medium">
                    {RfqFreightEnvLabel[order.freightEnvironment as RfqFreightEnv] + freightEnvTemp}
                </div>
                <div className="text-foreground-secondary">Certifications</div>
                <div className="font-medium space-y-2">
                    {certifications ? (
                        <>
                            {certifications.map((certification, index) => (
                                <div key={index}>{RfqCertificationLabel[certification as RfqCertification]}</div>
                            ))}
                        </>
                    ) : (
                        '-'
                    )}
                </div>
                {certifications?.includes(RfqCertification.Hazmat) && (
                    <>
                        <div className="text-foreground-secondary">Hazmat Types</div>
                        <div className="font-medium">{hazmatClasses || '-'}</div>
                    </>
                )}
                <div className="text-foreground-secondary">Commodity</div>
                <div className="font-medium">{order.commodity || '-'}</div>
                <div className="text-foreground-secondary">Load/Container #</div>
                <div className="font-medium">{order.loadNumber || '-'}</div>
                <Separator className="col-span-2" />
                <div className="text-foreground-secondary">Unit Type</div>
                <div className="font-medium">{order.unitType || '-'}</div>
                <div className="text-foreground-secondary"># of Units</div>
                <div className="font-medium">{order.totalUnits}</div>
            </div>
        </div>
    )
}
