import React from 'react'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Label } from '@/shared/ui/Label'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { CheckedState } from '@radix-ui/react-checkbox'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'

type ControlsHideQuotedProps = {
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
}

export const ControlsHideQuoted = ({ setParams }: ControlsHideQuotedProps) => {
    const dispatch = useAppDispatch()
    const hideQuoted = useAppSelector((state) => state.loadboard.filters?.hideQuoted)

    const onHideQuotedChange = (checked: CheckedState) => {
        const actualChecked = checked === true
        dispatch(loadboardActions.setFilters({ hideQuoted: actualChecked }))
        setParams({ hideQuoted: actualChecked || null })
    }

    return (
        <div className="shrink-0 flex gap-2 items-center">
            <Checkbox id="hideQuoted" checked={hideQuoted ?? false} onCheckedChange={onHideQuotedChange} />
            <Label htmlFor="hideQuoted" className="cursor-pointer">
                Hide Quoted
            </Label>
        </div>
    )
}
