import { AxiosRequestConfig } from 'axios'
import { HttpV2 } from '@shared/apiv2/api'
import { GetLoadboardOrdersRequest, HideLoadboardOrderRequest } from '@/entities/loadboard-orders/model/request'
import { api } from '@/shared/api/rtk-query'
import {
    GetLoadboardOrdersResponse,
    GetLoadboardTotalsResponse,
    LoadboardCategory,
} from '@/entities/loadboard-orders/model'
import { objectToQueryString } from '@shared/apiv2/list-query.utils'

const hideLoadboardOrder = async (req: HideLoadboardOrderRequest, config?: AxiosRequestConfig) => {
    const response = await HttpV2.post(
        `/load-board/order/${req.orderKey}/hide`,
        {
            reason: req.reason,
            reasonComment: req.reasonComment,
        },
        config,
    )
    return response
}

const markLoadboardOrderAsRead = async (orderKey: string, config?: AxiosRequestConfig) => {
    const response = await HttpV2.post(`/load-board/order/${orderKey}/read`, config)
    return response
}

const markLoadboardCategoryAsRead = async (category: LoadboardCategory, config?: AxiosRequestConfig) => {
    const response = await HttpV2.post(`/load-board/read/${category}`, config)
    return response
}

export const loadboardOrdersApi = {
    hideLoadboardOrder,
    markLoadboardOrderAsRead,
    markLoadboardCategoryAsRead,
}

export const loadboardRtkApi = api.enhanceEndpoints({ addTagTypes: ['LoadBoard'] }).injectEndpoints({
    endpoints: (builder) => ({
        getLoadBoard: builder.query<GetLoadboardOrdersResponse, GetLoadboardOrdersRequest>({
            query: (req) => ({ url: `/load-board?${objectToQueryString(req)}` }),
        }),
        getLoadBoardTotals: builder.query<GetLoadboardTotalsResponse, void>({
            query: () => ({ url: '/load-board/totals' }),
            providesTags: [{ type: 'LoadBoard', id: 'Totals' }],
        }),
    }),
})

export const { useLazyGetLoadBoardQuery, useGetLoadBoardTotalsQuery } = loadboardRtkApi
