import React, { useState } from 'react'
import { Section } from '@/shared/ui/Section'
import { TabsList, TabsRoot, TabsTrigger } from '@/shared/ui/Tabs'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { useAppSelector } from '@/app/store'
import { OrderDetailsServices } from './ui/OrderDetailsServices'
import { OrderDetailsNotes } from './ui/OrderDetailsNotes'
import { OrderDetailsFiles } from './ui/OrderDetailsFiles'
import { OrderDetailsTeamMembers } from './ui/OrderDetailsTeamMembers'
import { OrderDetailsStatus } from './ui/OrderDetailsStatus'

export const OrderDetails = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const [selectedTab, setSelectedTab] = useState('services')
    const { state: userState } = useUserState()

    /** Basic information to determine what to display and/or load. */
    const isSupplyAccount = userState.userInfo?.role === UserRole.WAREHOUSE_MANAGER

    return (
        <Section className="pt-2 md:pt-2">
            {/** Order ID, Tabs, Status */}
            <div className="border-b border-border flex flex-col md:flex-row items-center gap-x-5 gap-y-3">
                {/** Displays Order ID and dynamically displays Status for smaller screens */}
                <div className="flex w-full md:w-auto items-center justify-between shrink-0 px-2 pt-2 md:px-0">
                    <div className="text-lg md:text-2xl font-semibold text-foreground-pure">{order.id}</div>
                    <div className="md:hidden">
                        <OrderDetailsStatus />
                    </div>
                </div>

                {/** Displays Tabs and dynamically displays Status for larger screens */}
                <div className="flex w-full">
                    {!userState.isLoggedIn ? null : (
                        <TabsRoot value={selectedTab} onValueChange={setSelectedTab} className="w-full md:w-auto">
                            <TabsList className="md:h-[50px] w-full md:w-auto">
                                <TabsTrigger value="services" className="w-full md:w-auto">
                                    Services
                                </TabsTrigger>
                                {order.notes ? (
                                    <TabsTrigger value="notes" className="w-full md:w-auto">
                                        Notes
                                    </TabsTrigger>
                                ) : null}
                                {order.attachments.length > 0 ? (
                                    <TabsTrigger value="files" className="w-full md:w-auto">
                                        Files
                                    </TabsTrigger>
                                ) : null}
                                {!isSupplyAccount && order.teamMembers?.length ? (
                                    <TabsTrigger value="teamMembers" className="w-full md:w-auto">
                                        Team<span className="hidden sm:inline">&nbsp;Members</span>
                                    </TabsTrigger>
                                ) : null}
                            </TabsList>
                        </TabsRoot>
                    )}
                </div>
                <div className="hidden md:block">
                    <OrderDetailsStatus />
                </div>
            </div>

            {/** Display content depending on selected Tab */}
            <div className="mt-5">
                {{
                    services: <OrderDetailsServices order={order} />,
                    notes: <OrderDetailsNotes order={order} />,
                    files: <OrderDetailsFiles order={order} />,
                    teamMembers: <OrderDetailsTeamMembers order={order} />,
                }[selectedTab] ?? null}
            </div>
        </Section>
    )
}
