import React from 'react'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { Navigate } from 'react-router-dom'

const Home = () => {
    const { dispatch, state } = useUserState()
    const targetAppUrl = localStorage.getItem('targetAppUrl')!

    if (state.isLoggedIn && state.userInfo) {
        const isPSM = state.userInfo?.role === UserRole.PSM
        if (isPSM) {
            localStorage.setItem('psmToken', localStorage.getItem('token')!)
        }

        if (state.userInfo.role === UserRole.UNKNOWN) {
            return (
                <Navigate
                    to={{
                        pathname: '/logout',
                        search: window.location.search,
                    }}
                />
            )
        }
        if (!state.userInfo.verified && state.userInfo.sfContactId) {
            return <Navigate to={'/sign-up/verification'} />
        }
        if (targetAppUrl && !isPSM) {
            return <Navigate to={{ pathname: targetAppUrl }} />
        }
        return (
            <Navigate
                to={
                    state.userInfo?.role === UserRole.WAREHOUSE_MANAGER
                        ? '/loadboard'
                        : isPSM
                        ? '/psm/message-board'
                        : '/rfq'
                }
            />
        )
    } else {
        return (
            <Navigate
                to={{
                    pathname: '/login',
                    search: window.location.search,
                }}
            />
        )
    }
}

export default Home
