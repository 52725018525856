import { StandardQuoteService } from '@/pages/Order/ui/OrderQuote/model'
import { RfqServicePricing } from '@/entities/rfq/model'
import { StandardQuoteRevisedChanges } from '../model'

type GetRevisedChanges = {
    service: StandardQuoteService
    revisedService?: StandardQuoteService
    pricing: RfqServicePricing

    includeType?: boolean
    includeModifier?: boolean
}

export const getRevisedChanges = ({
    service,
    revisedService,
    pricing,
    includeType,
    includeModifier,
}: GetRevisedChanges) => {
    if (!revisedService || !revisedService.pricings[pricing]) return null
    if (!service.pricings[pricing] || !revisedService.pricings[pricing]) return null

    const result: StandardQuoteRevisedChanges = {}

    /** Convert string values to numbers for further comparison */
    const numberedPrice = Number(service.pricings[pricing]!.price)
    const numberedRevisedPrice = Number(revisedService.pricings[pricing]!.price)

    /** If there is no Current Price or Revised Price, do not show comparison */
    if (!numberedPrice || !numberedRevisedPrice) return null

    /** Get price comparison result */
    if (numberedPrice > numberedRevisedPrice) {
        result.price = {
            value: revisedService.pricings[pricing]!.price,
            status: 1,
        }
    } else if (numberedPrice < numberedRevisedPrice) {
        result.price = {
            value: revisedService.pricings[pricing]!.price,
            status: -1,
        }
    } else {
        result.price = {
            value: revisedService.pricings[pricing]!.price,
            status: 0,
        }
    }

    /** Add service.type to the comparison, if needed */
    if (includeType) {
        const type = service.type
        const revisedType = revisedService.type

        result.type = {
            value: revisedType,
            status: type !== revisedType ? 1 : 0,
        }
    }

    /** Add pricing.modifier to the comparison, if needed */
    if (includeModifier) {
        const modifier = service.pricings[pricing]!.modifier
        const revisedModifier = revisedService.pricings[pricing]!.modifier

        result.modifier = {
            value: revisedModifier,
            status: modifier !== revisedModifier ? 1 : 0,
        }
    }

    /** Check final result, if there is no changes between values, do not show comparison */
    let shouldSkip = true
    Object.entries(result).forEach(([_, entry]) => {
        if (entry.status !== 0) shouldSkip = false
    })
    if (shouldSkip) return null

    return result
}
