import * as React from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'

import { cn } from '@/shared/utils/cn'
import { Label } from '@/shared/ui/Label'
import { ScrollArea } from '@/shared/ui/ScrollArea'
import { InChevronDown } from '@/shared/ui/Icons/InChevronDown'
import { InCheckmark } from '@/shared/ui/Icons/InCheckmark'
import { ErrorMessage } from '@/shared/ui/ErrorMessage'
import { useComposedRefs } from '@/shared/hooks/useComposedRefs'
import { autoUpdate, size, useFloating } from '@floating-ui/react'

const SelectRoot = SelectPrimitive.Root

const SelectTriggerWrapper = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return <div className={cn('h-fit relative', className)} ref={ref} {...props} />
    },
)
SelectTriggerWrapper.displayName = 'SelectTriggerWrapper'

const SelectTrigger = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
            !props.asChild &&
                'peer flex h-9 px-3 w-full items-center justify-between rounded-lg !text-sm border border-solid border-border bg-background-secondary outline-none focus:border-primary hover:border-primary disabled:hover:border-border !font-normal ring-offset-background placeholder:text-muted-foreground data-[state=open]:border-primary transition-colors duration-75 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 data-[placeholder]:text-foreground-tertiary ring-offset-background placeholder:text-muted-foreground ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
            className,
        )}
        {...props}
    >
        {children}
    </SelectPrimitive.Trigger>
))
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectTriggerLabel = React.forwardRef<
    React.ElementRef<typeof Label>,
    React.ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => {
    return (
        <Label
            className={cn(
                'bg-white px-[6px] text-foreground-secondary font-normal absolute z-50 top-0 -translate-y-1/2 left-[6px]',
                className,
            )}
            ref={ref}
            {...props}
        />
    )
})
SelectTriggerLabel.displayName = 'SelectTriggerLabel'

const SelectTriggerIcon = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Icon>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Icon>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Icon
        ref={ref}
        className={cn(
            'absolute right-0 top-0 h-full w-fit flex items-center bg-transparent text-foreground-tertiary peer-disabled:cursor-not-allowed peer-disabled:pointer-events-none peer-disabled:opacity-50',
            className,
        )}
        {...props}
        asChild
    >
        <span>{children}</span>
    </SelectPrimitive.Icon>
))
SelectTriggerIcon.displayName = SelectPrimitive.Icon.displayName

const SelectValue = SelectPrimitive.Value

const SelectContent = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={(el) => {
                el?.addEventListener('touchend', (e) => e.preventDefault())
                if (typeof ref === 'function') {
                    ref(el)
                } else if (ref) {
                    ref.current = el
                }
            }}
            className={cn(
                'relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-lg border border-border bg-background-secondary text-foreground-primary shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                position === 'popper' &&
                    'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
                className,
            )}
            position={position}
            {...props}
        >
            <SelectPrimitive.Viewport
                className={cn(
                    'p-1',
                    position === 'popper' &&
                        'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
                )}
            >
                <ScrollArea viewportClassName="max-h-72">{children}</ScrollArea>
            </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectGroup = SelectPrimitive.Group

const SelectGroupLabel = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Label>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={cn('h-8 px-2 text-sm font-semibold flex items-center', className)}
        {...props}
    />
))
SelectGroupLabel.displayName = 'SelectGroupLabel'

export type SelectItemProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
    indicator?: React.ReactNode
}

const SelectItem = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, SelectItemProps>(
    ({ className, children, indicator, ...props }, ref) => (
        <SelectPrimitive.Item
            ref={ref}
            className={cn(
                'relative flex w-full cursor-default select-none items-center rounded h-8 px-2 text-sm outline-none transition-colors duration-75 focus:bg-background-tertiary focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                className,
            )}
            {...props}
        >
            <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
            {indicator !== undefined ? (
                <SelectItemIndicator asChild>{indicator}</SelectItemIndicator>
            ) : (
                <SelectItemIndicator>
                    <InCheckmark className="h-4 w-4" />
                </SelectItemIndicator>
            )}
        </SelectPrimitive.Item>
    ),
)
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectItemIndicator = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ItemIndicator>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.ItemIndicator>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.ItemIndicator
        className={cn('absolute right-2 flex items-center justify-center text-primary', className)}
    >
        {children}
    </SelectPrimitive.ItemIndicator>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-border', className)} {...props} />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export type SelectOption = { value: string; label: string }

export type SelectProps = Omit<SelectPrimitive.SelectProps, 'children'> & {
    options?: SelectOption[]

    wrapperClassName?: string
    wrapperProps?: Omit<React.ComponentProps<typeof SelectTriggerWrapper>, 'className'>

    triggerClassName?: string
    triggerProps?: Omit<React.ComponentProps<typeof SelectTrigger>, 'className'>
    renderTrigger?: () => React.ReactNode

    label?: React.ReactNode
    labelClassName?: string
    labelProps?: Omit<React.ComponentProps<typeof SelectTriggerLabel>, 'className'>

    icon?: React.ReactNode
    iconClassName?: string
    iconWrapperClassName?: string
    iconWrapperProps?: Omit<React.ComponentProps<typeof SelectTriggerIcon>, 'className'>

    error?: React.ReactNode
    errorClassName?: string
    errorProps?: Omit<React.ComponentProps<typeof ErrorMessage>, 'className'>

    placeholder?: React.ReactNode
    valueProps?: Omit<React.ComponentProps<typeof SelectValue>, 'placeholder'>
    renderValue?: () => React.ReactNode

    contentClassName?: string
    contentProps?: Omit<React.ComponentProps<typeof SelectContent>, 'className'>

    itemClassName?: string
    itemPropsBuilder?: (option: SelectOption, index: number) => Omit<React.ComponentProps<typeof SelectItem>, 'value'>
    itemIndicator?: React.ReactNode
    renderItemContent?: (option: SelectOption, index: number) => React.ReactNode
}

const Select = ({
    options,

    wrapperClassName,
    wrapperProps,

    triggerClassName,
    triggerProps,
    renderTrigger,

    label,
    labelClassName,
    labelProps,

    icon,
    iconClassName,
    iconWrapperClassName,
    iconWrapperProps,

    error,
    errorClassName,
    errorProps,

    placeholder,
    valueProps,
    renderValue,

    contentClassName,
    contentProps,

    itemClassName,
    itemPropsBuilder,
    itemIndicator,
    renderItemContent,
    ...rest
}: SelectProps) => {
    const { refs } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            size({
                apply: ({ elements, rects }) => {
                    const { width: triggerWidth, height: triggerHeight } = rects.reference
                    const contentStyle = elements.floating.style
                    contentStyle.setProperty('--select-trigger-width', `${triggerWidth}px`)
                    contentStyle.setProperty('--select-trigger-height', `${triggerHeight}px`)
                },
            }),
        ],
    })
    const composedTriggerRef = useComposedRefs(triggerProps?.ref, (node) => refs.setReference(node))
    const composedWrapperRef = useComposedRefs(wrapperProps?.ref, (node) => refs.setFloating(node))

    return (
        <SelectRoot {...rest}>
            <SelectTriggerWrapper className={wrapperClassName} {...wrapperProps} ref={composedWrapperRef}>
                {label ? (
                    <SelectTriggerLabel
                        className={cn(
                            rest.disabled && 'cursor-not-allowed opacity-70',
                            !!error && 'text-danger',
                            labelClassName,
                        )}
                        {...labelProps}
                    >
                        {label}
                    </SelectTriggerLabel>
                ) : null}

                {renderTrigger ? (
                    <SelectTrigger asChild>{renderTrigger()}</SelectTrigger>
                ) : (
                    <SelectTrigger
                        className={cn(!!error && 'border-danger', triggerClassName)}
                        {...triggerProps}
                        ref={composedTriggerRef}
                    >
                        {renderValue ? (
                            <SelectValue placeholder={placeholder}>{renderValue()}</SelectValue>
                        ) : (
                            <SelectValue placeholder={placeholder} {...valueProps} />
                        )}
                    </SelectTrigger>
                )}

                <SelectTriggerIcon
                    className={cn(
                        'h-[var(--select-trigger-height)] left-[var(--select-trigger-width)] -translate-x-full',
                        iconWrapperClassName,
                    )}
                    {...iconWrapperProps}
                >
                    {icon !== undefined ? icon : <InChevronDown size={12} className={cn('mr-3', iconClassName)} />}
                </SelectTriggerIcon>

                <ErrorMessage className={cn('ml-3 mt-0.5', errorClassName)} {...errorProps}>
                    {error}
                </ErrorMessage>
            </SelectTriggerWrapper>

            <SelectContent className={contentClassName} {...contentProps}>
                {options?.map((option, index) => {
                    const itemProps = itemPropsBuilder?.(option, index)

                    return (
                        <SelectItem
                            key={option.value}
                            value={option.value}
                            {...itemProps}
                            className={cn(itemClassName, itemProps?.className)}
                            indicator={itemProps?.indicator !== undefined ? itemProps?.indicator : itemIndicator}
                        >
                            {renderItemContent ? renderItemContent(option, index) : option.label}
                        </SelectItem>
                    )
                })}
            </SelectContent>
        </SelectRoot>
    )
}

export {
    SelectRoot,
    SelectTriggerWrapper,
    SelectTrigger,
    SelectTriggerIcon,
    SelectTriggerLabel,
    SelectValue,
    SelectContent,
    SelectGroup,
    SelectGroupLabel,
    SelectItem,
    SelectItemIndicator,
    SelectSeparator,
    Select,
}
