import React, { FC, useEffect } from 'react'
import { matchPath, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { UserActionType } from '@/store/user/user'
import { userLogout, useUserState } from '@/store/user'
import { Http } from '@shared/api/Http'
import { NotFound } from './pages/NotFound/NotFound'
import SignUp from './pages/SignUp'
import ROUTES, { envInfo, GUEST_ROUTES } from '@/router'
import { useLocation } from 'react-router'
import { LoginPage } from '@/pages/Login/LoginPage'
import { RfqPortalRoutes } from '@/pages/RFQPortal/RfqPortalRoutes'
import Home from '@/pages/Home/Home'
import { Invites } from '@/pages/Invites/Invites'
import { VerifyEmail } from '@/pages/VerifyEmail/VerifyEmail'
import { WarehousesRoutes } from '@/pages/Warehouses/WarehousesRoutes'
import { AdminRoutes } from '@/pages/Admin/AdminRoutes'
import { ServiceNotAvailable } from '@/ServiceNotAvailable'
import HowItWorksPage from '@/pages/HowItWorks'
import FaqPage from '@/pages/FAQ'
import { AccountRoutes } from '@/pages/Account/AccountRoutes'
import { getRelativePath } from '@/pages/RFQPortal/RfqForm/rfqNavigate'
import { OrderPage } from '@/pages/Order'
import { LoadBoardPage } from '@/pages/LoadBoard'
import { PSMRoutes } from '@/pages/PSM/PSMRoutes'
import { DevRoutes } from '@/pages/Dev/DevRoutes'

const Logout: FC = () => {
    const navigate = useNavigate()
    const { dispatch } = useUserState()
    useEffect(() => {
        const logout = async () => {
            await userLogout(dispatch!)
            localStorage.clear()
            delete Http.defaults.headers.common.Authorization
            navigate(ROUTES.index)
        }
        logout()
    }, [])
    return <></>
}

interface AppRoutesProps {
    shouldLogoReset: boolean
    setShouldLogoReset: (shouldReset: boolean) => void
}

const AppRoutes: FC<AppRoutesProps> = ({ shouldLogoReset, setShouldLogoReset }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { state, dispatch } = useUserState()
    useEffect(() => {
        dispatch?.({ type: UserActionType.UPDATE_LAST_PATH, pathname: location.pathname })
    }, [dispatch, location])

    // useEffect(() => {
    //     if (
    //         state.isLoggedIn &&
    //         state.userInfo &&
    //         location.pathname.indexOf('sign-up') < 0 &&
    //         location.pathname.indexOf('service/email') < 0 &&
    //         !(location.pathname === '' || location.pathname === '/' || location.pathname.startsWith('/search'))
    //     ) {
    //         navigate(ROUTES.signUp.index)
    //     }
    // }, [state])

    useEffect(() => {
        if (
            state.isLoggedIn &&
            GUEST_ROUTES.some((route) => matchPath(route, location.pathname)) &&
            !location.search.includes('auth_token')
        ) {
            navigate(ROUTES.index, { replace: true })
        }
    }, [location.pathname, navigate, state.isLoggedIn])

    return (
        <div className="content">
            <Routes>
                <Route path={'/'} element={<Home />} />

                {/** Start Authentication routes */}
                <Route path={ROUTES.public.verifyEmail} element={<VerifyEmail />} />
                <Route path={`${ROUTES.signUp.index}/*`} element={<SignUp />} />
                <Route
                    path={ROUTES.public.register}
                    element={<Navigate to={{ pathname: '/sign-up', search: location.search }} />}
                />
                <Route path={ROUTES.public.invites} element={<Invites />} />
                <Route path={ROUTES.public.auth} element={<div />} />
                <Route path={ROUTES.public.login} element={<LoginPage />} />
                <Route path={ROUTES.public.logout} element={<Logout />} />
                {/** End Authentication routes */}


                {/** Start "Demand" User routes */}
                <Route path={`${ROUTES.rfq.old}/*`} element={<RfqPortalRoutes />} />
                <Route path={`${ROUTES.rfq.base}/*`} element={<RfqPortalRoutes />} />
                <Route
                    path={`${ROUTES.rfq.baseNoRFQ}`}
                    element={
                        <Navigate
                            to={{
                                pathname: location.pathname.startsWith('/ms/rfq')
                                    ? '/rfq'
                                    : getRelativePath('rfq').path,
                                search: location.search,
                            }}
                            replace
                        />
                    }
                />
                {/** End "Demand" User routes */}


                {/** Start "Supply" User routes */}
                <Route path={`${ROUTES.loadboard.base}`} element={<LoadBoardPage />} />
                <Route
                    path={`${ROUTES.loadboard.base}/:orderKey`}
                    element={
                        <Navigate
                            to={{
                                pathname: `${ROUTES.order.base}/${location.pathname.split('/')[2]}`,
                                search: location.search,
                            }}
                            replace
                            state={{
                                redirected: true,
                            }}
                        />
                    }
                />
                <Route path={`${ROUTES.warehouses.base}/*`} element={<WarehousesRoutes />} />
                <Route
                    path={`${ROUTES.admin.base}/*`}
                    element={<AdminRoutes shouldLogoReset={shouldLogoReset} setShouldLogoReset={setShouldLogoReset} />}
                />
                <Route path={ROUTES.more['how-it-works']} element={<HowItWorksPage />} />
                <Route path={ROUTES.more.faq} element={<FaqPage />} />
                {/** End "Supply" User routes */}


                {/** Start PSM routes */}
                <Route path={`${ROUTES.psm.base}/*`} element={<PSMRoutes />} />
                {/** End PSM routes */}


                {/** Start common routes */}
                <Route path={`${ROUTES.account.base}/*`} element={<AccountRoutes />} />
                <Route path={`${ROUTES.order.base}/:orderKey`} element={<OrderPage />} />
                {/** End common routes */}


                {/** Error routes */}
                <Route path={'/504'} element={<ServiceNotAvailable />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />


                {/** Utility routes */}
                {envInfo().isLocalHostOrIP && <Route path={`${ROUTES.dev.base}/*`} element={<DevRoutes />} />}
                {/*<Route path={ROUTES.test} element={<Test />} />*/}
            </Routes>
        </div>
    )
}

export default AppRoutes
