import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing } from '@/entities/rfq/model'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'
import { StandardQuoteRevisedValues } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised/ui'
import { getRevisedChanges } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised'
import { cn } from '@/shared/utils/cn'

export const YardStorageBreakdownComponent = ({ service, revisedService }: ServiceBreakdownComponentProps) => {
    const changes = getRevisedChanges({
        service,
        revisedService,
        pricing: RfqServicePricing.Storage,
        includeModifier: true,
    })

    return (
        <div className="flex items-center">
            <div className="flex flex-col">
                {service.pricings[RfqServicePricing.Storage]?.price ? (
                    <div className="flex gap-2 items-center w-full">
                        <CurrencyDisplay
                            value={service.pricings[RfqServicePricing.Storage]!.price}
                            className={cn('text-xl leading-6 text-primary font-semibold', {
                                'text-success': changes?.price?.status === -1,
                                'text-danger': changes?.price?.status === 1,
                            })}
                        />
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                            Spot
                        </span>
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                            {service.pricings[RfqServicePricing.Storage]!.modifier}
                        </span>
                    </div>
                ) : (
                    <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                )}

                <StandardQuoteRevisedValues
                    changes={changes}
                    fields={['price', 'type', 'modifier']}
                    renderRevisedType={() => (
                        <>
                            <span className="text-sm shrink-0 text-foreground-tertiary">/</span>
                            <span className="font-medium">Spot</span>
                        </>
                    )}
                />
            </div>
        </div>
    )
}
