import React, { FC, useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import Input from '@/pages/RFQPortal/components/Input'
import RfqDatepicker from '@/pages/RFQPortal/components/RfqDatepicker'
import Button from '@/components/Button'
import Checkbox from '@/pages/RFQPortal/components/Checkbox'
import GoogleAutocompleteInput from '@/pages/RFQPortal/components/GoogleAutocompleteInput'
import RadioInput from '@/pages/RFQPortal/components/RadioInput'
import Select from '@/pages/RFQPortal/components/Select'
import Textarea from '@/pages/RFQPortal/components/Textarea'
import DropZoneArea from '@/pages/RFQPortal/components/DropZoneArea'
import InputPhone, { ResultPhoneProps, InputPhoneHandle } from '@/components/InputPhone/InputPhone'
import useDynamicValidationSchema, { matchEmailWithCustomSite } from './useRfqValidationSchema'
import TagsInput from 'react-tagsinput'
import HintBox from '@/pages/RFQPortal/components/HintBox'
import ValidationPopup from '@/pages/RFQPortal/RfqForm/ValidationPopup'
import { useUserState } from '@/store/user'
import { HttpV2 } from '@shared/apiv2/api'
import { submitToCallRail } from '@/pages/RFQPortal/utils/userActivityTracking'
import { isProdEnv, removeEmptyStrings, getLoadNumberLabel } from '@/pages/RFQPortal/helpers/rfqHelper'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'
import dayjs from 'dayjs'
import { usePardotCampaign } from '@/pages/RFQPortal/utils/userActivityTracking'

import './rfqForm.scss'
import '../parts/Sidebar/Sidebar.scss'

import { useMicrosite } from '@/pages/RFQPortal/hooks/useMicrosite'
import { toast } from 'react-toastify'
import { callAE } from '@/pages/RFQPortal/call.ae'
import { getRelativePath } from '@/pages/RFQPortal/RfqForm/rfqNavigate'
import useNavHelper from '@/hooks/useNavHelper'
import useReferralLink from '@/pages/RFQPortal/hooks/useReferralLink'
import {
    freightEnvironment,
    certifications,
    unitTypes,
    servicesNeededInitial,
    hazmatClasses,
} from '@/pages/RFQPortal/RfqForm/options'
import MultiSelectDropdown from '@/components/MultiSelectDropdown'
import { useRfqData } from '@/pages/RFQPortal/contexts/rfqDataContext'

import cn from 'classnames'

const CLASS_NAME = 'rfq-form'
const redirectClass = 'sidebar__form__redirect'
const parentClass = 'rfq-header'

interface RfqFormProps {}

interface ClientData {
    company: string | null
    firstName: string | null
    lastName: string | null
    email: string | null
    phone: string | null
    phoneExtention: string | null
    teamMembers: string[]
    account?: string | null
}

export interface FormValues {
    serviceLocation: {
        formattedAddress: string
        [key: string]: any
    }
    serviceDate: { start?: string; end?: string }
    commodity: string
    servicesNeeded: string[]
    freightEnvironment: string
    certifications: string[]
    unitType: string
    numberOfUnits: string
    loadNumber: string
    company: string
    firstName: string
    lastName: string
    email: string
    phone: string
    phoneExtention: string
    teamMembers: string[]
    notes: string
    files: File[]
    lead: {
        createDate: string
        type: string
        referral: string
        formHandlerAE: string
    }
    account?: string
    lowTempControlled?: number
    highTempControlled?: number
    hazmatClasses?: string[]
    truckNumber?: string
    driverName?: string
    driverPhone?: string
}

// eslint-disable-next-line complexity
const RfqForm: FC<RfqFormProps> = () => {
    const { setRfqContextData } = useRfqData()
    const {
        pages: { isGeneralRfqPage, isOnDemandRfqPage },
    } = useNavHelper()
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const { data: micrositeData } = useMicrosite()
    usePardotCampaign(micrositeData?.aeCampaignId)

    const { state } = useUserState()
    const { isLoggedIn } = state

    const location = useLocation()
    const navigate = useNavigate()

    const { sfAccountId, firstName, lastName, email, phoneExtention, phone, companyName } = state.userInfo || {}
    const [submitError, setSubmitError] = useState<string>('')

    const clientPhoneRef = useRef<InputPhoneHandle>(null)
    const [clientPhoneValidation, setClientPhoneValidation] = useState<ResultPhoneProps | undefined>()

    const driverPhoneRef = useRef<InputPhoneHandle>(null)
    const [driverPhoneValidation, setDriverPhoneValidation] = useState<ResultPhoneProps | undefined>()

    const validationSchema = useDynamicValidationSchema({ clientPhoneValidation, driverPhoneValidation })
    // const [formTouchedWithError, setFormTouchedWithError] = useState<boolean>()
    const [showValidationPopup, setShowValidationPopup] = useState<boolean>(true)
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)
    // const [corporateEmailError, setCorporateEmailError] = useState<string>('')

    const [matchedClient, setMatchedClient] = useState<{ name: string; location: string }>({ name: '', location: '' })
    const [seconds, setSeconds] = useState<number>(5)

    const [isSubmitedState, setIsSubmitedState] = useState<boolean | undefined>(undefined)
    const isSubmittedRef = useRef<boolean | undefined>()

    useEffect(() => {
        isSubmittedRef.current = isSubmitedState
    }, [isSubmitedState])

    const formik = useFormik<FormValues>({
        initialValues: {
            serviceLocation: { formattedAddress: '' },
            serviceDate: { start: undefined, end: undefined },
            commodity: '',
            servicesNeeded: [],
            freightEnvironment: 'Dry',
            certifications: [],
            unitType: '',
            numberOfUnits: '',
            loadNumber: '',
            company: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            phoneExtention: '',
            teamMembers: [],
            notes: '',
            files: [],
            lead: {
                createDate: new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }) + ' Central',
                type: 'Demand',
                referral: '',
                formHandlerAE: '',
            },
            account: '',
        },
        validateOnMount: false,
        validateOnChange: validateOnChange,
        // eslint-disable-next-line complexity
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitError('')
            setSubmitting(true)
            let isSubmited

            const cleanedServiceLocation = removeEmptyStrings({ ...values.serviceLocation })

            const files = values.files
            const filesMapping: number[] = []
            if (files && files.length > 0) {
                files.forEach((file, index) => {
                    filesMapping.push(0)
                })
            }

            // unitTypes.map((unitType) => unitType.value)

            const payload = {
                project: {
                    single: true,
                    data: [
                        {
                            servicesNeeded: values.servicesNeeded,
                            freightEnvironment: values.freightEnvironment || null,
                            lowTempControlled: values.lowTempControlled || null,
                            highTempControlled: values.highTempControlled || null,
                            certifications: values.certifications,
                            hazmatClasses: values.hazmatClasses || null,
                            serviceLocation: cleanedServiceLocation,
                            serviceDate: {
                                start: values.serviceDate.start || null,
                                end: values.serviceDate.end || null,
                            },
                            commodity: values.commodity || null,
                            loadNumber: values.loadNumber || null,
                            unitType: unitTypes.find((unitType) => unitType.label === values.unitType)?.value || null,
                            numberOfUnits: values.numberOfUnits || null,
                            notes: values.notes || null,
                            truckNumber: values.truckNumber,
                            driverName: values.driverName,
                            driverPhone: values.driverPhone,
                        },
                    ],
                },
                clientData: {
                    company: values.company || null,
                    firstName: values.firstName || null,
                    lastName: values.lastName || null,
                    email: values.email || null,
                    phone: values.phone || null,
                    phoneExtention: values.phoneExtention || null,
                    teamMembers: values.teamMembers,
                } as ClientData,
                lead: {
                    createDate: values.lead.createDate || null,
                    type: values.lead.type || null,
                    referral: values.lead.referral || null,
                    formHandlerAE: values.lead.formHandlerAE || null,
                },
                filesMapping: filesMapping,
            }

            if (values.account) {
                payload.clientData.account = values.account
            }

            const jsonData = JSON.stringify(payload)
            const body = new FormData()

            if (files && files.length > 0) {
                files.forEach((file) => {
                    body.append('files', file)
                })
            }
            body.append('data', jsonData)

            const rfqSubmitUrl = isLoggedIn ? '/rfq' : '/rfq/public'

            try {
                await HttpV2.post(rfqSubmitUrl, body, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                setIsSubmitedState(true)
                isSubmited = true //Needed this because state updates are not immediate
            } catch (error: any) {
                isSubmited = false
                setIsSubmitedState(false)
                setSubmitError(error.response?.data?.message)
            } finally {
                localStorage.removeItem('referral_link')
                setSubmitting(false)

                if (isProdEnv) {
                    if (values.lead.formHandlerAE) {
                        callAE(values.lead.formHandlerAE, {
                            company_name: values.company || '',
                            first_name: values.firstName || '',
                            last_name: values.lastName || '',
                            email: values.email || '',
                            phone: values.phone || '',
                        })
                    }
                    submitToCallRail(payload, companyName!)
                }

                const eventSlug = micrositeData?.site
                    ? micrositeData.site
                    : location.pathname.includes('on-demand-warehousing')
                    ? 'on_demand_warehousing'
                    : 'general'

                sendCustomEvent(`form_submission_${eventSlug}`, {
                    is_submit_successful: isSubmited,
                    first_name: values.firstName || '',
                    last_name: values.lastName || '',
                    service_location: values.serviceLocation?.formattedAddress || '',
                    email: values.email || '',
                    phone: values.phone || '',
                    error_message: submitError || '',
                    referral: values.lead.referral || '',
                    createDate: values.lead.createDate || '',
                    touched_fields: Object.keys(formik.touched).join(', '),
                })

                if (isSubmited) {
                    const thankYouLink = getRelativePath('thank-you')
                    navigate(thankYouLink.path, { state: { fromRfq: true } })
                }

                customResetForm()
            }
        },
        validationSchema,
    })
    useReferralLink(formik)

    const formikRef = useRef(formik)
    formikRef.current = formik

    const [servicesNeeded, setServicesNeeded] = useState(servicesNeededInitial)

    const [disabledServices, setDisabledServices] = useState<string[]>([])
    const [servicesTooltips, setServicesTooltips] = useState<any>()

    const teamMembersRef = useRef<TagsInput | null>(null)

    const [redirectedFormData, setRedirectedFormData] = useState<any>(undefined)

    useEffect(() => {
        const redirectedForm = localStorage.getItem('rfq_form')

        if (redirectedForm) {
            try {
                setRedirectedFormData(JSON.parse(redirectedForm))
            } catch (error) {
                console.error('Error parsing redirectedForm', error)
            }
            localStorage.removeItem('rfq_form')
        }

        const notPublicAccount = !isOnDemandRfqPage && !isGeneralRfqPage
        if (notPublicAccount) {
            formik.setFieldValue('company', companyName || micrositeData?.accountName || '')
            if (sfAccountId || micrositeData?.accountId) {
                formik.setFieldValue('account', micrositeData?.accountId ?? sfAccountId)
            }
        }
        formik.setFieldValue(
            'lead.formHandlerAE',
            micrositeData?.aeFormHandler || 'http://go.olimpwarehousing.com/l/1036033/2023-08-28/368l7j',
        )
        formik.setFieldValue('email', email || redirectedFormData?.email || '')

        formik.setFieldValue('firstName', redirectedFormData?.firstName || firstName || '')
        formik.setFieldValue('lastName', redirectedFormData?.lastName || lastName || '')
        formik.setFieldValue('phone', redirectedFormData?.phone || phone || '')
        formik.setFieldValue('phoneExtention', redirectedFormData?.phoneExtention || phoneExtention || '')

        formik.setFieldValue('serviceDate', redirectedFormData?.serviceDate || { start: undefined, end: undefined })
        formik.setFieldValue('commodity', redirectedFormData?.commodity || '')
        formik.setFieldValue('servicesNeeded', redirectedFormData?.servicesNeeded || [])
        formik.setFieldValue('freightEnvironment', redirectedFormData?.freightEnvironment || 'Dry')
        formik.setFieldValue('lowTempControlled', redirectedFormData?.lowTempControlled || '')
        formik.setFieldValue('highTempControlled', redirectedFormData?.highTempControlled || '')
        formik.setFieldValue('hazmatClasses', redirectedFormData?.hazmatClasses || [])
        formik.setFieldValue('certifications', redirectedFormData?.certifications || [])
        formik.setFieldValue('unitType', redirectedFormData?.unitType || '')
        formik.setFieldValue('numberOfUnits', redirectedFormData?.numberOfUnits || '')
        formik.setFieldValue('loadNumber', redirectedFormData?.loadNumber || '')
        formik.setFieldValue('loadNumber', redirectedFormData?.loadNumber || '')
        formik.setFieldValue('serviceLocation', redirectedFormData?.serviceLocation || { formattedAddress: '' })
        formik.setFieldValue('teamMembers', redirectedFormData?.teamMembers || [])
        formik.setFieldValue('notes', redirectedFormData?.notes || '')
        formik.setFieldValue('files', redirectedFormData?.files || '')
        formik.setFieldValue('files', redirectedFormData?.truckNumber || '')
        formik.setFieldValue('files', redirectedFormData?.driverName || '')
        formik.setFieldValue('files', redirectedFormData?.driverPhone || '')

        if (redirectedFormData) {
            toast('Your data was successfully transferred to your custom site.', { type: 'success' })
        }

        if (redirectedFormData?.filesExist) {
            setTimeout(() => {
                toast('Please upload your attachments again!', { type: 'warning' })
            }, 3000)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.userInfo, micrositeData])

    const skipBeforeUnloadRef = useRef<boolean>(false)

    useEffect(() => {
        if (micrositeData?.site === 'arrive') {
            setServicesNeeded([
                'Cross-Docking',
                'Rework / Restack',
                'Storage',
                'Transloading',
                'Disposal',
                'Yard Storage / Trailer Parking',
            ])
        }
    }, [micrositeData?.site])

    useEffect(() => {
        const disableAdditionalServices = () => {
            const isMainServiceSelected = formik.values.servicesNeeded.some(
                (service) => service !== 'Final Mile / Local Delivery' && service !== 'Drayage',
            )

            const disabledItems = isMainServiceSelected ? [] : ['Final Mile / Local Delivery', 'Drayage']
            setDisabledServices(disabledItems)

            const checkIfAnyAdditionalIsChecked = formik.values.servicesNeeded.some(
                (service) => service === 'Final Mile / Local Delivery' || service === 'Drayage',
            )

            if (!isMainServiceSelected && checkIfAnyAdditionalIsChecked) {
                const filteredOutAdditional = formik.values.servicesNeeded.filter(
                    (item) => item !== 'Final Mile / Local Delivery' && item !== 'Drayage',
                )

                formik.setFieldValue('servicesNeeded', filteredOutAdditional)
            }
        }

        disableAdditionalServices()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.servicesNeeded])

    useEffect(() => {
        const handleSetServicesTooltips = () => {
            const servicesHints: any = {
                'Cross-Docking': 'Includes handling in/out within 24 hours (without storage)',
                Storage: 'Includes handling in/out for more than 24 hours',
            }

            if (disabledServices.includes('Final Mile / Local Delivery') || disabledServices.includes('Drayage')) {
                servicesHints['Final Mile / Local Delivery'] =
                    // eslint-disable-next-line quotes
                    "Select another service to enable 'Final Mile / Local Delivery' option"
                servicesHints.Drayage =
                    // eslint-disable-next-line quotes
                    "Select another service to enable 'Drayage' option"
            }

            setServicesTooltips(servicesHints)
        }
        handleSetServicesTooltips()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.servicesNeeded, disabledServices])

    useEffect(() => {
        const rfqPortalElement = document.querySelector('.rfq-portal')

        if (rfqPortalElement) {
            rfqPortalElement.classList.remove('rfq-portal') //Need to remove that class
        }
    }, [])

    // useEffect(() => {
    //     const errorFields = Object.keys(formik.errors)
    //     const touchedFields = Object.keys(formik.touched)
    //     const anyTouchedWithError = errorFields.find((errorField) => touchedFields.includes(errorField))

    //     if (!formik.isValid && anyTouchedWithError) {
    //         setFormTouchedWithError(true)
    //     } else {
    //         setFormTouchedWithError(false)
    //     }
    // }, [formik.isValid, formik.touched, formik.errors])

    const handleCorpEmail = async (clientEmail: string) => {
        skipBeforeUnloadRef.current = true

        if (!formikRef.current.errors.email) {
            return
        }

        if (isGeneralRfqPage || isOnDemandRfqPage) {
            const matchResult = await matchEmailWithCustomSite(clientEmail)
            setMatchedClient(matchResult)

            if (matchResult?.name) {
                setSeconds(5)
            }
        }
    }

    useEffect(() => {
        if (matchedClient.location) {
            const intervalId: number = window.setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds <= 1) {
                        window.clearInterval(intervalId)
                        const { files, ...valuesWithoutFiles } = formik.values as any
                        valuesWithoutFiles.filesExist = files?.length > 0

                        localStorage.setItem('rfq_form', JSON.stringify(valuesWithoutFiles))

                        window.location.href = matchedClient.location
                        return 0
                    }
                    return prevSeconds - 1
                })
            }, 1000)

            return () => window.clearInterval(intervalId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchedClient.location, setSeconds, formik.values])

    useEffect(() => {
        let referenceURL
        const referenceLinkForSSO = localStorage.getItem('rfqLinkForSSO')

        if (referenceLinkForSSO) {
            referenceURL = new URL(referenceLinkForSSO)
            localStorage.removeItem('rfqLinkForSSO')
        } else {
            referenceURL = new URL(window.location.href)
        }

        if (isLoggedIn) {
            if (!referenceURL.searchParams.has('loggedIn')) {
                referenceURL.searchParams.append('loggedIn', 'true')
            }
        } else {
            referenceURL.searchParams.delete('loggedIn')
        }

        navigate(referenceURL.search)
    }, [isLoggedIn, navigate])

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (skipBeforeUnloadRef.current) {
                return
            }

            const { touched, values } = formikRef.current

            if (!isSubmittedRef.current && Object.keys(touched).length > 0) {
                sendCustomEvent('rfq_unsubmitted_form', {
                    touched_fields: Object.keys(touched).join(', '),
                    company: values.company || '',
                    first_name: values.firstName || '',
                    last_name: values.lastName || '',
                    email: values.email || '',
                    phone: values.phone || '',
                })
            }

            // Standard way to show a confirmation dialog
            event.preventDefault()
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)

            // Handle component unmounting
            const { touched, values } = formikRef.current

            if (!isSubmittedRef.current && Object.keys(touched).length > 0) {
                sendCustomEvent('rfq_unsubmitted_form', {
                    touched_fields: Object.keys(touched).join(', '),
                    company: values.company || '',
                    first_name: values.firstName || '',
                    last_name: values.lastName || '',
                    email: values.email || '',
                    phone: values.phone || '',
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const serviceDateErrorMessage =
        (validateOnChange && (formik.errors.serviceDate?.start || formik.errors.serviceDate?.end)) || ''

    const generateErrorMessage = (formikError: string | undefined): string => {
        if (formikError?.includes('use your corporate email') || formikError?.includes('Use your corporate RFQ page')) {
            return formikError
        }

        return (validateOnChange && formikError) || ''
    }

    useEffect(() => {
        if (showValidationPopup) {
            const fieldsWithError = Object.keys(formik.errors).filter((field) => field)
            sendCustomEvent('validation_popup_opened', {
                fields_with_error: fieldsWithError,
            })
        }
    }, [formik.errors, sendCustomEvent, showValidationPopup])

    useEffect(() => {
        setRfqContextData((prev) => ({
            ...prev,
            resetForm: () => {
                customResetForm()
            },
        }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const customResetForm = () => {
        formik.resetForm()
        clientPhoneRef.current?.reset()
        driverPhoneRef.current?.reset()
    }

    const isWernerMS = micrositeData?.site === 'werner'

    return (
        <form className={`${CLASS_NAME}`} onSubmit={formik.handleSubmit}>
            <div className={`${CLASS_NAME}__main`}>
                <div className={`${CLASS_NAME}__services-title`}>
                    <h2>Request For Quote</h2>
                    <div
                        className={cn(`${parentClass}__partner-code-wrapper`, {
                            active: micrositeData?.partnerCodeName && micrositeData?.partnerCode,
                        })}
                    >
                        <p className={`${parentClass}__partner-code-title`}>{micrositeData?.partnerCodeName}:</p>
                        <p className={`${parentClass}__partner-code`}>{micrositeData?.partnerCode}</p>
                    </div>
                </div>
                <section className={`${CLASS_NAME}__fieldset`}>
                    <GoogleAutocompleteInput
                        label="Service Location*"
                        name="serviceLocation"
                        shouldResetOnBlur
                        value={formik.values.serviceLocation.formattedAddress}
                        initialValue={formik.values.serviceLocation.formattedAddress}
                        changePlaceFull={(path, value) => {
                            formik.setFieldValue('serviceLocation', value)
                        }}
                        onBlur={() => {
                            setTimeout(() => {
                                formik.setFieldTouched('serviceLocation', true, true)

                                const serviceLocation = formikRef.current.values.serviceLocation
                                const serviceLocationError = formikRef.current.errors.serviceLocation?.formattedAddress

                                sendCustomEvent('rfq_service_location', {
                                    full_address: serviceLocation?.formattedAddress,
                                    country: serviceLocation?.country,
                                    zip: serviceLocation?.zip,
                                    street: serviceLocation?.street,
                                    building: serviceLocation?.building,
                                    city: serviceLocation?.city,
                                    state: serviceLocation?.state,
                                    lat: serviceLocation?.lat,
                                    lng: serviceLocation?.lng,
                                    error_message: generateErrorMessage(serviceLocationError) || '',
                                })
                            }, 300)
                        }}
                        errorMessage={generateErrorMessage(formik.errors.serviceLocation?.formattedAddress)}
                        validate={() => formik.validateField('serviceLocation')}
                    />
                    <div className={`${CLASS_NAME}__fieldset_flex`}>
                        <RfqDatepicker
                            label="Service Date(s)*"
                            placeholder=""
                            start={formik.values.serviceDate.start}
                            end={formik.values.serviceDate.end}
                            handleChange={({ start, end }) => {
                                const startDate = start ? dayjs(start).format('YYYY-MM-DD') : undefined
                                const endDate = end ? dayjs(end).format('YYYY-MM-DD') : undefined

                                formik.setFieldValue('serviceDate', {
                                    start: startDate,
                                    end: endDate,
                                })

                                sendCustomEvent('rfq_service_date', {
                                    start_date: startDate || '',
                                    end_date: endDate || '',
                                    error_message: serviceDateErrorMessage,
                                })

                                setTimeout(() => {
                                    formik.setFieldTouched('serviceDate.start', true, true)
                                    formik.setFieldTouched('serviceDate.end', true, true)
                                }, 50)
                            }}
                            errorMessage={serviceDateErrorMessage}
                            wrapperClassName={`${CLASS_NAME}__serviceDate-wrapper`}
                        />
                        <Input
                            label="Commodity"
                            {...formik.getFieldProps('commodity')}
                            onBlur={(e) => {
                                formik.getFieldProps('commodity').onBlur(e)

                                sendCustomEvent('rfq_commodity', {
                                    value: formik.values.commodity,
                                    error_message: generateErrorMessage(formik.errors.commodity),
                                })
                            }}
                            errorMessage={generateErrorMessage(formik.errors.commodity)}
                        />
                    </div>
                </section>
                <section className={`${CLASS_NAME}__servicesNeeded-wrapper`}>
                    <p className={`${CLASS_NAME}__fieldset-label`}>
                        Services Needed* <span>(Select all applicable)</span>
                    </p>
                    <Checkbox
                        isFlex={window.innerWidth > 1273 ? true : false}
                        fieldName="servicesNeeded"
                        avaliableOptions={servicesNeeded}
                        disabledOptions={disabledServices}
                        tooltipMessages={servicesTooltips}
                        stateData={formik.values.servicesNeeded}
                        handleChange={(isChecked, inputValue) => {
                            const newValues = isChecked
                                ? [...formik.values.servicesNeeded, inputValue]
                                : formik.values.servicesNeeded.filter((v) => v !== inputValue)

                            if (newValues && newValues.length > 0) {
                                const analyticsData: any = {
                                    checked_options: newValues.join(', '),
                                    error_message: generateErrorMessage(formik.errors.servicesNeeded as string),
                                }

                                newValues.forEach((item) => {
                                    analyticsData[item] = 'checked'
                                })

                                sendCustomEvent('rfq_services_needed', analyticsData)
                            }

                            setTimeout(() => {
                                formik.setFieldValue('servicesNeeded', newValues)
                                formik.setFieldTouched('servicesNeeded', true, true)
                            }, 50)
                        }}
                        errorMessage={generateErrorMessage(formik.errors.servicesNeeded as string)}
                    />
                </section>
                <section className={`${CLASS_NAME}__fieldset_flex ${CLASS_NAME}__fieldset-freight-cert`}>
                    <div className={`${CLASS_NAME}__freightEnvWrapper`}>
                        <p className={`${CLASS_NAME}__fieldset-label`}>Freight Environment*</p>
                        <RadioInput
                            fieldName="freightEnvironment"
                            avaliableOptions={freightEnvironment.map((env) => env.value)}
                            labels={freightEnvironment.map((env) => env.label)}
                            value={formik.values.freightEnvironment}
                            handleChange={(value) => {
                                formik.setFieldValue('freightEnvironment', value)

                                sendCustomEvent('rfq_freight_environment', {
                                    value: value,
                                    error_message: generateErrorMessage(formik.errors.freightEnvironment),
                                })

                                setTimeout(() => {
                                    formik.setFieldTouched('freightEnvironment', true, true)

                                    if (value !== 'Specific') {
                                        formik.setFieldValue('lowTempControlled', '')
                                        formik.setFieldValue('highTempControlled', '')
                                    }
                                }, 100)
                            }}
                            errorMessage={generateErrorMessage(formik.errors.freightEnvironment)}
                        />
                        <div className={`${CLASS_NAME}__fieldset_flex mt-2`}>
                            <Input
                                label="Low (F°)*"
                                type="number"
                                {...formik.getFieldProps('lowTempControlled')}
                                onBlur={(e) => {
                                    formik.getFieldProps('lowTempControlled').onBlur(e)

                                    sendCustomEvent('rfq_low_temp_controlled', {
                                        value: formik.values.lowTempControlled,
                                        error_message: generateErrorMessage(formik.errors.lowTempControlled),
                                    })
                                }}
                                placeholder="Temperature"
                                disabled={formik.values?.freightEnvironment !== 'Specific'}
                                errorMessage={generateErrorMessage(formik.errors.lowTempControlled)}
                            />
                            <Input
                                label="High (F°)*"
                                type="number"
                                {...formik.getFieldProps('highTempControlled')}
                                onBlur={(e) => {
                                    formik.getFieldProps('highTempControlled').onBlur(e)

                                    sendCustomEvent('rfq_high_temp_controlled', {
                                        value: formik.values.highTempControlled,
                                        error_message: generateErrorMessage(formik.errors.highTempControlled),
                                    })
                                }}
                                placeholder="Temperature"
                                disabled={formik.values?.freightEnvironment !== 'Specific'}
                                errorMessage={generateErrorMessage(formik.errors.highTempControlled)}
                            />
                        </div>
                    </div>
                    <div className={`${CLASS_NAME}__certificationsWrapper`}>
                        <p className={`${CLASS_NAME}__fieldset-label`}>Certifications</p>
                        <Checkbox
                            fieldName="certifications"
                            avaliableOptions={certifications}
                            stateData={formik.values.certifications}
                            handleChange={(isChecked, inputValue) => {
                                const newValues = isChecked
                                    ? [...formik.values.certifications, inputValue]
                                    : formik.values.certifications.filter((v) => v !== inputValue)

                                setTimeout(() => {
                                    formik.setFieldValue('certifications', newValues)
                                    formik.setFieldTouched('certifications', true, true)
                                }, 50)

                                if (newValues && newValues.length > 0) {
                                    const analyticsData: any = {
                                        checked_options: newValues.join(', '),
                                        error_message: generateErrorMessage(formik.errors.certifications as string),
                                    }

                                    newValues.forEach((item) => {
                                        analyticsData[item] = 'checked'
                                    })

                                    sendCustomEvent('rfq_certifications', analyticsData)
                                }
                            }}
                            errorMessage={generateErrorMessage(formik.errors.certifications as string)}
                        />
                        <MultiSelectDropdown
                            label="Hazmat Classes*"
                            options={hazmatClasses}
                            selectedOptions={formik.values.hazmatClasses}
                            setSelectedOptions={(value) => {
                                formik.setFieldValue('hazmatClasses', value)
                                setTimeout(() => {
                                    formik.setFieldTouched('hazmatClasses', true, true)
                                    formik.validateField('hazmatClasses')
                                }, 0)
                            }}
                            isDropdownDisabled={!formik.values.certifications.includes('Hazmat')}
                            errorMessage={generateErrorMessage(formik.errors.hazmatClasses as string)}
                        />
                    </div>
                </section>
                <section className={`${CLASS_NAME}__fieldset_flex`}>
                    <Select
                        label="Unit Type*"
                        name="unitType"
                        value={formik.values.unitType}
                        onChange={(e: any) => {
                            const value = e.target.value

                            formik.setFieldValue('unitType', value)
                            setTimeout(() => {
                                formik.setFieldTouched('unitType', true, true)
                            }, 50)

                            sendCustomEvent('rfq_unit_type', {
                                value: value,
                                error_message: generateErrorMessage(formik.errors.unitType),
                            })
                        }}
                        options={unitTypes.map((unitType) => unitType.label)}
                        isInitEmpty
                        errorMessage={generateErrorMessage(formik.errors.unitType)}
                    />
                    <Input
                        label={
                            formik.values.unitType
                                ? `${unitTypes.find((type) => type.label === formik.values.unitType)?.numberLabel}`
                                : '# of Units*'
                        }
                        type="number"
                        min="1"
                        {...formik.getFieldProps('numberOfUnits')}
                        onBlur={(e) => {
                            formik.getFieldProps('numberOfUnits').onBlur(e)

                            sendCustomEvent('rfq_unit_number', {
                                value: formik.values.numberOfUnits,
                                error_message: generateErrorMessage(formik.errors.numberOfUnits),
                            })
                        }}
                        errorMessage={generateErrorMessage(formik.errors.numberOfUnits)}
                    />
                </section>
                <div className={cn({ 'rfq-form__fieldset_flex': isWernerMS })}>
                    <Input
                        label={getLoadNumberLabel(micrositeData?.site)}
                        {...formik.getFieldProps('loadNumber')}
                        onBlur={(e) => {
                            formik.getFieldProps('loadNumber').onBlur(e)

                            sendCustomEvent('rfq_load_number', {
                                value: formik.values.loadNumber,
                                error_message: generateErrorMessage(formik.errors.loadNumber),
                            })
                        }}
                        errorMessage={generateErrorMessage(formik.errors.loadNumber)}
                    />
                    {isWernerMS && (
                        <Input
                            label="Truck #"
                            {...formik.getFieldProps('truckNumber')}
                            onBlur={(e) => {
                                formik.getFieldProps('truckNumber').onBlur(e)

                                sendCustomEvent('rfq_truck_number', {
                                    value: formik.values.truckNumber,
                                    error_message: generateErrorMessage(formik.errors.truckNumber),
                                })
                            }}
                            errorMessage={generateErrorMessage(formik.errors.truckNumber)}
                        />
                    )}
                </div>
            </div>
            <div className={`${CLASS_NAME}__sidebar`}>
                <div className={`${CLASS_NAME}__sidebar-card`}>
                    <h2 className={`${CLASS_NAME}__section-title`}>Your Information</h2>
                    <Input
                        label="Company Name*"
                        disabled={
                            (!!state.userInfo || !!micrositeData?.accountId) && !isOnDemandRfqPage && !isGeneralRfqPage
                        }
                        {...formik.getFieldProps('company')}
                        onBlur={(e) => {
                            formik.getFieldProps('company').onBlur(e)

                            sendCustomEvent('rfq_company_name', {
                                value: formik.values.company,
                                error_message: generateErrorMessage(formik.errors.company),
                            })
                        }}
                        errorMessage={generateErrorMessage(formik.errors.company)}
                    />
                    <div className={`${CLASS_NAME}__fieldset_flex`}>
                        <Input
                            label="First Name*"
                            {...formik.getFieldProps('firstName')}
                            onBlur={(e) => {
                                formik.getFieldProps('firstName').onBlur(e)

                                sendCustomEvent('rfq_first_name', {
                                    value: formik.values.firstName,
                                    error_message: generateErrorMessage(formik.errors.firstName),
                                })
                            }}
                            errorMessage={generateErrorMessage(formik.errors.firstName)}
                        />
                        <Input
                            label="Last Name*"
                            {...formik.getFieldProps('lastName')}
                            onBlur={(e) => {
                                formik.getFieldProps('lastName').onBlur(e)

                                sendCustomEvent('rfq_last_name', {
                                    value: formik.values.lastName,
                                    error_message: generateErrorMessage(formik.errors.lastName),
                                })
                            }}
                            errorMessage={generateErrorMessage(formik.errors.lastName)}
                        />
                    </div>
                    <div
                        className={`${CLASS_NAME}__fieldset_flex ${CLASS_NAME}__email-full-width sidebar__form__email-wrapper`}
                    >
                        <div
                            className={`${redirectClass}-wrapper ${
                                matchedClient.name ? `${redirectClass}-wrapper_active` : ''
                            }`}
                        >
                            <div className={`${redirectClass}-modal`}></div>
                            <div className={`${redirectClass}`}>
                                <h4 className={`${redirectClass}-title`}>Your Email Matches a Microsite!</h4>
                                <p className={`${redirectClass}-text`}>
                                    Your company has a custom microsite for submitting a Request for Quote.
                                </p>
                                <h3 className={`${redirectClass}-name`}>{matchedClient.name}</h3>
                                <p className={`${redirectClass}-warning`}>
                                    You will be redirected in {seconds} second(s)
                                </p>
                            </div>
                        </div>
                        <Input
                            label="Email*"
                            disabled={!!state.userInfo}
                            {...formik.getFieldProps('email')}
                            onBlur={async (e: any) => {
                                formik.getFieldProps('email').onBlur(e)
                                sendCustomEvent('rfq_email', {
                                    value: formik.values.email,
                                    error_message: generateErrorMessage(formik.errors.email),
                                })
                                setTimeout(async () => {
                                    await handleCorpEmail(e.target.value)
                                }, 50)
                            }}
                            // errorMessage={generateErrorMessage(formik.errors.email)}
                            errorMessage={generateErrorMessage(formik.errors.email)}
                            wrapperClassName={`${CLASS_NAME}__email`}
                        />
                        <InputPhone
                            ref={clientPhoneRef}
                            label="Phone#*"
                            initialValue={formik.values.phone}
                            handleChange={(phoneData: any) => {
                                formik.setFieldValue('phone', phoneData.value)
                                setClientPhoneValidation(phoneData)
                            }}
                            onBlur={() => {
                                formik.setFieldTouched('phone', true, true)
                                sendCustomEvent('rfq_phone', {
                                    ...clientPhoneValidation,
                                    errorMessage: formik.errors.phone,
                                })
                            }}
                            errorMessage={validateOnChange ? formikRef.current.errors?.phone : ''}
                            wrapperClassName={`${CLASS_NAME}__phone`}
                        />
                        <Input
                            label="Ext #"
                            wrapperClassName={`${CLASS_NAME}__client-ext-wrapper`}
                            {...formik.getFieldProps('phoneExtention')}
                            onBlur={(e) => {
                                formik.getFieldProps('phoneExtention').onBlur(e)

                                sendCustomEvent('rfq_phone_extention', {
                                    value: formik.values.phoneExtention,
                                    error_message: generateErrorMessage(formik.errors.phoneExtention),
                                })
                            }}
                            errorMessage={generateErrorMessage(formik.errors.phoneExtention)}
                        />
                    </div>
                    <div className={`input-wrapper ${CLASS_NAME}__team-members`}>
                        <div className={`select-label ${CLASS_NAME}__team-members-label`}>
                            <p>Add Team Members</p>
                            <HintBox content="Enter emails of your Team Members that you would like to receive updates on this request. Bulk emails will be accepted.e.g. one@example.com, two@example.com" />
                        </div>
                        <TagsInput
                            value={formik.values.teamMembers}
                            ref={teamMembersRef}
                            onChange={(emails: string[]) => {
                                const trimmedValues = emails.map((item) => item.trim().replace(/^<+|>+$/g, ''))
                                formik.setFieldValue('teamMembers', trimmedValues)

                                if (emails && emails.length > 0) {
                                    sendCustomEvent('rfq_team_members', {
                                        value: emails.join(', '),
                                    })
                                }
                            }}
                            inputProps={{
                                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (e.key === ' ' || e.key === ',' || e.key === 'Enter') {
                                        e.preventDefault()

                                        //Make accept of email on '', ',' and 'Enter'
                                        if (teamMembersRef.current) {
                                            teamMembersRef.current.accept()
                                        }
                                    }
                                },
                                className: 'react-tagsinput-input', //Default
                                placeholder: 'Add additional emails for visibility',
                            }}
                            tagProps={{
                                className: 'react-tagsinput-tag', //Default
                                classNameRemove: 'react-tagsinput-remove', //Default
                            }}
                            validationRegex={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
                            onlyUnique
                            addOnBlur
                            addOnPaste
                            pasteSplit={(data: string) => data.split(/[,\s]+/)} // Splits the pasted content using a regular expression that considers both commas and whitespace as delimiters.
                            renderTag={({
                                tag,
                                key,
                                disabled,
                                onRemove,
                                classNameRemove,
                                getTagDisplayValue,
                                ...other
                            }) => {
                                if (tag && getTagDisplayValue(tag).trim() !== '') {
                                    return (
                                        <div key={key} {...other}>
                                            <span className="tag-text">{getTagDisplayValue(tag)}</span>
                                            {!disabled && (
                                                <button
                                                    type="button"
                                                    className={classNameRemove}
                                                    onClick={(e) => onRemove(key)}
                                                >
                                                    X
                                                </button>
                                            )}
                                        </div>
                                    )
                                }
                                return null
                            }}
                        />
                    </div>
                </div>
                <div className={`${CLASS_NAME}__sidebar-card ${CLASS_NAME}__sidebar-card_flexible-block`}>
                    {isWernerMS && (
                        <div className={`${CLASS_NAME}__fieldset_flex`}>
                            <Input
                                label="Driver Name"
                                {...formik.getFieldProps('driverName')}
                                onBlur={(e) => {
                                    formik.getFieldProps('driverName').onBlur(e)

                                    sendCustomEvent('rfq_driver_name', {
                                        value: formik.values.driverName,
                                        error_message: generateErrorMessage(formik.errors.driverName),
                                    })
                                }}
                                errorMessage={generateErrorMessage(formik.errors.driverName)}
                            />
                            <InputPhone
                                ref={driverPhoneRef}
                                label="Driver Phone #"
                                initialValue={formik.values.driverPhone}
                                handleChange={(phoneData: any) => {
                                    formik.setFieldValue('driverPhone', phoneData.value)
                                    setDriverPhoneValidation(phoneData)
                                }}
                                onBlur={() => {
                                    formik.setFieldTouched('driverPhone', true, true)
                                    sendCustomEvent('rfq_driverPhone', {
                                        ...driverPhoneValidation,
                                        errorMessage: formik.errors.driverPhone,
                                    })
                                }}
                                errorMessage={validateOnChange ? formikRef.current.errors?.driverPhone : ''}
                                wrapperClassName={`${CLASS_NAME}__phone`}
                            />
                        </div>
                    )}
                    <Textarea
                        label="Notes"
                        wrapperClassName={`${CLASS_NAME}__notes`}
                        {...formik.getFieldProps('notes')}
                        onBlur={(e) => {
                            formik.getFieldProps('notes').onBlur(e)

                            sendCustomEvent('rfq_notes', {
                                value: formik.values.notes,
                                error_message: generateErrorMessage(formik.errors.notes),
                            })
                        }}
                        placeholder="Copy and paste Scope of Work, details, etc."
                        errorMessage={generateErrorMessage(formik.errors.notes)}
                    />
                    <div className={`${CLASS_NAME}__files`}>
                        <p className={`${CLASS_NAME}__fieldset-label`}>
                            BOL / Images / Packing Lists / Safety Data Sheet / Etc.
                        </p>
                        <DropZoneArea
                            name="files"
                            changeFormData={(path, files, filesInfo) => {
                                formik.setFieldValue('files', files)

                                const { file_names, file_sizes, file_types, total_files } = filesInfo || []

                                if (file_names) {
                                    sendCustomEvent('rfq_files', {
                                        file_sizes: file_sizes?.join(', '),
                                        file_types: file_types?.join(', '),
                                        total_files: total_files,
                                    })
                                }
                            }}
                            filesCollection={formik.values.files}
                        />
                        {validateOnChange && formik.errors.files && (
                            <span className="error-message">{formik.errors.files}</span>
                        )}
                    </div>
                </div>
                <div className={`${CLASS_NAME}__btns-wrapper`} style={{ position: 'relative' }}>
                    {validateOnChange && !formik.isValid && showValidationPopup && (
                        <ValidationPopup errors={formik.errors} setShowPopup={setShowValidationPopup} />
                    )}
                    <Button
                        type="submit"
                        types={['blue']}
                        className={`${CLASS_NAME}__submit-btn`}
                        loading={formik.isSubmitting && !formik.isValidating}
                        disabled={formik.isSubmitting}
                        onClick={() => {
                            setValidateOnChange(true)
                            setShowValidationPopup(true)
                            sendCustomEvent('rfq_submit_clicked', {
                                action: 'User tried to submit RFQ by clicking on submit button',
                            })
                        }}
                    >
                        Submit RFQ
                    </Button>
                    {submitError && <p className={`${CLASS_NAME}__submit-error`}>{submitError}</p>}
                </div>
            </div>
        </form>
    )
}

export default RfqForm
