import React, { useLayoutEffect, useState } from 'react'
import {
    DrawerClose,
    DrawerContent,
    DrawerFooter,
    DrawerHandle,
    DrawerOverlay,
    DrawerPortal,
    DrawerRoot,
    DrawerTrigger,
} from '@/shared/ui/Drawer'
import { Button } from '@/shared/ui/Button'
import { InFilter } from '@/shared/ui/Icons/InFilter'
import { Separator } from '@/shared/ui/Separator'
import { store, useAppDispatch, useAppSelector } from '@/app/store'
import { FilterParams } from '@/pages/LoadBoard/model/types'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { LoadBoardFilters } from '@/pages/LoadBoard/ui/LoadBoardFilters'
import { LoadboardCategory } from '@/entities/loadboard-orders/model'
import { cn } from '@/shared/utils/cn'

type LoadBoardMobileFiltersProps = {
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
}

export const ControlsFilters = ({ params, setParams }: LoadBoardMobileFiltersProps) => {
    const dispatch = useAppDispatch()
    const isReadyToFetchOrders = useAppSelector((state) => state.loadboard.isReadyToFetchOrders)

    const onParamsChanged = (newParams: Partial<FilterParams>) => {
        dispatch(loadboardActions.setFilters(newParams))
    }

    const onApplyFilters = () => {
        const filters = store.getState().loadboard.filters
        if (filters) {
            const { lat, lng, ...newParams } = filters
            setParams({ ...newParams, pageIndex: 0 })
        }
    }

    const onClear = () => {
        const newFilters = {
            search: null,
            hideQuoted: null,

            services: null,
            freightEnvironment: null,
            certifications: null,
            startDate: null,
            endDate: null,

            /** Params unique for non "new-opportunities" tab */
            warehouse: null,

            /** Params unique for "new-opportunities" tab */
            useWHLocation: null,
            locationWarehouse: null,
            locationSearch: null,
            lat: null,
            lng: null,
            locationDistance: null,
        }

        dispatch(loadboardActions.setFilters(newFilters))
        const { lat, lng, ...newParams } = newFilters
        setParams({ ...newParams })
    }

    /** Hack for initial fetching of filters */
    const [forceMount, setForceMount] = useState<true | undefined>(true)
    useLayoutEffect(() => {
        setForceMount(isReadyToFetchOrders ? undefined : true)
    }, [isReadyToFetchOrders])

    return (
        <DrawerRoot>
            <DrawerTrigger asChild>
                <Button className="p-0 h-11 w-11 shrink-0">
                    <InFilter size={16} />
                </Button>
            </DrawerTrigger>
            <DrawerPortal forceMount={forceMount}>
                <DrawerOverlay />
                <DrawerContent className={cn('pt-4 max-h-[90%]')}>
                    <DrawerHandle />
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            onApplyFilters()
                        }}
                        className="h-full flex flex-col justify-end overflow-hidden"
                    >
                        <div className="mx-auto max-w-[450px] w-full bg-background-secondary overflow-auto h-full">
                            <LoadBoardFilters
                                category={params.category as LoadboardCategory}
                                onParamsChanged={onParamsChanged}
                                onClear={onClear}
                                showSearchInput
                            />
                        </div>
                        <Separator />
                        <div className="shadow-shadow shrink-0">
                            <DrawerFooter className="mx-auto max-w-[450px]">
                                <DrawerClose asChild>
                                    <Button type="submit" className="h-11">
                                        Apply
                                    </Button>
                                </DrawerClose>
                                <DrawerClose asChild>
                                    <Button variant="outlined" onClick={onClear} className="h-11">
                                        Clear
                                    </Button>
                                </DrawerClose>
                            </DrawerFooter>
                        </div>
                    </form>
                </DrawerContent>
            </DrawerPortal>
        </DrawerRoot>
    )
}
