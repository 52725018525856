import React, { useState } from 'react'
import { Button } from '@/shared/ui/Button'
import { DecodedValueMap } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { loadboardOrdersApi, loadboardRtkApi } from '@/entities/loadboard-orders/api'
import { LoadboardCategory } from '@/entities/loadboard-orders/model'
import { useAppDispatch } from '@/app/store'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'
import { GoRead } from 'react-icons/go'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'

type ControlsMarkAsReadProps = {
    params: DecodedValueMap<GetLoadboardParams>
}

export const ControlsMarkAsRead = ({ params }: ControlsMarkAsReadProps) => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const markAllAsRead = async () => {
        if (isLoading) return

        const category = params.category as LoadboardCategory

        try {
            setIsLoading(true)
            await loadboardOrdersApi.markLoadboardCategoryAsRead(category)
            dispatch(loadboardRtkApi.util.invalidateTags([{ type: 'LoadBoard', id: 'Totals' }]))

            dispatch(loadboardActions.setIsReadyForFetching(false))
            setTimeout(() => {
                dispatch(loadboardActions.setIsReadyForFetching(true))
            })
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Button variant="ghost" className="shrink-0 text-primary !text-sm" onClick={markAllAsRead} disabled={isLoading}>
            Mark all as read
            {isLoading ? <ImSpinner8 size={16} className="ml-2 animate-spin" /> : <GoRead size={16} className="ml-2" />}
        </Button>
    )
}
