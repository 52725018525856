import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DecodedValueMap } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { FilterParams } from '@/pages/LoadBoard/model/types'
import { ColumnSort, SortingState } from '@tanstack/react-table'
import { LoadboardCategory, LoadBoardTotals } from '@/entities/loadboard-orders/model'
import { isMatch } from 'date-fns'
import { loadboardRtkApi } from '@/entities/loadboard-orders/api'

export type LoadBoardSlice = {
    isInitialized: boolean
    isReadyToFetchOrders: boolean
    filters: null | FilterParams
    lastFetchedLocationName: string
    sorting: SortingState
    totals: LoadBoardTotals
}

const initialState: LoadBoardSlice = {
    isInitialized: false,
    isReadyToFetchOrders: false,
    filters: null,
    lastFetchedLocationName: '',
    sorting: [
        {
            id: 'startDate',
            desc: true,
        },
    ],
    totals: {
        [LoadboardCategory.New]: 0,
        [LoadboardCategory.Quoted]: 0,
        [LoadboardCategory.Accepted]: 0,
        [LoadboardCategory.Lost]: 0,
        [LoadboardCategory.NotInterested]: 0,
    },
}

export const loadboardSlice = createSlice({
    name: 'loadboard',
    initialState,
    reducers: {
        setInitialFilters: (state, action: PayloadAction<DecodedValueMap<GetLoadboardParams>>) => {
            const params = action.payload

            /** Assign initial FILTERS from incoming URL params */
            const filters: FilterParams = {
                search: params.search?.trim(),
                hideQuoted: params.hideQuoted ?? null,

                services: (params.services as string[]) ?? null,
                freightEnvironment: params.freightEnvironment ?? null,
                certifications: (params.certifications as string[]) ?? null,
                startDate: null,
                endDate: null,

                /** Params unique for non "new-opportunities" tab */
                warehouse: params.warehouse?.trim() ?? null,

                /** Params unique for "new-opportunities" tab */
                useWHLocation: params.useWHLocation ?? null,
                locationWarehouse: params.locationWarehouse?.trim() ?? null,
                locationSearch: params.locationSearch?.trim() ?? null,
                lat: null,
                lng: null,
                locationDistance: params.locationDistance ?? null,
            }

            filters.startDate = params.startDate && isMatch(params.startDate, 'MM/dd/yyyy') ? params.startDate : null
            filters.endDate = params.endDate && isMatch(params.endDate, 'MM/dd/yyyy') ? params.endDate : null

            state.filters = filters

            /** Assign initial SORTING from incoming URL params */
            const ALLOWED_SORTING_BY = ['id', 'startDate', 'endDate', 'totalUnits']
            if (params.category === LoadboardCategory.New || params.category === LoadboardCategory.NotInterested) {
                if (
                    (filters.useWHLocation && !filters.locationWarehouse) ||
                    (!filters.useWHLocation && !filters.locationSearch)
                ) {
                    ALLOWED_SORTING_BY.push('distance')
                }
            }
            if (params.category === LoadboardCategory.Accepted) {
                ALLOWED_SORTING_BY.push('rfqStatus')
            }

            if (ALLOWED_SORTING_BY.includes(params.sortBy)) {
                const sorting: ColumnSort = {
                    id: params.sortBy,
                    desc: params.sortOrder !== 'ASC',
                }
                state.sorting = [sorting]
            }

            /** For "New" and "Not Interested" categories,
             *  if no location provided from URL params, allow to fetch orders,
             *  otherwise it will be allowed later when location params are fetched, processed and ready */
            if (params.category === LoadboardCategory.New || params.category === LoadboardCategory.NotInterested) {
                if (
                    (filters.useWHLocation && !filters.locationWarehouse) ||
                    (!filters.useWHLocation && !filters.locationSearch)
                ) {
                    state.isReadyToFetchOrders = true
                }
            } else {
                if (!filters.warehouse) state.isReadyToFetchOrders = true
            }

            state.isInitialized = true
        },

        setIsReadyForFetching: (state, action: PayloadAction<boolean>) => {
            state.isReadyToFetchOrders = action.payload
        },

        setFilters: (state, action: PayloadAction<Partial<FilterParams>>) => {
            if (state.filters) state.filters = { ...state.filters, ...action.payload }
        },

        setSorting: (state, action: PayloadAction<SortingState>) => {
            state.sorting = action.payload
        },

        setLastFetchedLocationName: (state, action: PayloadAction<string>) => {
            state.lastFetchedLocationName = action.payload
        },

        setTotals: (state, action: PayloadAction<Partial<LoadBoardTotals>>) => {
            state.totals = { ...state.totals, ...action.payload }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(loadboardRtkApi.endpoints.getLoadBoardTotals.matchFulfilled, (state, { payload }) => {
            state.totals = payload
        })
    },
})

export const loadboardActions = loadboardSlice.actions

export const loadboardReducer = loadboardSlice.reducer
