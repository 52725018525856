import React from 'react'
import { Select, SelectOption } from '@/shared/ui/Select'
import {
    DrawerClose,
    DrawerContent,
    DrawerFooter,
    DrawerHandle,
    DrawerOverlay,
    DrawerPortal,
    DrawerRoot,
    DrawerTrigger,
} from '@/shared/ui/Drawer'
import { Button } from '@/shared/ui/Button'
import { MdSort } from '@react-icons/all-files/md/MdSort'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { ColumnSort } from '@tanstack/react-table'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'
import { LoadboardCategory } from '@/entities/loadboard-orders/model'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'

const SORT_BY_DEFAULT_OPTIONS: SelectOption[] = [
    { value: 'id', label: 'Order #' },
    { value: 'startDate', label: 'Start Date' },
    { value: 'endDate', label: 'End Date' },
    { value: 'totalUnits', label: 'Units #' },
]

const getSortByOptions = (category: LoadboardCategory, allowDistanceOption: boolean) => {
    const options = [...SORT_BY_DEFAULT_OPTIONS]

    if (category === LoadboardCategory.New && allowDistanceOption) {
        options.push({ value: 'distance', label: 'Distance' })
    }
    if (category === LoadboardCategory.Accepted) {
        options.push({ value: 'rfqStatus', label: 'Order Status' })
    }

    return options
}

const SORT_ORDER_OPTIONS: SelectOption[] = [
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
]

type LoadBoardMobileSortingProps = {
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
}

export const ControlsSorting = ({ params, setParams }: LoadBoardMobileSortingProps) => {
    return (
        <DrawerRoot>
            <DrawerTrigger asChild>
                <Button className="p-0 h-11 w-11 shrink-0">
                    <MdSort size={20} />
                </Button>
            </DrawerTrigger>
            <DrawerPortal>
                <DrawerOverlay />
                <DrawerContent>
                    <LoadBoardMobileSortingContent params={params} setParams={setParams} />
                </DrawerContent>
            </DrawerPortal>
        </DrawerRoot>
    )
}

export const LoadBoardMobileSortingContent = ({ params, setParams }: LoadBoardMobileSortingProps) => {
    const dispatch = useAppDispatch()
    const sortingState = useAppSelector((state) => state.loadboard.sorting)
    const lat = useAppSelector((state) => state.loadboard.filters?.lat)
    const lng = useAppSelector((state) => state.loadboard.filters?.lng)

    const sortByOptions = getSortByOptions(params.category as LoadboardCategory, !!(lat && lng))

    const sorting: ColumnSort = sortingState[0] ?? null

    const onApplySorting = () => {
        if (sorting?.id) {
            setParams({ sortBy: sorting.id, sortOrder: !sorting.desc ? 'ASC' : 'DESC' })
        }
    }

    const onClearSorting = () => {
        dispatch(loadboardActions.setSorting([]))
        setParams({ sortBy: undefined, sortOrder: undefined })
    }

    return (
        <div className="pt-4">
            <DrawerHandle />
            <div className="mx-auto max-w-[450px] w-full bg-background-secondary">
                <div className="space-y-4 mb-3 px-3">
                    <div className="text-xl font-semibold">Sorting</div>
                    <div className="space-y-6">
                        <Select
                            options={sortByOptions}
                            value={sorting?.id ?? ''}
                            onValueChange={(v) => {
                                const newSorting = {
                                    id: v,
                                    desc: sorting?.desc === undefined ? true : sorting.desc,
                                }
                                dispatch(loadboardActions.setSorting([newSorting]))
                            }}
                            triggerClassName="h-11"
                            label="Sort By"
                        />
                        <Select
                            disabled={!sorting?.id}
                            options={SORT_ORDER_OPTIONS}
                            value={sorting ? (sorting.desc ? 'desc' : 'asc') : ''}
                            onValueChange={(v) => {
                                const newSorting = {
                                    id: sorting.id,
                                    desc: v === 'desc',
                                }
                                dispatch(loadboardActions.setSorting([newSorting]))
                            }}
                            triggerClassName="h-11"
                            label="Order"
                        />
                    </div>
                </div>
                <DrawerFooter>
                    <DrawerClose asChild>
                        <Button onClick={onApplySorting} className="h-11">
                            Apply
                        </Button>
                    </DrawerClose>
                    <DrawerClose asChild>
                        <Button variant="outlined" className="h-11" onClick={onClearSorting}>
                            Clear
                        </Button>
                    </DrawerClose>
                </DrawerFooter>
            </div>
        </div>
    )
}
