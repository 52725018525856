import React, { useEffect, useRef, useCallback, useState } from 'react'
import { Section } from '@/shared/ui/Section'
import { ScrollArea } from '@/shared/ui/ScrollArea'
import { Button } from '@/shared/ui/Button'
import { Textarea } from '@/shared/ui/Textarea'
import { ChatErrorMessage } from '@/pages/Order/ui/OrderChat/ui/ChatErrorMessage'
import { Loader } from '@/shared/ui/Loader'
import { ChatInfoMessage } from '@/pages/Order/ui/OrderChat/ui/ChatInfoMessage'
import { ChatMessage } from '@/pages/Order/ui/OrderChat/ui/ChatMessage'
import { ChatFileUploadPreview } from '@/pages/Order/ui/OrderChat/ui/ChatFileUploadPreview'
import { tcn } from '@/utils/tcn'
import { cn } from '@/shared/utils/cn'
import { useInputTextWidth } from '@/shared/utils/textWidth'
import { InSendV2 } from '@/shared/ui/Icons/InSendV2'
import { InAttach } from '@/shared/ui/Icons/InAttach'
import { mediaDocumentIcons } from '@/shared/lib/media'
import { toast } from 'react-toastify'
import { orderChatActions } from '@/pages/Order/ui/OrderChat/model/slice'
import { uploadChatAttachment } from '@/entities/chat/api/attachments'
import { UploadingMedia } from '@/pages/Order/ui/OrderChat/model/types'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { DrawerHandle } from '@/shared/ui/Drawer'
import { useIOSInputFocusOutFix } from '@/hooks/useIOSInputFocusOutFix'
import { useInviteDemandToChat } from '@/entities/chat/api/psmApi'
const { setCurrentMessage } = orderChatActions

interface ChatWindowProps {
    reference: string
    className?: string
    viewportClassName?: string
    psmMode?: boolean
    error: string | null
    userState: any
    addons?: React.ReactNode
    scrollBottom: () => void
    viewportRef: React.RefObject<HTMLDivElement>
    thumbsById: any
    sendMessage: (message: any) => void
}

export const ChatWindow = ({
    reference,
    className,
    viewportClassName,
    addons,
    psmMode,
    error,
    userState,
    sendMessage,
    viewportRef,
    thumbsById,
    scrollBottom,
}: ChatWindowProps) => {
    const [files, setFiles] = useState<File[]>([])
    const chatInputRef = useRef<HTMLTextAreaElement>(null)
    const attachmentRef = useRef<HTMLInputElement>(null)
    const [messagePending, setMessagePending] = useState<any>(false)
    const dispatch = useAppDispatch()
    const uploadingMedias = useAppSelector((state) => state.orderChat.uploadingMedias)
    const chatMessages = useAppSelector((state) => state.orderChat.chatMessages)
    const currentMessage = useAppSelector((state) => state.orderChat.currentMessage)
    const chatLoading = useAppSelector((state) => state.orderChat.chatLoading)
    const selectedChat = useAppSelector((state) => state.orderChat.selectedChat)
    const currentChat = useAppSelector((state) => state.orderChat.currentChat)
    const participants = useAppSelector((state) => state.orderChat.participants)
    const demandInfo = useAppSelector((state) => state.orderChat.demandInfo)
    const chatInputTextWidth = useInputTextWidth(chatInputRef.current)

    const { invite, loading: inviteLoading } = useInviteDemandToChat({
        onError: (message) => {
            toast(message, {
                type: 'error',
            })
        },
        onSuccess: () => {
            dispatch(orderChatActions.setDemandInfo({ ...demandInfo, invited: true }))
            toast('Invitation sent', {
                type: 'success',
            })
        },
    })

    useIOSInputFocusOutFix(chatInputRef)

    const hasFiles = uploadingMedias.length > 0

    useEffect(() => {
        const recalculateInputHeight = (e?: any) => {
            if (chatInputRef.current) {
                // chatInputRef.current.blur()
                const isMoreThan80 = chatInputTextWidth > chatInputRef.current.clientWidth * 0.8
                chatInputRef.current.style.height = 'auto'
                const totalRows = Math.max(
                    1,
                    Math.ceil(uploadingMedias.length / (chatInputRef.current.clientWidth < 300 ? 2 : 3)),
                )
                chatInputRef.current.style.height = `${
                    chatInputRef.current.scrollHeight +
                    (isMoreThan80 && !hasFiles ? 20 : 0) +
                    (hasFiles ? totalRows * 80 + 40 : 0)
                }px`
            }

            setTimeout(scrollBottom, 300)
        }
        recalculateInputHeight()
        const handleInputFocus = (e: any) => {
            // stop intertia scrolling on viewportRef
            if (viewportRef?.current) {
                viewportRef.current.scrollTop += 1
            }
            setTimeout(scrollBottom, 300)
        }
        const current = chatInputRef.current
        if (current) {
            current.addEventListener('blur', recalculateInputHeight)
            // current.addEventListener('touchstart', handleTouchStart)
            current.addEventListener('focus', handleInputFocus)
        }

        window.addEventListener('resize', recalculateInputHeight)
        return () => {
            if (current) {
                current.removeEventListener('blur', recalculateInputHeight)
                // current.removeEventListener('touchstart', handleTouchStart)
                current.removeEventListener('focus', handleInputFocus)
            }
            window.removeEventListener('resize', recalculateInputHeight)
        }
    }, [currentMessage, uploadingMedias.length])

    const handleMessageChange = (e: any) => {
        dispatch(setCurrentMessage(e.target.value))
    }

    const processFiles = () => {
        if (files.length === 0) {
            return Promise.resolve([])
        }
        setMessagePending(true)
        const promises = files.map((file, index) => {
            dispatch(orderChatActions.startUploadingMedia({ id: -index }))
            return uploadChatAttachment(file)
                .then((res) => {
                    dispatch(orderChatActions.finishUploadingMedia({ id: -index }))
                    return res
                })
                .catch((err) => {
                    toast('Error uploading file: ' + err?.response?.data?.message ?? 'Unknown error', {
                        type: 'error',
                    })
                    dispatch(orderChatActions.finishUploadingMedia({ id: -index }))
                    return null
                })
        })
        return Promise.all(promises).finally(() => {
            setMessagePending(false)
        })
    }

    const handleSendMessage = async () => {
        if (!currentMessage?.trim?.() && files.length === 0) {
            toast('Message cannot be empty', {
                type: 'error',
            })
            return
        }
        const filesProcessed = await processFiles()
        const anyFilesFailed = filesProcessed.some((f) => !f)
        if (anyFilesFailed) {
            dispatch(orderChatActions.clearUploadingMedias())
            setFiles([])
            return
        }
        const messageBody: any = {
            message: currentMessage,
            reference,
            attachments: filesProcessed,
        }
        if (typeof selectedChat === 'number') {
            messageBody.chatId = selectedChat
        } else if (selectedChat) {
            messageBody.messagingMode = selectedChat
        }
        sendMessage(messageBody)
        dispatch(orderChatActions.clearUploadingMedias())
        setFiles([])
        dispatch(setCurrentMessage(''))
        setTimeout(() => {
            scrollBottom()
        }, 500)
    }

    const attachFileToMsg = useCallback(
        (uploadedFile: File) => {
            if (uploadedFile) {
                const newFileID = Math.random().toString(36).substring(2, 15)

                setFiles((prev) => [...prev, uploadedFile])

                const uploadingMedia: UploadingMedia = {
                    id: newFileID,
                    loading: false,
                    name: uploadedFile.name,
                    previewLoading: true,
                }
                if (uploadedFile.type.startsWith('image/')) {
                    const reader = new FileReader()
                    reader.onloadend = () => {
                        dispatch(
                            orderChatActions.updateUploadingMedia({
                                id: newFileID,
                                preview: reader.result as any,
                                previewLoading: false,
                            }),
                        )
                    }
                    reader.readAsDataURL(uploadedFile)
                } else {
                    const fileExt = uploadedFile.name.split('.').pop()
                    if (fileExt) {
                        const preview = (mediaDocumentIcons[fileExt] ?? mediaDocumentIcons.pdf)!

                        uploadingMedia.preview = preview as any
                        uploadingMedia.previewLoading = false
                    } else {
                        uploadingMedia.preview = undefined
                    }
                }

                dispatch(orderChatActions.addUploadingMedia(uploadingMedia))
            }
        },
        [dispatch, files.length],
    )

    const handleMessageFileChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const uploadedFiles = e.target.files
            if (uploadedFiles && uploadedFiles.length > 0) {
                if (files.length + uploadedFiles.length > 6) {
                    toast('Maximum 6 files can be attached to the message', {
                        type: 'error',
                    })
                    return
                }
                //iterate over all files and attach them to the message
                for (let i = 0; i < uploadedFiles.length; i++) {
                    const fileSizeMB = uploadedFiles[i].size / 1024 / 1024
                    if (fileSizeMB > 15) {
                        toast('File size should not exceed 15MB (' + uploadedFiles[i].name + ')', {
                            type: 'error',
                        })
                    } else {
                        attachFileToMsg(uploadedFiles[i])
                    }
                }
            }

            if (attachmentRef.current?.value) {
                attachmentRef.current.value = ''
            }
        },
        [attachFileToMsg, files.length],
    )

    const missingUserAccount = demandInfo && !demandInfo?.signedUp
    return (
        <Section
            className={tcn(
                'h-[100vh] relative w-full overflow-hidden rounded-none xl:rounded-xl xl:h-auto flex flex-col',
                className,
                {
                    'h-[calc(100vh-50px)]': psmMode,
                },
            )}
        >
            <DrawerHandle
                className={cn('xl:!hidden', {
                    '!hidden': psmMode,
                })}
            />

            <h2 className="text-[24px] font-bold mb-3 flex-shrink-0 flex justify-between items-start">
                Chat
                {addons}
            </h2>
            <ScrollArea
                viewportProps={{
                    ref: viewportRef,
                }}
                viewportClassName={cn(
                    ' w-full max-w-full border border-border rounded-none xl:rounded-xl order-chat-scrollarea ',
                    viewportClassName,
                    {
                        'xl:h-auto': error,
                        'xl:h-[525px]': !error && !psmMode,
                        'xl:h-[400px]': psmMode,
                    },
                )}
                className="w-full flex-grow overflow-hidden"
            >
                <div className="flex flex-col gap-3 p-3 w-full">
                    {error && <ChatErrorMessage error={error} />}
                    {chatLoading && !error && (
                        <Loader className="h-12 w-12 border-2 border-accent-medium border-t-primary" />
                    )}
                    {!chatLoading && !missingUserAccount && chatMessages.length === 0 && (
                        <ChatInfoMessage message={'There are no messages yet.'} />
                    )}
                    {!chatLoading && missingUserAccount && (
                        <ChatInfoMessage message={'This contact is not registered.'} />
                    )}

                    {!chatLoading &&
                        chatMessages?.length > 0 &&
                        chatMessages.map((message, index) => {
                            return (
                                <ChatMessage
                                    key={index}
                                    {...message}
                                    containerRef={viewportRef}
                                    thumbs={thumbsById}
                                    participants={participants ?? []}
                                    userId={userState.userInfo?.id}
                                />
                            )
                        })}
                </div>
            </ScrollArea>

            {!error && !chatLoading && !missingUserAccount && (
                <div className="mt-5 flex w-full gap-2 flex-shrink-0">
                    <div className="relative flex items-end w-full">
                        <Textarea
                            ref={chatInputRef}
                            className="h-auto w-full rounded-xl px-3 py-1.5 transition-colors duration-75 ring-none outline-none bg-background-secondary text-sm placeholder:text-[12px] placeholder:text-foreground-tertiary disabled:cursor-not-allowed disabled:opacity-50"
                            value={currentMessage}
                            placeholder={'Ask any questions you may have'}
                            onChange={handleMessageChange}
                            rows={1}
                        />
                        <input
                            ref={attachmentRef}
                            accept="image/*,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            type="file"
                            className="hidden"
                            multiple
                            onChange={handleMessageFileChange}
                        />

                        {hasFiles && (
                            <div className="absolute left-2 bottom-6 flex gap-2 flex-wrap">
                                {uploadingMedias.map((med, index) => (
                                    <ChatFileUploadPreview
                                        key={index}
                                        index={index}
                                        media={med}
                                        onRemove={(idx) => {
                                            setFiles((prev) => prev.filter((_, i) => i !== idx))
                                            dispatch(orderChatActions.removeUploadingMediaAt(idx))
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                        <div className="absolute right-2 bottom-2 flex gap-2">
                            <div
                                className="hover:cursor-pointer"
                                onClick={() => {
                                    if (attachmentRef.current) {
                                        attachmentRef.current.click()
                                    }
                                }}
                            >
                                <InAttach size={14} className="text-gray-5 hover:text-primary" />
                            </div>
                        </div>
                    </div>
                    <Button className="p-0 h-9 w-16 shrink-0" onClick={handleSendMessage} disabled={messagePending}>
                        {messagePending ? (
                            <Loader className="w-4 h-4 border-2 border-white border-t-primary" />
                        ) : (
                            <InSendV2 size={24} />
                        )}
                    </Button>
                </div>
            )}
            {missingUserAccount && (
                <Button
                    className="mt-2"
                    onClick={() => invite(reference)}
                    disabled={inviteLoading || demandInfo?.invited}
                >
                    {inviteLoading ? (
                        <Loader className="w-4 h-4 border-2 border-white border-t-primary" />
                    ) : demandInfo?.invited ? (
                        'User invite is pending'
                    ) : (
                        'Invite to register with Quick Sign-Up'
                    )}
                </Button>
            )}
        </Section>
    )
}
