import React from 'react'
import { Input } from '@/shared/ui/Input'
import { InSearch } from '@/shared/ui/Icons/InSearch'
import { FilterParams } from '@/pages/LoadBoard/model/types'
import { useAppSelector } from '@/app/store'

type FilterSearchProps = {
    onParamsChanged: (params: Partial<FilterParams>) => void
}

export const FilterSearch = ({ onParamsChanged }: FilterSearchProps) => {
    const search = useAppSelector((state) => state.loadboard.filters?.search)

    return (
        <div className="px-3">
            <Input
                value={search ?? ''}
                onChange={(e) => onParamsChanged({ search: e.target.value })}
                label="Search"
                className="h-11 md:h-9"
                icon={<InSearch size={16} className="text-foreground-tertiary mr-2" />}
            />
        </div>
    )
}
