import React, { memo, useLayoutEffect } from 'react'
import { Container } from '@/shared/ui/Container'
import { LoadBoardHeader, LoadBoardMain, LoadBoardSidebar } from '@/pages/LoadBoard/ui'
import { useLoadboardQueryParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { useScreen } from '@/shared/hooks/useScreen'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'
import { LoadBoardListControls } from '@/pages/LoadBoard/ui/LoadBoardListControls'
import { useGetLoadBoardTotalsQuery } from '@/entities/loadboard-orders/api'

export const LoadBoardPage = memo(() => {
    const dispatch = useAppDispatch()
    const isInitialized = useAppSelector((state) => state.loadboard.isInitialized)
    const [params, setParams] = useLoadboardQueryParams()

    /** Fetch totals. Automatically sets result to the Loadboard Slice (Check extraReducers) */
    useGetLoadBoardTotalsQuery(undefined, { refetchOnMountOrArgChange: true })

    useLayoutEffect(() => {
        dispatch(loadboardActions.setInitialFilters(params))
    }, [])

    /** Resolutions for different views */
    const isMD = useScreen('md')

    if (!isInitialized) return null

    return (
        <Container className="pt-3 pb-6 lg:w-full">
            <LoadBoardHeader params={params} setParams={setParams} />

            <div className="mt-3 px-3 sm:px-0 flex md:gap-4 xl:gap-6">
                {isMD && <LoadBoardSidebar params={params} setParams={setParams} />}

                <div className="flex flex-col gap-4 xl:gap-2 w-full">
                    <LoadBoardListControls params={params} setParams={setParams} />
                    <LoadBoardMain params={params} setParams={setParams} />
                </div>
            </div>
        </Container>
    )
})
