import React from 'react'
import { useScreen } from '@/shared/hooks/useScreen'
import { ControlsSearch } from '@/pages/LoadBoard/ui'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { ControlsHideQuoted } from './ControlsHideQuoted'
import { ControlsMarkAsRead } from './ControlsMarkAsRead'
import { ControlsCategory } from './ControlsCategory'
import { ControlsFilters } from './ControlsFilters'
import { ControlsSorting } from './ControlsSorting'

import { Separator } from '@/shared/ui/Separator'
import { LoadboardCategory } from '@/entities/loadboard-orders/model'

type LoadBoardListControlsProps = {
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
}

export const LoadBoardListControls = ({ params, setParams }: LoadBoardListControlsProps) => {
    const isMD = useScreen('md')
    const isLG = useScreen('lg')
    const isXL = useScreen('xl')

    if (isXL) {
        return (
            <div className="flex gap-3 items-center">
                <ControlsSearch params={params} setParams={setParams} />
                {(params.category === LoadboardCategory.New || params.category === LoadboardCategory.NotInterested) && (
                    <div className="ml-2 flex shrink-0">
                        <ControlsHideQuoted params={params} setParams={setParams} />
                    </div>
                )}
                <ControlsMarkAsRead params={params} />
            </div>
        )
    }

    if (isLG) {
        return (
            <div className="flex gap-3 items-center">
                <ControlsSearch params={params} setParams={setParams} />
                <ControlsSorting params={params} setParams={setParams} />
                <Separator orientation="vertical" />
                {(params.category === LoadboardCategory.New || params.category === LoadboardCategory.NotInterested) && (
                    <ControlsHideQuoted params={params} setParams={setParams} />
                )}
                <ControlsMarkAsRead params={params} />
            </div>
        )
    }

    if (isMD) {
        return (
            <div className="flex flex-col gap-3 items-center">
                <div className="w-full flex gap-3 items-center">
                    <ControlsCategory params={params} setParams={setParams} />
                    <ControlsSorting params={params} setParams={setParams} />
                </div>
                <div className="w-full flex gap-3 items-center">
                    {(params.category === LoadboardCategory.New ||
                        params.category === LoadboardCategory.NotInterested) && (
                        <ControlsHideQuoted params={params} setParams={setParams} />
                    )}
                    <ControlsMarkAsRead params={params} />
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-3 items-center">
            <div className="w-full flex gap-3 items-center">
                <ControlsCategory params={params} setParams={setParams} />
                <ControlsFilters params={params} setParams={setParams} />
                <ControlsSorting params={params} setParams={setParams} />
            </div>
            <div className="w-full flex gap-3 items-center">
                {(params.category === LoadboardCategory.New || params.category === LoadboardCategory.NotInterested) && (
                    <ControlsHideQuoted params={params} setParams={setParams} />
                )}
                <ControlsMarkAsRead params={params} />
            </div>
        </div>
    )
}
