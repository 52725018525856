import React from 'react'
import { GetLoadboardOrdersResponse } from '@/entities/loadboard-orders/model/response'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { LoadBoardListItem } from '@/pages/LoadBoard/ui/LoadBoardMain/LoadBoardList/LoadBoardListItem'
import { Section } from '@/shared/ui/Section'
import { Loader } from '@/shared/ui/Loader'
import { Pagination } from '@/shared/ui/Pagination'
import { useMediaQuery } from '@/shared/hooks/useMediaQuery'
import { LoadboardCategory } from '@/entities/loadboard-orders/model'

type LoadBoardListProps = {
    data: GetLoadboardOrdersResponse | undefined
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
    isLoading: boolean
}

export const LoadBoardList = ({ data, params, setParams, isLoading }: LoadBoardListProps) => {
    const isSmallScreen = useMediaQuery('(max-width: 380px)')

    if (!data || isLoading) {
        return (
            <Section className="w-full h-40">
                <Loader />
            </Section>
        )
    }

    if (!data.orders?.length) {
        return <Section className="w-full h-24 flex items-center justify-center">No results.</Section>
    }

    return (
        <div className="flex flex-col gap-4">
            {data.orders.map((order) => (
                <LoadBoardListItem key={order.id} order={order} category={params.category as LoadboardCategory} />
            ))}
            <Pagination
                siblings={isSmallScreen ? 0 : 1}
                rootClassName="flex justify-center"
                // contentClassName="gap-3"
                totalPages={Math.ceil(data.count / params.pageSize)}
                pageIndex={params.pageIndex}
                onPageChange={(pageIndex) => {
                    setParams({ pageIndex })
                    console.log(123)
                    document.querySelector('#app')?.scroll({ top: 0 })
                }}
            />
        </div>
    )
}
