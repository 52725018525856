import React from 'react'
import { Section } from '@/shared/ui/Section'
import { Select, SelectOption } from '@/shared/ui/Select'
import { LoadboardCategory, LoadboardCategoryLabel } from '@/entities/loadboard-orders/model'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { Badge } from '@/shared/ui/Badge'

const CATEGORY_OPTIONS: SelectOption[] = [
    { value: LoadboardCategory.New, label: LoadboardCategoryLabel[LoadboardCategory.New] },
    { value: LoadboardCategory.Quoted, label: LoadboardCategoryLabel[LoadboardCategory.Quoted] },
    { value: LoadboardCategory.Accepted, label: LoadboardCategoryLabel[LoadboardCategory.Accepted] },
    { value: LoadboardCategory.Lost, label: LoadboardCategoryLabel[LoadboardCategory.Lost] },
    { value: LoadboardCategory.NotInterested, label: LoadboardCategoryLabel[LoadboardCategory.NotInterested] },
]

type LoadBoardMobileCategoryProps = {
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
}

export const ControlsCategory = ({ params, setParams }: LoadBoardMobileCategoryProps) => {
    const dispatch = useAppDispatch()

    const onCategoryChange = (v: string) => {
        const newParams: any = {
            category: v,
            pageIndex: 0,
        }
        if (v !== LoadboardCategory.Accepted && params.sortBy === 'rfqStatus') {
            newParams.sortBy = undefined
            newParams.sortOrder = undefined
            dispatch(loadboardActions.setSorting([{ id: 'startDate', desc: true }]))
        }
        setParams(newParams)
    }

    const category = params.category as LoadboardCategory
    const totals = useAppSelector((state) => state.loadboard.totals)

    return (
        <Section className="!p-0 rounded-lg">
            <Select
                options={CATEGORY_OPTIONS}
                value={params.category}
                onValueChange={onCategoryChange}
                triggerClassName="h-11"
                renderValue={() => (
                    <div className="flex gap-1 items-center">
                        <div>{LoadboardCategoryLabel[category]}</div>
                        {totals[category] ? (
                            <Badge
                                variant="danger"
                                className="py-0 h-[18px] px-1.5 flex justify-center items-center rounded-[4px]"
                            >
                                <span className="mt-[1px]">{totals[category]}</span>
                            </Badge>
                        ) : null}
                    </div>
                )}
                renderItemContent={(option) => (
                    <div className="flex gap-3 items-center">
                        <div>{LoadboardCategoryLabel[option.value as LoadboardCategory]}</div>
                        {totals[option.value as LoadboardCategory] ? (
                            <Badge
                                variant="danger"
                                className="py-0 h-[18px] px-1.5 flex justify-center items-center rounded-[4px]"
                            >
                                <span className="mt-[1px]">{totals[option.value as LoadboardCategory]}</span>
                            </Badge>
                        ) : null}
                    </div>
                )}
            />
        </Section>
    )
}
