import React from 'react'
import { Badge } from '@/shared/ui/Badge'
import { RfqStatus } from '@/entities/rfq/model'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'

type LoadBoardItemStatusProps = {
    order: LoadboardOrder
}

export const LoadBoardItemStatus = ({ order }: LoadBoardItemStatusProps) => {
    if (order.paid) {
        return <Badge variant="success">Paid</Badge>
    }

    if (order.rfqStatus === RfqStatus.Booked) {
        return <Badge variant="warning">Booked</Badge>
    }

    if (order.rfqStatus === RfqStatus.Dropped) {
        return <Badge variant="info">Dropped</Badge>
    }

    if (order.rfqStatus === RfqStatus.Finished) {
        return <Badge variant="success">Finished</Badge>
    }

    return null
}
