import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InCaretUp = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.24745 4.86009L2.45121 10.3415C1.88545 10.9881 2.34463 12 3.20379 12H12.7963C13.6554 12 14.1146 10.9881 13.5488 10.3415L8.7526 4.86009C8.35419 4.40476 7.64586 4.40476 7.24745 4.86009Z" />
            </svg>
        </IconBase>
    )
}
