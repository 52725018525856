import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InLocation = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.25938 15.6C3.62813 13.5938 0 8.73125 0 6C0 2.68625 2.68625 0 6 0C9.3125 0 12 2.68625 12 6C12 8.73125 8.34375 13.5938 6.74062 15.6C6.35625 16.0781 5.64375 16.0781 5.25938 15.6ZM6 8C7.10313 8 8 7.10313 8 6C8 4.89687 7.10313 4 6 4C4.89687 4 4 4.89687 4 6C4 7.10313 4.89687 8 6 8Z" />
            </svg>
        </IconBase>
    )
}
