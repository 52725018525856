import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/shared/utils/cn'

const alertRootVariants = cva('flex flex-col gap-3 w-full p-3 border rounded-xl', {
    variants: {
        variant: {
            default: 'border-border bg-background-tertiary',
            info: 'border-primary bg-primary-light/20',
            success: 'border-success bg-success-light/20',
            warning: 'border-warning bg-warning-light/20',
            danger: 'border-danger bg-danger-light',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
})

const alertTitleVariants = cva('text-base font-medium leading-5', {
    variants: {
        variant: {
            default: '',
            info: 'text-primary',
            success: 'text-success',
            warning: 'text-warning',
            danger: 'text-danger-dark',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
})

const AlertContext = React.createContext<VariantProps<typeof alertRootVariants>>({
    variant: 'default',
})

const AlertRoot = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertRootVariants>
>(({ className, variant, children, ...props }, ref) => (
    <div ref={ref} role="alert" className={cn(alertRootVariants({ variant }), className)} {...props}>
        <AlertContext.Provider value={{ variant: variant }}>{children}</AlertContext.Provider>
    </div>
))
AlertRoot.displayName = 'AlertRoot'

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => {
        const { variant } = React.useContext(AlertContext)

        return <div ref={ref} className={cn(alertTitleVariants({ variant, className }))} {...props} />
    },
)
AlertTitle.displayName = 'AlertTitle'

const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
    ({ className, ...props }, ref) => (
        <div ref={ref} className={cn('[&_p]:leading-relaxed', className)} {...props} />
    ),
)
AlertDescription.displayName = 'AlertDescription'

export { AlertRoot, AlertTitle, AlertDescription }
