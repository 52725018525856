import { getSWRFetcher, HttpV2 } from '@shared/apiv2/api'
import { QuoteDataDto } from '@/pages/PSM/Bid/model/response'
import useSWR from 'swr'
import { QuoteRequestDto } from '@/pages/PSM/Bid/model/request'

export const useQuoteData = (bidId?: string | number) => {
    const { data, error } = useSWR<QuoteDataDto>(bidId ? `/psm/bids/${bidId}/quote` : null, getSWRFetcher, {
        revalidateOnFocus: false,
        shouldRetryOnError: false,
    })
    return {
        data,
        error,
        isLoading: !data && !error,
    }
}

export const sendQuoteData = async (bidId: string, data: QuoteRequestDto) => {
    return HttpV2.post(`/psm/bids/${bidId}/quote`, data)
}
