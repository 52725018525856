import React from 'react'
import { Navigate } from 'react-router-dom'
import { useUserState } from '@/store/user'
import { ssoUrl } from '@/router'

// eslint-disable-next-line complexity
const SignUp: React.FC = () => {
    const { state: userState } = useUserState()
    if (!userState.isLoggedIn || !userState.userInfo?.verified) {
        window.location.href = `${ssoUrl}/${window.location.search}`
        return null
    }

    if (userState.isLoggedIn && userState.userInfo?.verified) {
        return <Navigate to="/" />
    }
    return null
}

export default SignUp
