import * as React from 'react'
import { Command as CommandPrimitive } from './cmdk'

import { cn } from '@/shared/utils/cn'
import { InputIcon, InputLabel, InputWrapper } from '@/shared/ui/Input'
import { InSearch } from '@/shared/ui/Icons/InSearch'
import { v4 } from 'uuid'
import { ErrorMessage } from '@/shared/ui/ErrorMessage'
import { useComposedRefs } from '@/shared/hooks/useComposedRefs'
import { autoUpdate, size, useFloating } from '@floating-ui/react'

const Command = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
    <CommandPrimitive
        ref={ref}
        className={cn('flex h-full w-full flex-col overflow-hidden rounded-md bg-background-secondary', className)}
        {...props}
    />
))
Command.displayName = CommandPrimitive.displayName

export type CommandInputProps = Omit<React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>, 'id'> & {
    wrapperClassName?: string
    wrapperProps?: Omit<React.ComponentProps<typeof InputWrapper>, 'className'>

    label?: React.ReactNode
    labelClassName?: string
    labelProps?: Omit<React.ComponentProps<typeof InputLabel>, 'className'>

    icon?: React.ReactNode
    iconClassName?: string
    iconWrapperClassName?: string
    iconWrapperProps?: Omit<React.ComponentProps<typeof InputIcon>, 'className'>

    error?: React.ReactNode
    errorClassName?: string
    errorProps?: Omit<React.ComponentProps<typeof ErrorMessage>, 'className'>
}

const CommandInput = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Input>, CommandInputProps>(
    (
        {
            wrapperClassName,
            wrapperProps,

            label,
            labelClassName,
            labelProps,

            icon,
            iconClassName,
            iconWrapperClassName,
            iconWrapperProps,

            error,
            errorClassName,
            errorProps,

            className,

            ...inputProps
        },
        ref,
    ) => {
        const cmdkId = React.useRef<string | undefined>()

        const { refs } = useFloating({
            whileElementsMounted: autoUpdate,
            middleware: [
                size({
                    apply: ({ elements, rects }) => {
                        const { width: inputWidth, height: inputHeight } = rects.reference
                        const contentStyle = elements.floating.style
                        contentStyle.setProperty('--command-input-field-width', `${inputWidth}px`)
                        contentStyle.setProperty('--command-input-field-height', `${inputHeight}px`)
                    },
                }),
            ],
        })
        const composedInputRef = useComposedRefs(
            ref,
            (node) => refs.setReference(node),
            (node) => {
                if (node) cmdkId.current = node.id
            },
        )
        const composedWrapperRef = useComposedRefs(iconWrapperProps?.ref, (node) => refs.setFloating(node))

        return (
            <InputWrapper
                className={cn('border-b', wrapperClassName)}
                cmdk-input-wrapper=""
                {...wrapperProps}
                ref={composedWrapperRef}
            >
                {label !== undefined ? (
                    <InputLabel
                        className={cn(!!error && 'text-danger', labelClassName)}
                        htmlFor={cmdkId.current}
                        {...labelProps}
                    >
                        {label}
                    </InputLabel>
                ) : null}

                <CommandPrimitive.Input
                    ref={composedInputRef}
                    className={cn(
                        'peer flex h-10 w-full pl-3 bg-transparent text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
                        className,
                    )}
                    {...inputProps}
                />

                <InputIcon
                    className={cn(
                        'h-[var(--command-input-field-height)] left-[var(--command-input-field-width)] -translate-x-full',
                        iconWrapperClassName,
                    )}
                    {...iconWrapperProps}
                >
                    {icon !== undefined ? (
                        icon
                    ) : (
                        <InSearch className={cn('mr-3 h-4 w-4 shrink-0 text-foreground-tertiary', iconClassName)} />
                    )}
                </InputIcon>

                <ErrorMessage className={cn('ml-3 mt-0.5', errorClassName)} {...errorProps}>
                    {error}
                </ErrorMessage>
            </InputWrapper>
        )
    },
)
CommandInput.displayName = CommandPrimitive.Input.displayName

const CommandList = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.List ref={ref} className={cn('overflow-y-auto overflow-x-hidden', className)} {...props} />
))
CommandList.displayName = CommandPrimitive.List.displayName

const CommandEmpty = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Empty>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Empty ref={ref} className={cn('py-4 text-center text-sm', className)} {...props} />
))
CommandEmpty.displayName = CommandPrimitive.Empty.displayName

const CommandGroup = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Group>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Group
        ref={ref}
        className={cn(
            'overflow-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground',
            className,
        )}
        {...props}
    />
))

CommandGroup.displayName = CommandPrimitive.Group.displayName

const CommandItem = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Item
        ref={ref}
        className={cn(
            'relative flex cursor-default select-none items-center rounded h-8 px-2 text-sm outline-none transition-colors duration-75 aria-selected:bg-background-tertiary aria-selected:text-accent-foreground data-[disabled=true]:pointer-events-none data-[selected=true]:bg-accent data-[selected=true]:text-accent-foreground data-[disabled=true]:opacity-50',

            className,
        )}
        {...props}
    />
))
CommandItem.displayName = CommandPrimitive.Item.displayName

const CommandSeparator = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Separator ref={ref} className={cn('-mx-1 h-px bg-border', className)} {...props} />
))
CommandSeparator.displayName = CommandPrimitive.Separator.displayName

const CommandShortcut = ({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => {
    return <span className={cn('ml-auto text-xs tracking-widest text-muted-foreground', className)} {...props} />
}
CommandShortcut.displayName = 'CommandShortcut'

export {
    Command,
    CommandInput,
    CommandList,
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandSeparator,
    CommandShortcut,
}
