import React from 'react'
import { SWRProvider } from '@/app/providers/SWRProvider'

type AppProviderProps = {
    children?: React.ReactNode
}

export const AppProviders = ({ children }: AppProviderProps) => {
    return <SWRProvider>{children}</SWRProvider>
}
