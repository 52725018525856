import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InSearch = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.9673 13.8656L12.2267 10.125C13.0773 8.86219 13.497 7.28437 13.2723 5.60313C12.8892 2.74344 10.5455 0.416254 7.68297 0.0538482C3.42703 -0.484683 -0.151716 3.09406 0.386847 7.35C0.749347 10.2137 3.07685 12.5594 5.93685 12.9406C7.6181 13.1653 9.19622 12.7457 10.4587 11.895L14.1993 15.6356C14.6875 16.1237 15.479 16.1237 15.9672 15.6356C16.4548 15.1469 16.4548 14.3531 15.9673 13.8656ZM2.80485 6.5C2.80485 4.29438 4.59922 2.5 6.80485 2.5C9.01047 2.5 10.8048 4.29438 10.8048 6.5C10.8048 8.70562 9.01047 10.5 6.80485 10.5C4.59922 10.5 2.80485 8.70625 2.80485 6.5Z" />
            </svg>
        </IconBase>
    )
}
