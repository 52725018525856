import React from 'react'
import { RfqService } from '@/entities/rfq/model'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { StandardQuoteService } from '@/pages/Order/ui/OrderQuote/model'
import { crossDockingService } from './CrossDocking'
import { transloadingService } from './Transloading'
import { storageService } from './Storage'
import { disposalService } from './Disposal'
import { drayageService } from './Drayage'
import { finalMileService } from './FinalMile'
import { handlingService } from './Handling'
import { reworkService } from './Rework'
import { yardStorageService } from './YardStorage'

export type ServiceBreakdownComponentProps = {
    order: LoadboardOrder
    service: StandardQuoteService
    revisedService?: StandardQuoteService
}

export type ServiceFormComponentProps = {
    order: LoadboardOrder
    isFloorLoaded: boolean
    service: StandardQuoteService
    errors: { [key: string]: string }
}

export type ServiceMethods = {
    breakdownComponent: React.FC<ServiceBreakdownComponentProps>
    formComponent: React.FC<ServiceFormComponentProps>
    getDefaultService: (type?: string) => any
}

export type ServiceComponentsMap = Partial<Record<RfqService, ServiceMethods>>

export const standardQuoteService: ServiceComponentsMap = {
    [RfqService.CrossDocking]: crossDockingService,
    [RfqService.Disposal]: disposalService,
    [RfqService.Drayage]: drayageService,
    [RfqService.FinalMile]: finalMileService,
    [RfqService.Handling]: handlingService,
    [RfqService.Rework]: reworkService,
    [RfqService.Storage]: storageService,
    [RfqService.Transloading]: transloadingService,
    [RfqService.YardStorage]: yardStorageService,
}
