import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing } from '@/entities/rfq/model'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'
import { getRevisedChanges } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised'
import { StandardQuoteRevisedValues } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised/ui'
import { cn } from '@/shared/utils/cn'

export const StorageBreakdownComponent = ({ order, service, revisedService }: ServiceBreakdownComponentProps) => {
    const changes = getRevisedChanges({
        service,
        revisedService,
        pricing: RfqServicePricing.Storage,
        includeType: true,
        includeModifier: true,
    })

    return (
        <div className="flex flex-col gap-3">
            <div className="flex flex-col">
                {service.pricings[RfqServicePricing.Storage]?.price ? (
                    <div className="flex flex-col sm:flex-row gap-1 sm:gap-2 sm:items-center w-full">
                        <CurrencyDisplay
                            value={service.pricings[RfqServicePricing.Storage]!.price}
                            className={cn('text-xl leading-6 text-primary font-semibold', {
                                'text-success': changes?.price?.status === -1,
                                'text-danger': changes?.price?.status === 1,
                            })}
                        />

                        <div className="flex gap-1 sm:gap-2 items-center">
                            <span className="text-xl shrink-0 text-foreground-secondary hidden sm:inline">/</span>
                            <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 sm:hidden">
                                per
                            </span>
                            <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                {service.type === 'unit' ? order.unitType ?? 'Unit' : 'Sqft'}
                            </span>
                        </div>
                        <div className="flex gap-1 sm:gap-2 items-center">
                            <span className="text-xl shrink-0 text-foreground-secondary hidden sm:inline">/</span>
                            <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 sm:hidden">
                                per
                            </span>
                            <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                {service.pricings[RfqServicePricing.Storage]!.modifier}
                            </span>
                        </div>
                    </div>
                ) : (
                    <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                )}

                <StandardQuoteRevisedValues
                    changes={changes}
                    fields={['price', 'type', 'modifier']}
                    className="flex-col sm:flex-row items-start sm:items-center gap-0 sm:gap-1 mt-1 sm:mt-0"
                    renderRevisedType={(type) => (
                        <div className="flex gap-1 sm:gap-2 items-center">
                            <span className="text-sm shrink-0 text-foreground-tertiary hidden sm:inline no-underline">
                                /
                            </span>
                            <span className="text-foreground-tertiary text-sm font-medium shrink-0 leading-4 sm:hidden">
                                per
                            </span>
                            <span
                                className={cn(
                                    'text-foreground-tertiary text-sm font-medium shrink-0 leading-4 capitalize',
                                    type?.status !== 0 && 'line-through',
                                )}
                            >
                                {type?.value === 'sqft' ? 'Sqft' : order.unitType ?? 'Unit'}
                            </span>
                        </div>
                    )}
                    renderRevisedModifier={(modifier) => (
                        <div className="flex gap-1 sm:gap-2 items-center">
                            <span className="text-sm shrink-0 text-foreground-tertiary hidden sm:inline no-underline">
                                /
                            </span>
                            <span className="text-foreground-tertiary text-sm font-medium shrink-0 leading-4 sm:hidden">
                                per
                            </span>
                            <span
                                className={cn(
                                    'text-foreground-tertiary text-sm font-medium shrink-0 leading-4 capitalize',
                                    modifier?.status !== 0 && 'line-through',
                                )}
                            >
                                {modifier?.value}
                            </span>
                        </div>
                    )}
                />
            </div>
        </div>
    )
}
