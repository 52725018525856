import React from 'react'
import { TabsList, TabsRoot, TabsTrigger } from '@/shared/ui/Tabs'
import { Badge } from '@/shared/ui/Badge'
import { useScreen } from '@/shared/hooks/useScreen'
import { LoadboardCategory, LoadboardCategoryLabel } from '@/entities/loadboard-orders/model'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { GetLoadboardParams } from '@/pages/LoadBoard/lib/useLoadboardQueryParams'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'
import { useAppDispatch, useAppSelector } from '@/app/store'

type LoadBoardHeaderProps = {
    params: DecodedValueMap<GetLoadboardParams>
    setParams: SetQuery<GetLoadboardParams>
}

export const LoadBoardHeader = ({ params, setParams }: LoadBoardHeaderProps) => {
    const dispatch = useAppDispatch()

    const onCategoryChange = (v: string) => {
        const newParams: any = {
            category: v,
            pageIndex: 0,
        }
        if (v !== LoadboardCategory.Accepted && params.sortBy === 'rfqStatus') {
            newParams.sortBy = undefined
            newParams.sortOrder = undefined
            dispatch(loadboardActions.setSorting([{ id: 'startDate', desc: true }]))
        }
        setParams(newParams)
    }

    const totals = useAppSelector((state) => state.loadboard.totals)

    const isLG = useScreen('lg')

    return (
        <header className="flex md:gap-4 xl:gap-6 items-end border-b border-border">
            <div className="w-[260px] text-[32px] leading-10 font-semibold px-3 shrink-0">LoadBoard</div>

            {isLG ? (
                <TabsRoot defaultValue={params.category} onValueChange={onCategoryChange}>
                    <TabsList className="gap-4">
                        {Object.values(LoadboardCategory).map((c) => (
                            <TabsTrigger key={c} value={c} className="gap-1 items-center">
                                <div>{LoadboardCategoryLabel[c]}</div>
                                {totals[c] ? (
                                    <Badge
                                        variant="danger"
                                        className="py-0 h-[18px] px-1.5 flex justify-center items-center rounded-[4px]"
                                    >
                                        <span className="mt-[1px]">{totals[c]}</span>
                                    </Badge>
                                ) : null}
                            </TabsTrigger>
                        ))}
                    </TabsList>
                </TabsRoot>
            ) : null}
        </header>
    )
}
