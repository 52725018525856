import React, { FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Loader } from '@/shared/ui/Loader'

export const DevRoutes: FC = () => {
    // dynamically load Emails
    const Emails = React.lazy(() => import('@/pages/Dev/Emails/Emails').then((module) => ({ default: module.Emails })))
    return (
        <Routes>
            <Route
                path="/emails"
                element={
                    <Suspense fallback={<Loader />}>
                        <Emails />
                    </Suspense>
                }
            />
        </Routes>
    )
}
