import React from 'react'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { LoadboardCategory, LoadboardOrder } from '@/entities/loadboard-orders/model'
import { format } from 'date-fns'
import { InLocation } from '@/shared/ui/Icons/InLocation'
import { Button } from '@/shared/ui/Button'
import { Link } from 'react-router-dom'
import ROUTES from '@/router'
import { cn } from '@/shared/utils/cn'
import { LoadBoardItemServices, LoadBoardItemStatus } from '@/pages/LoadBoard/ui/LoadBoardMain/LoadBoardItem'
import { LoadBoardItemSub } from '@/pages/LoadBoard/ui/LoadBoardMain/LoadBoardItem/LoadBoardItemSub'
import { Separator } from '@/shared/ui/Separator'
import { FaWarehouse } from 'react-icons/fa6'
import { HintContent, HintRoot, HintTrigger } from '@/shared/ui/Hint'

export const getLoadBoardTableColumns = (
    category: LoadboardCategory,
    orders?: LoadboardOrder[],
): ColumnDef<LoadboardOrder>[] => {
    const showDistance = orders?.some((o) => typeof o.distance === 'number')
    const showStatus = orders?.some((o) => !!o.rfqStatus)

    return [
        {
            accessorKey: 'id',
            header: 'Order #',
            cell: function Cell({ cell, row }) {
                return (
                    <div>
                        <Button
                            variant="ghost"
                            className="h-auto min-h-9 flex-col items-start gap-0 justify-center"
                            asChild
                        >
                            <Link to={`${ROUTES.order.base}/${row.original.id}`}>
                                <span className="text-primary !font-semibold text-sm 2xl:!text-base text-nowrap">
                                    {cell.getValue()}
                                </span>
                                <LoadBoardItemSub order={row.original} />
                            </Link>
                        </Button>
                    </div>
                )
            },
            meta: {
                headClassName: '!w-[164px] 2xl:!w-[188px] px-0 !pl-5 box-content',
                cellClassName: '!w-[176px] 2xl:!w-[200px] px-0 !pl-2 box-content',
            },
        },
        ...(showStatus
            ? [
                  {
                      accessorKey: 'rfqStatus',
                      header: 'Status',
                      cell: ({ row }: CellContext<LoadboardOrder, any>) => {
                          return <LoadBoardItemStatus order={row.original} />
                      },
                      meta: {
                          headClassName: cn('w-[92px] px-0'),
                          cellClassName: cn('w-[92px] px-0'),
                      },
                  },
              ]
            : []),
        {
            accessorKey: 'startDate',
            header: 'Start Date',
            cell: ({ row }) => {
                const date = new Date(row.original.startDate)
                return <div className="">{format(date, 'MM/dd/yyyy')}</div>
            },
            meta: {
                headClassName: 'w-[112px] px-0',
                cellClassName: 'w-[112px] px-0',
            },
        },
        {
            accessorKey: 'endDate',
            header: 'End Date',
            cell: ({ row }) => {
                const date = new Date(row.original.endDate)
                return <div className="">{format(date, 'MM/dd/yyyy')}</div>
            },
            meta: {
                headClassName: 'w-[112px] px-0 pr-3',
                cellClassName: 'w-[112px] px-0 ',
            },
        },
        {
            accessorKey: 'totalUnits',
            header: () => {
                return <div className="text-nowrap">Units #</div>
            },
            cell: ({ getValue }) => {
                return <div>{getValue()}</div>
            },
            meta: {
                headClassName: 'w-[76px] px-0',
                cellClassName: 'w-[76px] px-0',
            },
        },
        {
            accessorKey: 'unitType',
            header: 'Unit Type',
            cell: ({ cell }) => {
                return (
                    <div className="flex gap-2 items-center max-w-[80px] 2xl:max-w-[120px]">
                        <div className="text-ellipsis overflow-hidden text-nowrap">{cell.getValue()}</div>
                    </div>
                )
            },
            meta: {
                headClassName: 'w-[92px] 2xl:w-[132px] px-0',
                cellClassName: 'w-[92px] 2xl:w-[132px] px-0',
            },
            enableSorting: false,
        },

        ...(category === LoadboardCategory.Accepted
            ? [
                  {
                      accessorKey: 'warehouseName',
                      header: 'Warehouse',
                      cell: ({ row }: CellContext<LoadboardOrder, any>) => {
                          return (
                              <div className="flex gap-2 items-center w-[calc(100%-20px)]">
                                  <HintRoot>
                                      <HintTrigger asChild>
                                          <div className="flex gap-2 items-center overflow-hidden">
                                              <InLocation size={16} className="text-foreground-secondary shrink-0" />
                                              <div className="text-ellipsis overflow-hidden text-nowrap">
                                                  {row.original.warehouseName}
                                              </div>
                                          </div>
                                      </HintTrigger>
                                      <HintContent>
                                          <div className="flex flex-col">
                                              <span className="text-foreground-secondary text-xs">
                                                  Service Location
                                              </span>
                                              <div className="text-sm font-medium">{row.original.address}</div>
                                          </div>
                                      </HintContent>
                                  </HintRoot>
                              </div>
                          )
                      },
                      enableSorting: false,
                      meta: {
                          headClassName: 'w-full px-0',
                          cellClassName: 'w-full px-0',
                      },
                  },
              ]
            : [
                  {
                      accessorKey: showDistance ? 'distance' : 'address',
                      header: showDistance ? 'Distance' : 'Service Location',
                      cell: ({ row }: CellContext<LoadboardOrder, any>) => {
                          return (
                              <div className="flex gap-2 items-center w-[calc(100%-20px)]">
                                  <div className="flex gap-1 items-center overflow-hidden">
                                      <InLocation size={16} className="text-foreground-secondary shrink-0" />
                                      <div className="text-ellipsis overflow-hidden text-nowrap">
                                          {row.original.address}
                                      </div>
                                  </div>
                                  {showDistance && (
                                      <>
                                          <Separator orientation="vertical" className="h-auto self-stretch" />
                                          <div className="text-nowrap shrink-0">{row.original.distance ?? 0} mi</div>
                                      </>
                                  )}
                              </div>
                          )
                      },
                      enableSorting: showDistance,
                      meta: {
                          headClassName: 'w-full px-0',
                          cellClassName: 'w-full px-0',
                      },
                  },
              ]),

        {
            accessorKey: 'services',
            header: 'Services',
            cell: ({ row }) => {
                const services = row.original.services.split(',')
                return <LoadBoardItemServices services={services} />
            },
            enableSorting: false,
            meta: {
                headClassName: 'w-[100px] px-0 pr-5',
                cellClassName: 'w-[100px] px-0 pr-5',
            },
        },
    ]
}
