import React, { FC } from 'react'
import './HowItWorks.scss'
import HowItWorksList from './HowItWorksList'
import { LoadBoardGate } from '@/pages/LoadBoard/LoadBoardGate'
import HowItWorksSteps from './HowItWorksSteps'

export default function HowItWorksPage() {
    return (
        <>
            <LoadBoardGate />
            <div className="wrapper">
                <div className="how-it-works">
                    <h1 className="how-it-works__title">How it works</h1>
                    <h2 className="how-it-works__subtitle">
                        The OLIMP Warehousing Load Board is a platform to find, bid on and book warehousing
                        opportunities.
                    </h2>
                    <HowItWorksSteps />
                </div>
            </div>
        </>
    )
}
