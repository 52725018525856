import { HttpV2 } from '@shared/apiv2/api'
import { BaseQueryFn, createApi, retry } from '@reduxjs/toolkit/query/react'
import { AxiosError, AxiosRequestConfig } from 'axios'

export const axiosBaseQuery = (): BaseQueryFn<AxiosRequestConfig> => async (params) => {
    try {
        const res = await HttpV2({ ...params })
        return { data: res.data }
    } catch (error) {
        const axiosError = error as AxiosError
        return {
            error: {
                // Use 'FETCH_ERROR' for non-HTTP errors
                status: axiosError.response?.status || 'FETCH_ERROR',

                // Server response or the error message
                data: axiosError.response?.data || axiosError.message || 'An unknown error occurred',
            },
        }
    }
}

const baseQueryWithRetry = retry(axiosBaseQuery(), { maxRetries: 3 })

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithRetry,
    endpoints: () => ({}),
})
