export enum LbPostBidStatus {
    CREATED,
    ACCEPTED,
    DECLINED,
    QUOTED,
    REQUOTE,
    REVISED,
    WAREHOUSE_CANCELLED,
}

export enum LbPostBidType {
    ALLIN,
    STANDARD,
}
