import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { orderChatReducer } from '@/pages/Order/ui/OrderChat/model/slice'
import { orderPageReducer } from '@/pages/Order/model'
import { orderQuoteSupplyReducer } from '@/pages/Order/ui/OrderQuote/ui/OrderQuoteSupply/model'
import { notificationsReducer } from '@/entities/notifications/model/slice'
import { api } from '@/shared/api/rtk-query'
import { loadboardReducer } from '@/pages/LoadBoard/model/slice'

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        orderChat: orderChatReducer,
        orderPage: orderPageReducer,
        orderQuoteSupply: orderQuoteSupplyReducer,
        notifications: notificationsReducer,
        loadboard: loadboardReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
