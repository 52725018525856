import React, { createContext, useContext, useState, ReactNode } from 'react'

interface RfqDataContextProps {
    children: ReactNode
}

interface RfqContextData {
    resetForm?: () => void
}

// Accept either a partial update or a function updater
type SetRfqContextDataType = React.Dispatch<React.SetStateAction<RfqContextData>>

interface RfqDataContextValue {
    rfqContextData: RfqContextData
    setRfqContextData: SetRfqContextDataType // Use the dispatch type to support function updaters
}

const RfqDataContext = createContext<RfqDataContextValue | null>(null)

// Context provider component
export const RfqDataProvider: React.FC<RfqDataContextProps> = ({ children }) => {
    const [rfqContextData, setRfqData] = useState<RfqContextData>({})

    const contextValue: RfqDataContextValue = {
        rfqContextData,
        setRfqContextData: setRfqData,
    }

    return <RfqDataContext.Provider value={contextValue}>{children}</RfqDataContext.Provider>
}

export const useRfqData = (): RfqDataContextValue => {
    const context = useContext(RfqDataContext)

    if (!context) {
        throw new Error('useRfqData must be used within a RfqDataProvider')
    }

    return context
}
