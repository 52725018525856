import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import { Separator } from '@/shared/ui/Separator'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'
import { HintArrow, HintContent, HintRoot, HintTrigger } from '@/shared/ui/Hint'
import { FaInfoCircle } from 'react-icons/fa'
import { getRevisedChanges } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised'
import { StandardQuoteRevisedValues } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services/RenderRevised/ui'
import { cn } from '@/shared/utils/cn'

export const TransloadingBreakdownComponent = ({ order, service, revisedService }: ServiceBreakdownComponentProps) => {
    if (service.type === 'allIn') {
        const unloadingChanges = getRevisedChanges({
            service,
            revisedService,
            pricing: RfqServicePricing.Unloading,
        })

        const palletizationChanges = getRevisedChanges({
            service,
            revisedService,
            pricing: RfqServicePricing.Palletization,
        })

        const palletOutChanges = getRevisedChanges({
            service,
            revisedService,
            pricing: RfqServicePricing.PalletOut,
        })

        return (
            <div className="flex flex-col gap-3">
                <span className="font-medium leading-5">Floor Loaded</span>
                <div className="flex flex-col">
                    {service.pricings[RfqServicePricing.Unloading]?.price ? (
                        <div className="flex gap-2 items-center w-full">
                            <CurrencyDisplay
                                value={service.pricings[RfqServicePricing.Unloading]!.price}
                                className={cn('text-xl leading-6 text-primary font-semibold', {
                                    'text-success': unloadingChanges?.price?.status === -1,
                                    'text-danger': unloadingChanges?.price?.status === 1,
                                })}
                            />
                            <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                            <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                {order.unitType}
                            </span>
                        </div>
                    ) : (
                        <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                    )}

                    <StandardQuoteRevisedValues changes={unloadingChanges} fields={['price']} />
                </div>

                <Separator />

                <div className="space-y-1">
                    <span className="text-sm leading-[18px] text-foreground-secondary font-medium">
                        {RfqServicePricingLabel[RfqServicePricing.Palletization]}
                    </span>
                    <div className="space-y-3">
                        <div className="flex flex-col">
                            <div className="flex flex-col sm:flex-row gap-0 sm:gap-2 sm:items-center w-full">
                                {service.pricings[RfqServicePricing.Palletization]?.price ? (
                                    <CurrencyDisplay
                                        value={service.pricings[RfqServicePricing.Palletization]!.price}
                                        className={cn('text-xl leading-6 text-primary font-semibold', {
                                            'text-success': palletizationChanges?.price?.status === -1,
                                            'text-danger': palletizationChanges?.price?.status === 1,
                                        })}
                                    />
                                ) : (
                                    <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                                )}
                                <div className="flex gap-1 sm:gap-2 items-center">
                                    <span className="text-xl shrink-0 text-foreground-secondary hidden sm:inline">
                                        /
                                    </span>
                                    <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 sm:hidden">
                                        per
                                    </span>
                                    <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                        Pallet (including materials)
                                    </span>
                                </div>
                            </div>

                            <StandardQuoteRevisedValues
                                changes={palletizationChanges}
                                fields={['price']}
                                className="mt-1 sm:mt-0"
                            />
                        </div>

                        <div className="flex flex-col">
                            <div className="flex gap-2 items-center w-full">
                                {service.pricings[RfqServicePricing.PalletOut]?.price ? (
                                    <CurrencyDisplay
                                        value={service.pricings[RfqServicePricing.PalletOut]!.price}
                                        className={cn('text-xl leading-6 text-primary font-semibold', {
                                            'text-success': palletOutChanges?.price?.status === -1,
                                            'text-danger': palletOutChanges?.price?.status === 1,
                                        })}
                                    />
                                ) : (
                                    <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                                )}
                                <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                                <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                    Pallet Out
                                </span>
                            </div>

                            <StandardQuoteRevisedValues changes={palletOutChanges} fields={['price']} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const changes = getRevisedChanges({
        service,
        revisedService,
        pricing: RfqServicePricing.HandlingTotal,
    })

    return (
        <div className="flex flex-col gap-3">
            <span className="font-medium leading-5">Palletized</span>
            <div className="flex flex-col">
                <div className="text-sm leading-[18px] text-foreground-secondary font-medium flex mb-1">
                    {RfqServicePricingLabel[RfqServicePricing.HandlingTotal]}
                    <HintRoot>
                        <HintTrigger asChild>
                            <div className="ml-1 -mt-[1px]">
                                <FaInfoCircle size={16} />
                            </div>
                        </HintTrigger>
                        <HintContent sideOffset={-3}>
                            <HintArrow /> Combined In and Out Charges per Unit
                        </HintContent>
                    </HintRoot>
                </div>
                {service.pricings[RfqServicePricing.HandlingTotal]?.price ? (
                    <CurrencyDisplay
                        value={service.pricings[RfqServicePricing.HandlingTotal]!.price}
                        className={cn('text-xl leading-6 text-primary font-semibold', {
                            'text-success': changes?.price?.status === -1,
                            'text-danger': changes?.price?.status === 1,
                        })}
                    />
                ) : (
                    <span className="text-sm text-foreground-secondary font-medium">No Price Given</span>
                )}
                <StandardQuoteRevisedValues changes={changes} fields={['price']} />
            </div>
        </div>
    )
}
