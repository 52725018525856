import React, { useCallback } from 'react'
import { Attachment, LoadboardOrder } from '@/entities/loadboard-orders/model'
import { mediaDocumentIcons, mediaImageExtensions } from '@/shared/lib/media'
import { Button } from '@/shared/ui/Button'
import {
    DialogRoot,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogPortal,
    DialogOverlay,
    DialogFloatingClose,
} from '@/shared/ui/Dialog'
import { InDownload2 } from '@/shared/ui/Icons/InDownload2'
import { toast } from 'react-toastify'
import { Loader } from '@/shared/ui/Loader'
import { cn } from '@/shared/utils/cn'
import { ScrollArea } from '@/shared/ui/ScrollArea'

type OrderDetailsFilesProps = {
    order: LoadboardOrder
}

export const OrderDetailsFiles = ({ order }: OrderDetailsFilesProps) => {
    return (
        <div>
            <div className="font-semibold">Uploaded Files</div>
            {order.attachments.length > 0 ? (
                <div className="mt-1 grid grid-cols-2 md:grid-cols-4 gap-3">
                    {order.attachments.map((attachment, index) => {
                        const splittedAttachment = attachment.filename.split('.')
                        const extension = splittedAttachment[splittedAttachment.length - 1].toLowerCase()

                        return mediaImageExtensions.includes(extension) ? (
                            <ImageFile key={index} attachment={attachment} />
                        ) : (
                            <DocumentFile key={index} attachment={attachment} extension={extension} />
                        )
                    })}
                </div>
            ) : null}
        </div>
    )
}

type DocumentFileProps = {
    attachment: Attachment
    extension: string
}

export const DocumentFile = ({ attachment, extension }: DocumentFileProps) => {
    const onDocumentClick = () => {
        window.open(attachment.link, '_blank')
    }

    return (
        <Button
            variant="plain"
            className="border border-border rounded-lg overflow-hidden flex flex-col items-start"
            onClick={onDocumentClick}
        >
            <div className="h-28 w-full flex items-center justify-center">
                <img
                    src={mediaDocumentIcons[extension] as any}
                    alt={attachment.filename}
                    className="h-16 w-auto object-cover rounded-t-lg"
                />
            </div>
            <div className="px-2 py-1 text-[12px]">{attachment.filename}</div>
        </Button>
    )
}

type ImageFileProps = {
    attachment: Attachment
    getSignedURL?: () => Promise<string>
    trigger?: React.ReactNode
}

export const ImageFile = ({ attachment, trigger, getSignedURL }: ImageFileProps) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [signedURL, setSignedURL] = React.useState<string | null>(null)
    const [loading, setLoading] = React.useState(false)
    const onDownloadClick = () => {
        if (signedURL) window.open(signedURL, '_blank')
        if (attachment) window.open(attachment.link, '_blank')
    }

    const setupSignedURL = async () => {
        if (getSignedURL) {
            try {
                setLoading(true)

                setSignedURL(await getSignedURL())
            } catch (error) {
                console.error(error)
                toast('Failed to get URL for image', { type: 'error' })
            }
        }
    }

    const onOpenChange = useCallback(
        async (nowOpen) => {
            if (nowOpen) {
                setupSignedURL()
                setIsOpen(true)
            } else if (!nowOpen) {
                setIsOpen(false)
                setSignedURL(null)
            }
        },
        [getSignedURL],
    )

    return (
        <DialogRoot onOpenChange={onOpenChange} open={isOpen}>
            <DialogTrigger asChild>
                {trigger ?? (
                    <Button
                        variant="plain"
                        className="border border-border rounded-lg overflow-hidden flex flex-col items-start"
                    >
                        <div className="h-28 w-full">
                            <img src={attachment.link} alt={attachment.filename} className="h-full w-full object-cover rounded-t-lg" />
                        </div>
                        <div className="px-2 py-1 text-[12px]">{attachment.filename}</div>
                    </Button>
                )}
            </DialogTrigger>
            <DialogPortal>
                <DialogOverlay>
                    <ScrollArea className="w-full h-full" viewportClassName="py-3 grid place-items-center">
                        <DialogContent className="relative translate-y-0 flex flex-col">
                            <DialogHeader>
                                <DialogTitle className="line-clamp-1">{attachment.filename}</DialogTitle>
                                <DialogFloatingClose />
                            </DialogHeader>
                            {getSignedURL && loading && (
                                <div style={{ height: '50vh' }}>
                                    <Loader className="w-12 h-12 m-2 border-2 border-accent-medium border-t-primary" />
                                </div>
                            )}
                            <img
                                src={signedURL ?? attachment.link}
                                onLoad={() => setLoading(false)}
                                alt={attachment.filename}
                                className={cn(
                                    'h-full w-auto object-cover rounded-lg',
                                    getSignedURL && loading && 'hidden',
                                )}
                            />
                            <DialogFooter>
                                <Button
                                    onClick={onDownloadClick}
                                    disabled={loading}
                                    variant="outlined"
                                    className="gap-2"
                                >
                                    {loading ? 'Please wait...' : 'Download'}
                                    <InDownload2 size={16} />
                                </Button>
                                <DialogClose asChild>
                                    <Button>Close</Button>
                                </DialogClose>
                            </DialogFooter>
                        </DialogContent>
                    </ScrollArea>
                </DialogOverlay>
            </DialogPortal>
        </DialogRoot>
    )
}
