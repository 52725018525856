import React, { useEffect, useMemo } from 'react'
import { useGetRelatedAccountsQuery } from '@/entities/accounts/api'
import { Select, SelectOption } from '@/shared/ui/Select'
import { FilterParams } from '@/pages/LoadBoard/model/types'
import { loadboardActions } from '@/pages/LoadBoard/model/slice'
import { useAppDispatch, useAppSelector } from '@/app/store'

type FilterWarehouseProps = {
    onParamsChanged: (
        params: Partial<FilterParams>,
        options?: {
            invokeImmediately?: boolean
            skipSettingParams?: boolean
        },
    ) => void
}

export const FilterWarehouse = ({ onParamsChanged }: FilterWarehouseProps) => {
    const dispatch = useAppDispatch()
    const isReadyToFetchOrders = useAppSelector((state) => state.loadboard.isReadyToFetchOrders)
    const warehouse = useAppSelector((state) => state.loadboard.filters?.warehouse)

    const { data, error, isFetching } = useGetRelatedAccountsQuery()

    const warehousesOptions = useMemo(() => {
        const result: SelectOption[] = [{ value: 'all', label: 'All' }]
        if (!data) return result
        result.push(...data.map((w) => ({ value: w.id, label: w.name })))
        return result
    }, [data])

    useEffect(() => {
        if (data && warehouse) {
            const selectedWH = data.find((a) => a.id === warehouse)
            if (!selectedWH) {
                onParamsChanged({ warehouse: null }, { invokeImmediately: true })
            }
            if (!isReadyToFetchOrders) dispatch(loadboardActions.setIsReadyForFetching(true))
        }
        if (error) {
            onParamsChanged({ warehouse: null }, { invokeImmediately: true })
            if (!isReadyToFetchOrders) dispatch(loadboardActions.setIsReadyForFetching(true))
        }
    }, [data, error])

    const onSelect = (value: string) => {
        if (value === 'all') {
            onParamsChanged({ warehouse: null })
            return
        }

        const selectedWH = data?.find((w) => w.id === value)
        if (!selectedWH) return
        onParamsChanged({ warehouse: selectedWH.id })
    }

    return (
        <div className="px-3">
            <Select
                label="Warehouse"
                disabled={isFetching}
                options={warehousesOptions}
                value={warehouse ?? 'all'}
                onValueChange={onSelect}
                triggerClassName="h-11 md:h-9 pr-7 [&>span]:text-ellipsis [&>span]:overflow-hidden [&>span]:block"
            />
        </div>
    )
}
