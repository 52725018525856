import React from 'react'
import { Section } from '@/shared/ui/Section'
import { LoadboardCategory, LoadboardOrder } from '@/entities/loadboard-orders/model'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { LoadBoardItemStatus } from '@/pages/LoadBoard/ui/LoadBoardMain/LoadBoardItem'
import { Separator } from '@/shared/ui/Separator'
import { LoadBoardItemSub } from '@/pages/LoadBoard/ui/LoadBoardMain/LoadBoardItem/LoadBoardItemSub'
import { RfqService, serviceIcons } from '@/entities/rfq/model'
import { Badge } from '@/shared/ui/Badge'

type LoadBoardListItemProps = {
    order: LoadboardOrder
    category: LoadboardCategory
}

export const LoadBoardListItem = ({ order, category }: LoadBoardListItemProps) => {
    const services = order.services.split(',')

    return (
        <Link to={`/order/${order.id}`}>
            <Section className="w-full !px-0 !py-0 overflow-hidden">
                <div className="flex justify-between items-center px-3 py-2 border-b">
                    <div>
                        <div className="text-primary font-semibold text-base text-nowrap">{order.id}</div>
                        <LoadBoardItemSub order={order} />
                    </div>
                    <LoadBoardItemStatus order={order} />
                </div>

                <div className="p-3 flex justify-between gap-4">
                    <div className="basis-0 shrink-0 flex-grow flex flex-col gap-3">
                        <div className="flex flex-col">
                            {category === LoadboardCategory.Accepted ? (
                                <>
                                    <span className="text-foreground-secondary text-xs">Warehouse</span>
                                    <div className="text-sm font-medium">{order.warehouseName}</div>
                                </>
                            ) : (
                                <>
                                    <span className="text-foreground-secondary text-xs">Location</span>
                                    <div className="text-sm font-medium">{order.address}</div>
                                </>
                            )}
                        </div>
                        {order.distance !== undefined && (
                            <div className="flex flex-col">
                                <span className="text-foreground-secondary text-xs">Distance</span>
                                <div className="text-sm font-medium">{order.distance} mi</div>
                            </div>
                        )}
                        <div className="flex flex-col gap-1">
                            <span className="text-foreground-secondary text-xs">Services</span>
                            <div className="flex flex-col gap-2">
                                {services.slice(0, 3).map((service, index) => {
                                    const Icon = serviceIcons[service as RfqService]
                                    return (
                                        <Badge
                                            variant="secondary"
                                            key={index}
                                            className="rounded-md text-sm font-medium flex gap-3 items-center"
                                        >
                                            {Icon ? <Icon size={20} className="text-foreground-tertiary" /> : null}
                                            {service}
                                        </Badge>
                                    )
                                })}
                                {services.length - 3 > 0 && (
                                    <div className="text-sm font-medium flex gap-3 items-center">
                                        + {services.length - 3} more
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Separator orientation="vertical" className="shrink-0 h-auto self-stretch" />
                    <div className="basis-0 shrink-0 flex-grow flex flex-col gap-3">
                        <div className="flex flex-col">
                            <span className="text-foreground-secondary text-xs">Start Date</span>
                            <div className="text-sm font-medium">{format(new Date(order.startDate), 'MM/dd/yyyy')}</div>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-foreground-secondary text-xs">End Date</span>
                            <div className="text-sm font-medium">{format(new Date(order.endDate), 'MM/dd/yyyy')}</div>
                        </div>
                        <Separator />
                        <div className="flex flex-col">
                            <span className="text-foreground-secondary text-xs">Unit Type</span>
                            <div className="text-sm font-medium">{order.unitType}</div>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-foreground-secondary text-xs"># of Units</span>
                            <div className="text-sm font-medium">{order.totalUnits}</div>
                        </div>
                    </div>
                </div>
            </Section>
        </Link>
    )
}
