import React from 'react'
import {
    TableRoot,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TablePagination,
    TableRow,
    TablePaginationProps,
} from '@/shared/ui/Table'
import { flexRender, Row, RowData, Table } from '@tanstack/react-table'
import { cn } from '@/shared/utils/cn'
import { InCaretUp } from '@/shared/ui/Icons/InCaretUp'
import { Skeleton } from '@/shared/ui/Skeleton'
import { range } from 'lodash'

/** Use this declaration to type TableMeta and ColumnMeta properties */
declare module '@tanstack/table-core' {
    interface TableMeta<TData extends RowData> {
        // foo: string
    }

    interface ColumnMeta<TData extends RowData, TValue> {
        cellStyle?: any
        cellClass?: any

        cellClassName?: string
        headClassName?: string
    }
}

type DataTableHeaderProps<TData> = React.ComponentPropsWithRef<typeof TableHeader> & {
    table: Table<TData>
}

/** Default DataTable header renderer */
export const DataTableHeader = <TData = any,>({ table, ...props }: DataTableHeaderProps<TData>) => (
    <TableHeader {...props}>
        {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                    const canSort = header.column.getCanSort()

                    return (
                        <TableHead
                            key={header.id}
                            onClick={canSort ? header.column.getToggleSortingHandler() : undefined}
                            className={cn(canSort && 'cursor-pointer', header.column.columnDef.meta?.headClassName)}
                        >
                            {header.isPlaceholder ? null : (
                                <div className="flex gap-1 items-center">
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                    {canSort ? (
                                        <div className="flex flex-col text-foreground-tertiary">
                                            {{
                                                asc: <InCaretUp size={8} className="text-foreground-secondary" />,
                                                desc: (
                                                    <InCaretUp
                                                        size={8}
                                                        className="rotate-180 text-foreground-secondary"
                                                    />
                                                ),
                                                false: (
                                                    <>
                                                        <InCaretUp size={8} />
                                                        <InCaretUp size={8} className="rotate-180" />
                                                    </>
                                                ),
                                            }[String(header.column.getIsSorted())] ?? null}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </TableHead>
                    )
                })}
            </TableRow>
        ))}
    </TableHeader>
)

type DataTableBodyProps<TData> = React.ComponentPropsWithRef<typeof TableBody> & {
    table: Table<TData>
    rowPropsBuilder?: (row: Row<TData>, index: number) => React.ComponentPropsWithRef<typeof TableRow>
    isLoading?: boolean
    pageSize?: number
    renderExpandedRow?: (row: Row<TData>, index: number) => React.ReactNode
}

/** Default DataTable body renderer
 *  Contains Loader and 0 rows placeholder in addition to standard body renderer */
export const DataTableBody = <TData = any,>({
    table,
    isLoading,
    pageSize,
    renderExpandedRow,
    rowPropsBuilder,
    ...props
}: DataTableBodyProps<TData>) => {
    const actualPageSize = pageSize ?? table.getState().pagination.pageSize

    if (isLoading) {
        return (
            <TableBody {...props}>
                {range(0, actualPageSize).map((item) => (
                    <TableRow key={item} className="!bg-background-secondary">
                        <TableCell colSpan={table.getAllColumns().length}>
                            <Skeleton className="h-6 w-full" />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        )
    }

    const rows = table.getRowModel().rows

    if (!rows.length) {
        return (
            <TableBody {...props}>
                <TableRow>
                    <TableCell colSpan={table.getAllColumns().length} className="h-24 text-center">
                        No results.
                    </TableCell>
                </TableRow>
            </TableBody>
        )
    }

    return (
        <TableBody {...props}>
            {table.getRowModel().rows.map((row, index) => (
                <React.Fragment key={row.id}>
                    <TableRow data-state={row.getIsSelected() && 'selected'} {...rowPropsBuilder?.(row, index)}>
                        {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id} className={cell.column.columnDef.meta?.cellClassName}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                        ))}
                    </TableRow>
                    {row.getIsExpanded() ? renderExpandedRow?.(row, index) : null}
                </React.Fragment>
            ))}
        </TableBody>
    )
}

/** Default DataTable footer.
 *  Contains TablePagination, accepts props to control pagination.
 *  Can be replaced with custom TableFooter by using renderFooter prop in DataTable */
type DataTableFooterProps<TData> = React.ComponentPropsWithRef<typeof TableFooter> & {
    table: Table<TData>
    totalItems?: number
    tablePaginationProps?: Partial<TablePaginationProps>
    hideTablePagination?: boolean
    hideItemsCount?: boolean
}

export const DataTableFooter = <TData = any,>({
    table,
    totalItems = 0,
    tablePaginationProps,
    hideTablePagination,
    hideItemsCount,
    ...props
}: DataTableFooterProps<TData>) => {
    const state = table.getState()

    return (
        <TableFooter {...props}>
            <TableRow>
                <TableCell colSpan={table.getAllColumns().length}>
                    <div className="flex justify-between items-center">
                        {!hideItemsCount && totalItems > 0 && (
                            <div className="text-foreground-secondary text-sm font-normal">
                                {totalItems} result{totalItems > 1 && 's'}
                            </div>
                        )}
                        <div />
                        <TablePagination
                            totalPages={Math.ceil(totalItems / state.pagination.pageSize)}
                            pagination={state.pagination}
                            onPaginationChange={table.setPagination}
                            {...tablePaginationProps}
                        />
                    </div>
                </TableCell>
            </TableRow>
        </TableFooter>
    )
}

export type DataTableProps<TData> = {
    table: Table<TData>
    isLoading?: boolean

    rootClassName?: string
    rootProps?: Omit<React.ComponentPropsWithRef<typeof TableRoot>, 'className'>

    headerClassName?: string
    headerProps?: Omit<React.ComponentPropsWithRef<typeof TableHeader>, 'className'>

    bodyClassName?: string
    bodyProps?: Omit<React.ComponentPropsWithRef<typeof TableBody>, 'className'>
    renderExpandedRow?: (row: Row<TData>, index: number) => React.ReactNode

    rowPropsBuilder?: (row: Row<TData>, index: number) => React.ComponentPropsWithRef<typeof TableRow>

    footerClassName?: string
    footerProps?: Omit<React.ComponentPropsWithRef<typeof TableFooter>, 'className'>
    renderFooter?: () => React.ReactNode

    totalItems?: number
    tablePaginationProps?: Partial<TablePaginationProps>
    hideTablePagination?: boolean
    hideItemsCount?: boolean
}

export const DataTable = <TData = any,>({
    table,
    isLoading,

    rootClassName,
    rootProps,

    headerClassName,
    headerProps,

    bodyClassName,
    bodyProps,
    renderExpandedRow,

    rowPropsBuilder,

    footerClassName,
    footerProps,
    renderFooter,

    totalItems,
    tablePaginationProps,
    hideTablePagination,
    hideItemsCount,
}: DataTableProps<TData>) => {
    const state = table.getState()
    const pageSize = tablePaginationProps?.pagination?.pageSize ?? state.pagination.pageSize

    return (
        <TableRoot className={rootClassName} {...rootProps}>
            <DataTableHeader table={table} className={headerClassName} {...headerProps} />
            <DataTableBody
                table={table}
                isLoading={isLoading}
                renderExpandedRow={renderExpandedRow}
                pageSize={pageSize}
                className={bodyClassName}
                rowPropsBuilder={rowPropsBuilder}
                {...bodyProps}
            />
            {renderFooter ? (
                renderFooter()
            ) : (
                <DataTableFooter
                    table={table}
                    totalItems={totalItems}
                    tablePaginationProps={tablePaginationProps}
                    hideTablePagination={hideTablePagination}
                    hideItemsCount={hideItemsCount}
                    className={footerClassName}
                    {...footerProps}
                />
            )}
        </TableRoot>
    )
}
