import React from 'react'
import { DatePicker } from '@/shared/ui/DatePicker'
import { DateRange } from 'react-day-picker'
import { useAppSelector } from '@/app/store'
import { FilterParams } from '@/pages/LoadBoard/model/types'
import { format, parse } from 'date-fns'
import { InClose } from '@/shared/ui/Icons/InClose'
import { Button } from '@/shared/ui/Button'

const getSelectedDates = (startDate: string | null, endDate: string | null) => {
    try {
        if (!startDate) return undefined
        if (!endDate) return { from: parse(startDate, 'MM/dd/yyyy', new Date()), to: undefined }
        return { from: parse(startDate, 'MM/dd/yyyy', new Date()), to: parse(endDate, 'MM/dd/yyyy', new Date()) }
    } catch (e) {
        return undefined
    }
}

type FilterDatesProps = {
    onParamsChanged: (params: Partial<FilterParams>) => void
}

export const FilterDates = ({ onParamsChanged }: FilterDatesProps) => {
    const startDate = useAppSelector((state) => state.loadboard.filters!.startDate)
    const endDate = useAppSelector((state) => state.loadboard.filters!.endDate)

    const onDatesSelect = (dates: DateRange | undefined) => {
        if (!dates || !dates.from) {
            onParamsChanged({ startDate: null, endDate: null })
            return
        }

        const sd = dates?.from ? format(dates.from, 'MM/dd/yyyy') : null
        const ed = dates?.to ? format(dates.to, 'MM/dd/yyyy') : null

        if (!sd) {
            onParamsChanged({ startDate: null, endDate: null })
            return
        }
        if (!ed) {
            onParamsChanged({ startDate: sd, endDate: null })
            return
        }
        onParamsChanged({ startDate: sd, endDate: ed })
    }

    const selected = getSelectedDates(startDate, endDate)

    return (
        <div className="px-3">
            <DatePicker
                modal
                mode="range"
                selected={selected}
                onSelect={onDatesSelect}
                label="Start Date(s)"
                iconWrapperClassName="text-foreground-tertiary"
                triggerClassName="h-11 md:h-9"
                icon={
                    selected?.to || selected?.from ? (
                        <Button
                            variant="ghost"
                            className="text-foreground-tertiary rounded-l-none"
                            onClick={() => onParamsChanged({ startDate: null, endDate: null })}
                        >
                            <InClose size={12} />
                        </Button>
                    ) : undefined
                }
            />
        </div>
    )
}
