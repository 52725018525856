import { HttpV2 } from '@shared/apiv2/api'
import { Account, UploadFileRequest } from '../model'
import { AxiosRequestConfig } from 'axios'
import { api } from '@/shared/api/rtk-query'

const getRelatedAccounts = async (config?: AxiosRequestConfig) => {
    const { data } = await HttpV2.get<Account[]>('accounts/related', config)
    return data
}

const getWarehouse = async (accountId: string, config?: AxiosRequestConfig) => {
    const { data } = await HttpV2.get<Account>(`accounts/${accountId}/warehouse`, config)
    return data
}

const uploadFile = async (params: UploadFileRequest, config?: AxiosRequestConfig) => {
    const response = await HttpV2.post(
        `/accounts/${params.accountId}/documents/upload/${params.documentType}`,
        params.formData,
        {
            ...config,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...config?.headers,
            },
        },
    )
    return response
}

const loadboardRtkApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getRelatedAccounts: builder.query<Account[], void>({
            query: () => ({ url: '/accounts/related' }),
        }),
    }),
})

export const { useGetRelatedAccountsQuery } = loadboardRtkApi

export const accountsApi = {
    getRelatedAccounts,
    getWarehouse,
    uploadFile,
}
