import React from 'react'
import { RfqService, serviceIcons } from '@/entities/rfq/model'
import { HintArrow, HintContent, HintRoot, HintTrigger } from '@/shared/ui/Hint'

type LoadBoardItemServicesProps = {
    services: string[]
}

export const LoadBoardItemServices = ({ services }: LoadBoardItemServicesProps) => {
    return (
        <div className="flex gap-1.5 items-center text-foreground-secondary">
            {services.length > 3 ? (
                <>
                    {services.slice(0, 3).map((item, index) => {
                        const Icon = serviceIcons[item as RfqService]
                        return Icon ? (
                            <HintRoot key={index}>
                                <HintTrigger asChild>
                                    <div className="cursor-pointer">
                                        <Icon size={16} />
                                    </div>
                                </HintTrigger>
                                <HintContent sideOffset={-4}>
                                    {item}
                                    <HintArrow />
                                </HintContent>
                            </HintRoot>
                        ) : null
                    })}
                    <HintRoot>
                        <HintTrigger asChild>
                            <div className="cursor-pointer mt-0.5">+{services.length - 3}</div>
                        </HintTrigger>
                        <HintContent sideOffset={-4}>
                            {services.slice(3).join(', ')}
                            <HintArrow />
                        </HintContent>
                    </HintRoot>
                </>
            ) : (
                services.map((item, index) => {
                    const Icon = serviceIcons[item as RfqService]
                    return Icon ? (
                        <HintRoot key={index}>
                            <HintTrigger asChild>
                                <div className="cursor-pointer">
                                    <Icon size={16} />
                                </div>
                            </HintTrigger>
                            <HintContent sideOffset={-4}>
                                {item}
                                <HintArrow />
                            </HintContent>
                        </HintRoot>
                    ) : null
                })
            )}
        </div>
    )
}
