import React, { FC, memo, useState, useEffect, useMemo } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import { Toggle } from '@/shared/ui/Toggle'
import { Switch } from '@/shared/ui/Switch'
import { Label } from '@/shared/ui/Label'
import { useFormik } from 'formik'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import CancelDeleteButtons from '@/components/CancelDeleteButtons'

import './NotificationSettingsModal.scss'

const NotificationSettingsModalId = 50

interface ChangeNotificationSettingsForm {
    isMessages: boolean
    isOrders: boolean
    isLoadBoard: boolean
    isApp: boolean
    isSms: boolean
    isEmail: boolean
}

interface ChangeNotificationSettingsModalProps {}

function transformToObject(array: any, isSupplyAccount: boolean) {
    const result = {
        isMessages: false,
        isOrders: false,
        isLoadBoard: false,
        isApp: false,
        isSms: false,
        isEmail: false,
    };

    if(!isSupplyAccount) {
        array = array.filter((item: any) => item.typeKey !== 'load-board')
    }

    array.forEach((item: any) => {
        if (item.enabled) {
            switch (item.typeKey) {
                case 'chat':
                    result.isMessages = true;
                    break;
                case 'order':
                    result.isOrders = true;
                    break;
                case 'load-board':
                    result.isLoadBoard = true;
                    break;
            }

            switch (item.channelKey) {
                case 'in-app':
                    result.isApp = true;
                    break;
                case 'email':
                    result.isEmail = true;
                    break;
                case 'sms':
                    result.isSms = true;
                    break;
            }
        }
    });

    return result;
}

function transformToArray(obj:any, isSupplyAccount: boolean) {
    const array = [];

    array.push({ typeKey: 'chat', channelKey: 'in-app', enabled: obj.isApp && obj.isMessages });
    array.push({ typeKey: 'chat', channelKey: 'email', enabled: obj.isEmail && obj.isMessages  });
    array.push({ typeKey: 'chat', channelKey: 'sms', enabled: obj.isSms && obj.isMessages  });

    array.push({ typeKey: 'order', channelKey: 'in-app', enabled: obj.isApp && obj.isOrders });
    array.push({ typeKey: 'order', channelKey: 'email', enabled: obj.isEmail && obj.isOrders });
    array.push({ typeKey: 'order', channelKey: 'sms', enabled: obj.isSms && obj.isOrders });

    if (isSupplyAccount) {
        array.push({ typeKey: 'load-board', channelKey: 'in-app', enabled: obj.isApp && obj.isLoadBoard  });
        array.push({ typeKey: 'load-board', channelKey: 'email', enabled: obj.isEmail && obj.isLoadBoard  });
        array.push({ typeKey: 'load-board', channelKey: 'sms', enabled: obj.isSms && obj.isLoadBoard  });
    }

    return array;
}

const NotificationSettingsModal: FC<ChangeNotificationSettingsModalProps> = () => {
    const { state: userState } = useUserState()
    const isSupplyAccount = userState.userInfo?.role === UserRole.WAREHOUSE_MANAGER
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const formik = useFormik<ChangeNotificationSettingsForm>({
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true,
        initialValues: {
            isMessages: false,
            isOrders: false,
            isLoadBoard: false,
            isApp: false,
            isSms: false,
            isEmail: false,
        },
        onSubmit: async (values) => {
            try {
                formik.setSubmitting(true)
                setErrorMessage('')
                // PUT request to save notification preferences
                await HttpV2.put('/notifications/preferences', {
                    preferences: transformToArray(values, isSupplyAccount)
                })

                ModalRegistry.get().close(NotificationSettingsModalId)
            } catch (error: any) {
                const message = error.response?.data?.message
                setErrorMessage(message)
            } finally {
                formik.setSubmitting(false)
            }
        },
    })

    // Fetch notification settings when the component is mounted
    useEffect(() => {
        const fetchNotificationSettings = async () => {
            try {
                setIsLoading(true)
                // Fetch notification settings from the server for the user
                const response = await HttpV2.get('/notifications/preferences')
                const data = response.data.filter((item:any) => item.typeKey !== 'system')

                // Update form values with fetched data
                if (data.length > 0) {
                    formik.setValues(transformToObject(data, isSupplyAccount))
                }
            } catch (error: any) {
                const message = error.response?.data?.message
                setErrorMessage(message)
            } finally {
                setIsLoading(false)
            }
        }

        fetchNotificationSettings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="modal-notification-settings">
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <form onSubmit={formik.handleSubmit} className="notification-settings-form">
                    <div className="">
                        <div className="text-sm">Select applicable:</div>

                        <div className="flex items-center gap-2 mt-1">
                            <Toggle
                                variant="outlined"
                                className="w-[140px] bg-background-tertiary"
                                name="isMessages"
                                defaultPressed={formik.values.isMessages}
                                onPressedChange={(pressed) => formik.setFieldValue('isMessages', pressed)}
                            >
                                Messages
                            </Toggle>
                            <Toggle
                                variant="outlined"
                                className="w-[140px] bg-background-tertiary"
                                name="IsOrders"
                                defaultPressed={formik.values.isOrders}
                                onPressedChange={(pressed) => formik.setFieldValue('isOrders', pressed)}
                            >
                                Orders
                            </Toggle>
                            {isSupplyAccount ? (
                                <Toggle
                                    variant="outlined"
                                    className="w-[140px] bg-background-tertiary"
                                    name="IsLoadBoard"
                                    defaultPressed={formik.values.isLoadBoard}
                                    onPressedChange={(pressed) => formik.setFieldValue('isLoadBoard', pressed)}
                                >
                                    Load Board
                                </Toggle>
                            ) : null}
                        </div>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Switch
                            id="in-app-notifications"
                            name="chanel"
                            defaultChecked={formik.values.isApp}
                            checked={formik.values.isApp}
                            onCheckedChange={() => formik.setFieldValue('isApp', !formik.values.isApp)}
                        />
                        <Label htmlFor="email-notifications" className="font-normal text-sm">
                            In-App Notifications
                        </Label>
                    </div>

                    <div className="flex items-center space-x-2">
                        <Switch
                            id="email-notifications"
                            name="chanel"
                            defaultChecked={formik.values.isEmail}
                            checked={formik.values.isEmail}
                            onCheckedChange={() => formik.setFieldValue('isEmail', !formik.values.isEmail)}
                        />
                        <Label htmlFor="email-notifications" className="font-normal text-sm">
                            Email Notifications
                        </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Switch
                            id="sms-notifications"
                            name="chanel"
                            defaultChecked={formik.values.isSms}
                            checked={formik.values.isSms}
                            onCheckedChange={() => formik.setFieldValue('isSms', !formik.values.isSms)}
                        />
                        <Label htmlFor="sms-notifications" className="font-normal text-sm">
                            SMS Notifications
                        </Label>
                    </div>

                    <CancelDeleteButtons
                        handleCancel={() => ModalRegistry.get().close(NotificationSettingsModalId)}
                        isSubmitting={formik.isSubmitting}
                        disableSubmit={!formik.isValid}
                        textSubmit="Save"
                        stylesSubmit={{ width: '80px' }}
                        buttonsPosition="right"
                        errorMessage={errorMessage}
                    />
                </form>
            )}
        </div>
    )
}

ModalRegistry.get().register<ChangeNotificationSettingsModalProps>(NotificationSettingsModalId, {
    id: 'ChangeNotificationSettingsModalProps',
    className: 'modal-notification-settings',
    size: 'medium',
    Component: memo(NotificationSettingsModal),
    title: 'Notification Settings',
})

export const showNotificationSettingsModal = (props: ModalPartialProps<ChangeNotificationSettingsModalProps>): void =>
    ModalRegistry.get().show<ChangeNotificationSettingsModalProps>(NotificationSettingsModalId, props)

export default memo(NotificationSettingsModal)
