import React, { FC, useCallback, useEffect, useState } from 'react'
import { Section } from '@/shared/ui/Section'
import { sendQuoteData, useQuoteData } from '@/pages/PSM/Bid/api/bid.api'
import { useParams } from 'react-router-dom'
import { RfqService, RfqServicePricing, RfqServicePricingLabel } from '@/entities/rfq/model'
import Currency from '@/components/Currency'
import { QuoteServiceDataDto } from '@/pages/PSM/Bid/model/response'
import { cn } from '@/shared/utils/cn'
import { LbPostBidType } from '@/pages/PSM/Bid/model/enums'
import { Textarea } from '@/shared/ui/Textarea'
import { QuoteRequestDto } from '@/pages/PSM/Bid/model/request'
import { CurrencyInput } from '@/shared/ui/CurrencyInput'
import { Slider } from '@/shared/ui/Slider'
import { toast } from 'react-toastify'
import { ScrollArea } from '@/shared/ui/ScrollArea'
import { Button } from '@/shared/ui/Button'
import { Loader } from '@/shared/ui/Loader'
import { InClose } from '@/shared/ui/Icons/InClose'

const getExtraServiceLabel = (name: RfqService, type: QuoteServiceDataDto['type']) => {
    if (name === RfqService.CrossDocking || name === RfqService.Transloading) {
        return type === 'allIn' ? 'Floor Loaded' : 'Palletized'
    }
    return ''
}

const getPricingLabel = (
    name: RfqService,
    priceName: RfqServicePricing,
    type: QuoteServiceDataDto['type'],
    modifier: string,
    unitType: string,
) => {
    if (name === RfqService.CrossDocking || name === RfqService.Transloading) {
        if (type === 'unit') {
            return RfqServicePricingLabel[priceName]
        }
        return ''
    }
    if (name === RfqService.Handling) {
        return RfqServicePricingLabel[priceName]
    }
    if (
        name === RfqService.Rework ||
        priceName === RfqServicePricing.LineHaul ||
        priceName === RfqServicePricing.Chassis
    ) {
        return '/ Pallet (including materials)'
    }
    if (type === 'unit' || type === 'sqft') {
        return `/ ${type === 'sqft' ? 'Sqft' : unitType}` + (modifier ? ` / ${modifier}` : '')
    }
    if (type === null && modifier === 'pallet') {
        return '/ Pallet - Standard'
    }
    if (name === RfqService.YardStorage) {
        return '/ Spot' + (modifier ? ` / ${modifier}` : '')
    }
    return ''
}
const getExtraPricingLabel = (
    name: RfqService,
    priceName: RfqServicePricing,
    type: QuoteServiceDataDto['type'],
    unitType: string,
) => {
    if (name === RfqService.Transloading) {
        if (priceName === RfqServicePricing.Unloading || priceName === RfqServicePricing.Palletization) {
            return '/ Pallet (including materials)'
        }
        if (priceName === RfqServicePricing.PalletOut) {
            return '/ Pallet Out'
        }
        return `/ ${unitType}`
    }
    if (name === RfqService.CrossDocking) {
        if (type === 'allIn') {
            return '/ Pallet'
        }
        return `/ ${unitType}`
    }
    return ''
}

function calculateSellToNearestHalf(buyPrice: number, marginPercentage: number) {
    const sellCostWithMargin = buyPrice / ((100 - marginPercentage) / 100)

    return Math.round(sellCostWithMargin * 2) / 2
}

function getUpperLabel(name: RfqService, priceName: RfqServicePricing) {
    if (name === RfqService.Drayage || name === RfqService.FinalMile) {
        return RfqServicePricingLabel[priceName]
    }
}

export const QuoteCustomerForm: FC = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [isValid, setIsValid] = useState<boolean>(true)
    const [marginPercentage, setMarginPercentage] = useState<number>(35)
    const [quoteRequest, setQuoteRequest] = useState<QuoteRequestDto | null>(null)
    const { key: bidId } = useParams<{ key: string }>()
    const { data, error, isLoading } = useQuoteData(bidId)

    const updateValid = useCallback(() => {
        if (data && quoteRequest) {
            // check all prices are greater than buy prices (except 0 buy prices)
            const valid = !quoteRequest.prices.some((price) => {
                const pricing = data.services.flatMap((service) => service.prices).find((p) => p.id === price.id)
                return pricing && price.sellPrice < pricing.buyPrice && pricing.buyPrice !== 0
            })
            setIsValid(valid)
            return valid
        }
    }, [quoteRequest, data])

    const handleCancel = useCallback(() => {
        const msg = {
            action: 'cancel',
        }
        if (window.parent) {
            window.parent.postMessage(msg, '*')
        }
    }, [])
    const handleSuccess = useCallback((message: string) => {
        const msg = {
            action: 'success',
            message,
        }
        if (window.parent) {
            window.parent.postMessage(msg, '*')
        }
    }, [])
    const handleError = useCallback((message: string) => {
        const msg = {
            action: 'error',
            message,
        }
        if (window.parent) {
            window.parent.postMessage(msg, '*')
        }
    }, [])

    const handleSubmit = useCallback(async () => {
        if (quoteRequest) {
            const valid = updateValid()
            // check all prices are greater than buy prices (except 0 buy prices)
            if (!valid) {
                toast('Sell prices must be greater than buy prices', {
                    type: 'error',
                })
                return
            }
            setLoading(true)
            try {
                await sendQuoteData(bidId!, quoteRequest)
                // toast('Quote sent successfully', {
                //     type: 'success',
                // })
                setLoading(false)
                handleSuccess('Quote sent successfully')
            } catch (e: any) {
                const errorMessage = e?.response?.data?.message ?? e.message ?? 'Failed to send quote: unknown error'

                setLoading(false)
                console.error(e)
                handleError(errorMessage)
            }
        }
    }, [bidId, handleError, handleSuccess, quoteRequest, updateValid])

    const handleMarginChange = useCallback(
        (value: number) => {
            if (!data) return
            setQuoteRequest({
                ...quoteRequest,
                minimumSell: data.minimumBuy
                    ? data.minimumBuy
                        ? calculateSellToNearestHalf(data.minimumBuy, value)
                        : 0
                    : 0,
                prices: data.services.reduce((acc, service) => {
                    return [
                        ...acc,
                        ...service.prices.map((price) => {
                            return {
                                id: price.id,
                                sellPrice: calculateSellToNearestHalf(price.buyPrice, value),
                            }
                        }),
                    ]
                }, [] as QuoteRequestDto['prices']),
            })
        },
        [data, quoteRequest],
    )

    useEffect(() => {
        if (error) {
            const errorMessage = 'Failed to load quote data: ' + (error?.response?.data?.message ?? 'unknown error')
            if (window.parent !== window) {
                handleError(errorMessage)
            } else {
                toast(errorMessage, {
                    type: 'error',
                })
            }
        }
    }, [error])

    useEffect(() => {
        if (data && !quoteRequest) {
            setQuoteRequest({
                customerNotes: data.customerNotes ?? '',
                minimumSell:
                    data.minimumSell ??
                    (data.minimumBuy ? calculateSellToNearestHalf(data.minimumBuy, marginPercentage) : 0),
                prices: data.services.reduce((acc, service) => {
                    return [
                        ...acc,
                        ...service.prices.map((price) => {
                            return {
                                id: price.id,
                                sellPrice:
                                    price.sellPrice ?? calculateSellToNearestHalf(price.buyPrice, marginPercentage),
                            }
                        }),
                    ]
                }, [] as QuoteRequestDto['prices']),
            })
        }
    }, [data, marginPercentage, quoteRequest, updateValid])

    useEffect(() => {
        updateValid()
    }, [quoteRequest])

    if (isLoading || !data || !quoteRequest) {
        return <div>Loading...</div>
    }

    const allIn = data.type === LbPostBidType.ALLIN
    return (
        <div className="flex flex-col flex-grow h-full w-full bg-white pt-[16px] pb-[16px] px-[20px]">
            <h3 className="text-[24px] font-[600] flex justify-between relative">
                Quote Customer
                <Button onClick={handleCancel} className="absolute right-0 top-0" variant="plain">
                    <InClose className="text-foreground-tertiary" size={12} />
                </Button>
            </h3>

            <div className="flex-col p-0 text-[14px] rounded-[6px] text-primary">
                {allIn ? 'All-In' : 'Standard Quote'}
            </div>

            <div className={cn('mt-2 py-2 mb-2')}>
                <label className="text-foreground-tertiary text-[14px] mb-2 flex">Margin</label>

                <Slider
                    trackClassName="h-[10px]"
                    value={[marginPercentage]}
                    disabled={loading}
                    thumbPropsBuilder={(val) => ({
                        children: <div className="flex items-center justify-center pt-5">{val}%</div>,
                    })}
                    onValueChange={([val]) => setMarginPercentage(val)}
                    onValueCommit={([val]) => handleMarginChange(val)}
                ></Slider>
            </div>

            {/*3 columns horisontally*/}
            <div className="flex flex-row mt-[20px] px-[16px] font-[500] text-base text-foreground-tertiary">
                <div className="flex flex-col gap-[20px] w-[33%]">Service</div>
                <div className="flex flex-col gap-[20px] w-[33%]">Buy Cost</div>
                <div className="flex flex-col gap-[20px] w-[33%]">Sell Cost</div>
            </div>

            <div className="flex-grow flex-1">
                <Section className="flex flex-col gap-2 border-[1px] shadow-none mt-3 !p-[16px]">
                    <ScrollArea
                        className="max-h-[calc(100vh-300px)] pr-3"
                        scrollbarProps={{ forceMount: true }}
                        viewportClassName="max-h-[calc(100vh-300px)] pb-2"
                    >
                        {data.services.map((service, serviceIdx) => {
                            return service.prices.map((price, priceIdx) => {
                                const value = quoteRequest.prices.find((p) => p.id === price.id)
                                return (
                                    <div
                                        className={cn('flex mt-2', {
                                            'mt-6': priceIdx === 0,
                                            'border-t-[1px] border-gray-200 pt-6': priceIdx === 0 && serviceIdx !== 0,
                                        })}
                                        key={String(service.id) + String(price.id)}
                                    >
                                        <div className="w-[33%]">
                                            {priceIdx === 0 && (
                                                <div className="whitespace-break-spaces max-w-[150px] font-bold text-base">
                                                    {service.name} {getExtraServiceLabel(service.name, service.type)}
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-[33%] flex flex-col">
                                            <div className="whitespace-nowrap text-foreground-tertiary text-xs">
                                                {getUpperLabel(service.name, price.name)}
                                            </div>
                                            <div className="whitespace-nowrap text-primary font-medium text-xl">
                                                <Currency value={price.buyPrice} />
                                            </div>
                                            <div className="whitespace-nowrap text-foreground-tertiary text-xs">
                                                {getPricingLabel(
                                                    service.name,
                                                    price.name,
                                                    service.type,
                                                    price.modifier,
                                                    data.unitType,
                                                )}{' '}
                                                {getExtraPricingLabel(
                                                    service.name,
                                                    price.name,
                                                    service.type,
                                                    data.unitType,
                                                )}
                                            </div>
                                        </div>
                                        <div className="w-[33%] flex items-center">
                                            <CurrencyInput
                                                onValueChange={({ value: newVal }) => {
                                                    setQuoteRequest({
                                                        ...quoteRequest,
                                                        prices: quoteRequest.prices.map((p) => {
                                                            if (p.id === price.id) {
                                                                return {
                                                                    ...p,
                                                                    sellPrice: Number(newVal),
                                                                }
                                                            }
                                                            return p
                                                        }),
                                                    })
                                                }}
                                                allowNegative={false}
                                                decimalScale={2}
                                                prefix="$"
                                                label="Sell Cost"
                                                disabled={loading}
                                                error={
                                                    price.buyPrice && (value?.sellPrice ?? 0) < price.buyPrice
                                                        ? 'Sell cost must be greater than buy cost'
                                                        : undefined
                                                }
                                                value={value?.sellPrice ?? 0}
                                            ></CurrencyInput>
                                        </div>
                                    </div>
                                )
                            })
                        })}
                        <div
                            className={cn('flex border-t-[1px] border-gray-200 pt-6 pb-4 mt-4', {
                                'border-none pt-2': allIn,
                            })}
                        >
                            <div className="w-[33%]">
                                <div className="whitespace-break-spaces max-w-[150px] font-bold text-base">
                                    {allIn ? 'All-In Charge' : 'Minimum Charge'}
                                </div>
                            </div>
                            <div className="w-[33%] flex flex-col">
                                <div className="whitespace-nowrap text-foreground-tertiary text-xs"></div>
                                <div className="whitespace-nowrap text-primary font-medium text-xl">
                                    <Currency value={data.minimumBuy ?? 0} />
                                </div>
                                <div className="whitespace-nowrap text-foreground-tertiary text-xs"></div>
                            </div>
                            <div className="w-[33%] flex items-center">
                                <CurrencyInput
                                    onValueChange={({ value: newVal }) => {
                                        setQuoteRequest({
                                            ...quoteRequest,
                                            minimumSell: Number(newVal),
                                        })
                                    }}
                                    allowNegative={false}
                                    decimalScale={2}
                                    prefix="$"
                                    disabled={loading}
                                    label="Sell Cost"
                                    error={
                                        data.minimumBuy && (quoteRequest?.minimumSell ?? 0) < data.minimumBuy
                                            ? 'Sell cost must be greater than buy cost'
                                            : undefined
                                    }
                                    value={quoteRequest.minimumSell ?? 0}
                                ></CurrencyInput>
                            </div>
                        </div>

                        <div className={cn('flex border-t-[1px] border-gray-200 pt-6')}>
                            <div className="w-[33%]">
                                <div className="whitespace-break-spaces max-w-[150px] font-bold text-base">Quote</div>
                            </div>
                            <div className="w-[33%] flex flex-col">
                                <div className="whitespace-nowrap text-foreground-tertiary text-xs">
                                    Warehouse Quote
                                </div>
                                <div className="whitespace-break-spaces text-foreground-secondary font-medium text-base">
                                    {data.notes}
                                </div>
                                <div className="whitespace-nowrap text-foreground-tertiary text-xs"></div>
                            </div>
                            <div className="w-[33%] flex items-center">
                                <Textarea
                                    placeholder="Customer Quote"
                                    disabled={loading}
                                    value={quoteRequest.customerNotes}
                                    onChange={(e) => {
                                        setQuoteRequest({
                                            ...quoteRequest,
                                            customerNotes: e.target.value,
                                        })
                                    }}
                                ></Textarea>
                            </div>
                        </div>
                    </ScrollArea>
                </Section>
            </div>
            <div className="flex justify-end">
                <Button className="mr-2" variant="outlined" onClick={handleCancel} disabled={!window.parent || loading}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={!isValid || loading}>
                    {loading ? (
                        <>
                            <Loader className="mr-2 w-4 h-4 border-2 border-white border-t-primary " /> Please wait...
                        </>
                    ) : (
                        'Send Quote'
                    )}
                </Button>
            </div>
        </div>
    )
}
