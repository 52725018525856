import * as React from 'react'

import { cn } from '@/shared/utils/cn'
import {
    PaginationContent,
    PaginationItem,
    PaginationJumpFirst,
    PaginationJumpLast,
    PaginationLimit,
    PaginationNext,
    PaginationPrevious,
    PaginationRoot,
} from '@/shared/ui/Pagination'
import { SelectOption } from '@/shared/ui/Select'
import { Separator } from '@/shared/ui/Separator'
import { PaginationState } from '@tanstack/table-core/build/lib/features/Pagination'

const TableRoot = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
    ({ className, ...props }, ref) => (
        <table ref={ref} className={cn('w-full caption-bottom text-sm', className)} {...props} />
    ),
)
TableRoot.displayName = 'TableRoot'

const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
    ({ className, ...props }, ref) => (
        <thead
            ref={ref}
            className={cn('[&_tr]:border-b border-border bg-accent-medium text-xs font-normal', className)}
            {...props}
        />
    ),
)
TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
    ({ className, ...props }, ref) => (
        <tbody
            ref={ref}
            className={cn('[&_tr:last-child]:border-0 [&>tr:hover]:bg-accent-light/30', className)}
            {...props}
        />
    ),
)
TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
    ({ className, ...props }, ref) => (
        <tfoot ref={ref} className={cn('border-t font-medium w-full [&>tr]:last:border-b-0', className)} {...props} />
    ),
)
TableFooter.displayName = 'TableFooter'

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
    ({ className, ...props }, ref) => (
        <tr ref={ref} className={cn('border-b transition-colors bg-background-secondary', className)} {...props} />
    ),
)
TableRow.displayName = 'TableRow'

const TableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(
    ({ className, ...props }, ref) => (
        <th
            ref={ref}
            className={cn(
                'h-10 px-5 text-left align-middle font-medium bg-accent-medium [&:has([role=checkbox])]:pr-0',
                className,
            )}
            {...props}
        />
    ),
)
TableHead.displayName = 'TableHead'

const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
    ({ className, ...props }, ref) => (
        <td ref={ref} className={cn('px-5 h-14 align-middle [&:has([role=checkbox])]:pr-0', className)} {...props} />
    ),
)
TableCell.displayName = 'TableCell'

const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
    ({ className, ...props }, ref) => (
        <caption ref={ref} className={cn('mt-4 text-sm', className)} {...props} />
    ),
)
TableCaption.displayName = 'TableCaption'

export type TablePaginationProps = {
    pagination: PaginationState
    onPaginationChange: (newPagination: PaginationState) => void
    totalPages: number

    pageSizeSelectOptions?: SelectOption[]
    shouldResetPageOnSizeChange?: boolean

    hidePageSizeSelect?: boolean
    hidePageCount?: boolean

    disabled?: boolean
    disablePreviousPage?: boolean
    disableNextPage?: boolean
    disablePageSizeSelect?: boolean
}

const TablePagination = ({
    pagination,
    onPaginationChange,
    totalPages,
    pageSizeSelectOptions,

    shouldResetPageOnSizeChange = true,

    hidePageSizeSelect,
    hidePageCount,

    disabled,
    disablePreviousPage,
    disableNextPage,
    disablePageSizeSelect,
}: TablePaginationProps) => {
    const onPageChange = (newPageIndex: number) => {
        onPaginationChange({
            pageSize: pagination.pageSize,
            pageIndex: newPageIndex,
        })
    }

    const onPageSizeChange = (newPageSize: number) => {
        onPaginationChange({
            pageSize: newPageSize,
            pageIndex: shouldResetPageOnSizeChange ? 0 : pagination.pageIndex,
        })
    }

    return (
        <PaginationRoot className="flex items-center w-fit">
            <div className="flex items-center gap-6">
                {!hidePageSizeSelect && (
                    <>
                        <div className="flex items-center gap-3 shrink-0">
                            <p className="text-sm font-medium shrink-0">Rows per page</p>
                            <PaginationLimit
                                options={pageSizeSelectOptions}
                                value={pagination.pageSize.toString()}
                                onValueChange={(v) => onPageSizeChange(Number(v))}
                                disabled={disabled || disablePageSizeSelect}
                                triggerClassName="h-8"
                            />
                        </div>
                        <Separator orientation="vertical" className="h-auto self-stretch" />
                    </>
                )}
                {!hidePageCount && (
                    <>
                        <div className="flex items-center justify-center text-sm font-medium">
                            Page {pagination.pageIndex + 1} of {totalPages}
                        </div>
                        <Separator orientation="vertical" className="h-auto self-stretch" />
                    </>
                )}
                <div className="flex items-center space-x-2">
                    <PaginationContent>
                        <PaginationItem>
                            <PaginationJumpFirst
                                disabled={disabled || disablePreviousPage}
                                onClick={() => {
                                    if (pagination.pageIndex === 0) return
                                    onPageChange(0)
                                }}
                                className="h-8 w-8"
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationPrevious
                                disabled={disabled || disablePreviousPage}
                                onClick={() => {
                                    if (pagination.pageIndex === 0) return
                                    onPageChange(pagination.pageIndex - 1)
                                }}
                                className="h-8 w-8"
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationNext
                                disabled={disabled || disableNextPage}
                                onClick={() => {
                                    if (pagination.pageIndex === totalPages - 1) return
                                    onPageChange(pagination.pageIndex + 1)
                                }}
                                className="h-8 w-8"
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationJumpLast
                                disabled={disabled || disableNextPage}
                                onClick={() => {
                                    if (pagination.pageIndex === totalPages - 1) return
                                    onPageChange(totalPages - 1)
                                }}
                                className="h-8 w-8"
                            />
                        </PaginationItem>
                    </PaginationContent>
                </div>
            </div>
        </PaginationRoot>
    )
}
TablePagination.displayName = 'TablePagination'

export { TableRoot, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption, TablePagination }
