import React, { FC, useEffect, useRef, useCallback, useState, forwardRef, useImperativeHandle } from 'react'
import intlTelInput from 'intl-tel-input'
import Inputmask from 'inputmask'
import cn from 'classnames'

export interface ResultPhoneProps {
    value: string
    valid: boolean
    errorCode: number
    errorMessage: string
    countryCode: string
}

interface InputPhoneProps extends React.InputHTMLAttributes<HTMLInputElement> {
    initialValue?: string
    handleChange: (resultPhone: ResultPhoneProps) => void
    label?: string
    errorMessage?: string | null
    wrapperClassName?: string
}

export interface InputPhoneHandle {
    reset: () => void
}

const InputPhone = forwardRef<InputPhoneHandle, InputPhoneProps>(
    ({ initialValue = '', handleChange, label = 'Phone#', errorMessage, wrapperClassName, ...rest }, ref) => {
        const inputRef = useRef<HTMLInputElement>(null)
        const itiRef = useRef<any>(null)

        // Using useImperativeHandle to define custom functions on the ref
        useImperativeHandle(ref, () => ({
            reset: () => {
                if (itiRef.current && inputRef.current) {
                    itiRef.current.setNumber('')
                    inputRef.current.value = ''
                    validatePhoneNumber()
                }
            },
        }))

        useEffect(() => {
            if (inputRef.current) {
                const scriptURL = `${window.location.origin}/static/intl-tel-input-utils.min.js`
                itiRef.current = intlTelInput(inputRef.current, {
                    utilsScript: scriptURL,
                    preferredCountries: ['us', 'ca', 'mx'],
                    nationalMode: false,
                    separateDialCode: true,
                })

                const phoneMask = new Inputmask({
                    mask: '(999) 999-9999',
                    placeholder: '',
                    onBeforeMask: function (value, opts) {
                        return value.replace(/\D/g, '') // Preprocess input to remove non-digits
                    },
                })

                phoneMask.mask(inputRef.current)

                return () => {
                    if (itiRef.current) {
                        itiRef.current.destroy()
                    }
                }
            }
        }, [])

        const validatePhoneNumber = useCallback(() => {
            if (itiRef.current) {
                const phoneNumber = itiRef.current.getNumber() || ''
                const errorCode = itiRef.current.getValidationError()
                const countryCode = itiRef.current.getSelectedCountryData().iso2.toUpperCase()

                let valid = false
                let message = ''
                switch (errorCode) {
                    case 0:
                        valid = true
                        message = ''
                        break
                    case 2:
                        message = 'Number is too short'
                        break
                    case 3:
                        message = 'Number is too long'
                        break
                    default:
                        message = 'Number is invalid'
                        break
                }

                handleChange({
                    value: phoneNumber,
                    valid,
                    errorCode,
                    errorMessage: message,
                    countryCode,
                })
            }
        }, [handleChange])

        useEffect(() => {
            const currentInput = inputRef.current
            if (currentInput) {
                currentInput.addEventListener('countrychange', validatePhoneNumber)
                return () => {
                    currentInput.removeEventListener('countrychange', validatePhoneNumber)
                }
            }
        }, [validatePhoneNumber])

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            validatePhoneNumber()
        }

        useEffect(() => {
            if (initialValue) {
                itiRef.current.setNumber(initialValue || '')

                setTimeout(() => {
                    validatePhoneNumber()
                }, 500)
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [initialValue])

        return (
            <div className={cn(`${wrapperClassName} input-wrapper iti-wrapper`)}>
                <label className="select-label">{label}</label>
                <input
                    ref={inputRef}
                    type="tel"
                    onChange={handleInputChange}
                    className={cn('phone-input', { 'input-error': errorMessage })}
                    placeholder={rest.placeholder || '(999) 999-9999'}
                    {...rest}
                />
                {errorMessage && <span className="error-message">{errorMessage || ''}</span>}
            </div>
        )
    },
)

export default InputPhone
