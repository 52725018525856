import React, { FC, useEffect } from 'react'
import { HttpV2, setHttpV2Token } from '@shared/apiv2/api'
import { updateLoggedState, useUserState } from '@/store/user'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PSMOrderChat } from '@/pages/PSM/OrderChat/PSMOrderChat'
import { MessageBoard } from '@/pages/PSM/MessageBoard/MessageBoard'
import { Loader } from '@/shared/ui/Loader'
import { QuoteCustomerForm } from '@/pages/PSM/Bid/ui/QuoteCustomer/QuoteCustomerForm'

export const PSMRoutes: FC = () => {
    const [loading, setLoading] = React.useState(true)
    const { dispatch } = useUserState()
    const setupToken = async () => {
        // read psmToken from URL
        const urlParams = new URLSearchParams(window.location.search)
        const psmToken = urlParams.get('psmToken') || localStorage.getItem('psmToken')
        if (!psmToken) {
            alert('No access found')
        } else {
            // set token to local storage
            localStorage.setItem('psmToken', psmToken!)
            setHttpV2Token(psmToken!)

            // remove token from URL
            urlParams.delete('psmToken')
            window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`)

            const { data: myData } = await HttpV2.get('/auth/me')

            updateLoggedState(myData, dispatch)
        }
        setLoading(false)
    }

    useEffect(() => {
        setupToken()
    }, [])

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        )
    }

    return (
        <Routes>
            <Route path="/order/:key/chat" element={<PSMOrderChat />} />
            <Route path="/bid/:key/quote" element={<QuoteCustomerForm />} />
            <Route path="/message-board" element={<MessageBoard />} />
            <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
    )
}
