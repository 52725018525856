import React, { useRef, useState } from 'react'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger } from '@/shared/ui/Accordion'
import { InChevronDown } from '@/shared/ui/Icons/InChevronDown'
import { Button } from '@/shared/ui/Button'
import { Quote, QuoteStatus } from '@/entities/quotes/model'
import { OrderQuoteBreakdown } from '../OrderQuoteBreakdown'
import { useAppSelector } from '@/app/store'
import { QuoteRequoteModal } from './QuoteRequoteModal'
import { QuoteAcceptModal } from './QuoteAcceptModal'
import { Separator } from '@/shared/ui/Separator'

export const OrderQuoteDemandList = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const quoteRef = useRef<Quote | null>(null)

    /** Control dialogs open state */
    const [requoteDialogOpen, setRequoteDialogOpen] = useState(false)
    const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const canManageQuote = order.JBHuntAllowed === undefined ? true : order.JBHuntAllowed

    return (
        <div className="space-y-4">
            <span className="text-2xl font-semibold">Quotes</span>
            <AccordionRoot
                type="multiple"
                defaultValue={order.bids.length === 1 ? [order.bids[0].id.toString()] : []}
                className="flex flex-col gap-5 border border-border rounded-xl bg-background-tertiary p-4"
            >
                {order.bids.map((quote, quoteIndex) => (
                    <React.Fragment key={quoteIndex}>
                        {quoteIndex !== 0 && <Separator />}
                        <AccordionItem value={quote.id.toString()} className="border-0 w-full">
                            <div className="flex flex-col sm:flex-row justify-between gap-x-3 gap-y-1">
                                <AccordionTrigger
                                    className="!text-xl !font-semibold flex w-fit max-w-full overflow-hidden gap-2"
                                    icon={null}
                                >
                                    <div className="!text-xl !font-semibold overflow-hidden text-nowrap text-ellipsis">
                                        {quote.warehouseAddress}
                                    </div>
                                    <InChevronDown
                                        size={20}
                                        className="text-foreground-tertiary mr-1 shrink-0 transition-transform duration-200"
                                    />
                                </AccordionTrigger>
                                {quote.status === QuoteStatus.QUOTED && canManageQuote && (
                                    <div className="shrink-0 w-full flex gap-3 sm:w-fit">
                                        <Button
                                            variant="outlined"
                                            className="border-danger text-danger hover:bg-danger/10 px-6 basis-0 flex-grow shrink-0 md:flex-grow-0 md:basis-auto"
                                            onClick={() => {
                                                quoteRef.current = quote
                                                setRequoteDialogOpen(true)
                                            }}
                                            disabled={isLoading}
                                        >
                                            Requote
                                        </Button>
                                        <Button
                                            className="px-6 flex-grow basis-0 shrink-0 md:flex-grow-0 md:basis-auto"
                                            onClick={() => {
                                                quoteRef.current = quote
                                                setAcceptDialogOpen(true)
                                            }}
                                            disabled={isLoading}
                                        >
                                            Accept Quote
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <AccordionContent className="p-0">
                                <div className="border border-border rounded-xl bg-background-secondary p-3 mt-3">
                                    <OrderQuoteBreakdown quote={quote} />
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    </React.Fragment>
                ))}
            </AccordionRoot>

            {/** Accept Quote Dialog */}
            <QuoteAcceptModal
                open={acceptDialogOpen}
                onOpenChange={setAcceptDialogOpen}
                quoteRef={quoteRef}
                order={order}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />

            {/** Requote Dialog */}
            <QuoteRequoteModal
                open={requoteDialogOpen}
                onOpenChange={setRequoteDialogOpen}
                quoteRef={quoteRef}
                order={order}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        </div>
    )
}
