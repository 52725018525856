import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Button } from '@/shared/ui/Button'
import { ContentState, EditorState } from 'draft-js'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { Account } from '@/entities/accounts/model'
import { toast } from 'react-toastify'
import { cn } from '@/shared/utils/cn'
import { useFormik } from 'formik'

import * as Yup from 'yup'
import { Quote, QuoteType } from '@/entities/quotes/model'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'
import { QuoteSubmitModal } from './QuoteSubmitModal'
import { reviseQuote, submitQuote, updateQuote, setQuoteMode } from '../model'
import { CurrencyInput } from '@/shared/ui/CurrencyInput'

type OrderQuoteAllInSubmitProps = {
    mode?: undefined | 'default'
    submittedQuote?: never
}

type OrderQuoteAllInEditProps = {
    mode: 'edit' | 'revise'
    submittedQuote: Quote
}

type OrderQuoteAllInProps = OrderQuoteAllInSubmitProps | OrderQuoteAllInEditProps

type AllInQuoteForm = {
    editor: EditorState
    total: string
}

const AllInQuoteSchema = Yup.object().shape({
    total: Yup.number()
        .typeError('Total can be a number only.')
        .positive('Total can be a positive number only.')
        .max(99_999_999.99, 'Price exceeds the maximum allowed value')
        .required('Total cannot be empty.'),
    editor: Yup.object().test('test-editor', 'Please add a note for your quote.', (editor) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const editorState = editor as EditorState
        const quoteText = editorState.getCurrentContent().getPlainText()
        return !!quoteText
    }),
})

const toolbarOptions = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'history'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline'],
    },
}

export const QuoteAllIn = ({ mode, submittedQuote }: OrderQuoteAllInProps) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const dispatch = useAppDispatch()

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder
    const warehouse = useAppSelector((state) => state.orderQuoteSupply.selectedWarehouse) as Account

    const isEditMode = mode === 'edit' || mode === 'revise'

    const [submissionAttempted, setSubmissionAttempted] = useState(false)

    const form = useFormik<AllInQuoteForm>({
        /** Set editor and total values from quote if they are available. */
        initialValues: {
            editor:
                isEditMode && submittedQuote?.notes
                    ? EditorState.createWithContent(ContentState.createFromText(submittedQuote.notes))
                    : EditorState.createEmpty(),
            total: isEditMode && submittedQuote?.price ? String(submittedQuote.price) : '',
        },
        validationSchema: AllInQuoteSchema,
        validateOnChange: submissionAttempted,
        onSubmit: async (values) => {
            try {
                const notes = values.editor.getCurrentContent().getPlainText()
                const numericTotal = +values.total

                if (mode === 'edit' || mode === 'revise') {
                    const newQuote = {
                        orderKey: order.id,
                        quoteId: submittedQuote.id,
                        notes: notes,
                        price: numericTotal,
                    }

                    if (mode === 'edit') {
                        await dispatch(updateQuote(newQuote)).unwrap()
                        sendCustomEvent('all_in_quote_updated', newQuote)
                    } else {
                        await dispatch(reviseQuote(newQuote)).unwrap()
                        sendCustomEvent('all_in_quote_revised', newQuote)
                    }
                } else {
                    const newQuote = {
                        orderKey: order.id,
                        warehouseId: warehouse.id,
                        notes: notes,
                        price: numericTotal,
                        type: QuoteType.ALLIN,
                    }

                    await dispatch(submitQuote(newQuote)).unwrap()

                    sendCustomEvent('all_in_quote_created', newQuote)
                }

                toast(
                    `Your quote is successfully ${
                        mode === 'edit' ? 'updated' : mode === 'revise' ? 'revised' : 'submitted'
                    }.`,
                    { type: 'success' },
                )

                setConfirmDialogOpen(false)
            } catch (error: any) {
                console.error(error)
                let errorMessage = ''
                if (error?.message) {
                    errorMessage = error?.message
                } else {
                    errorMessage = `We could not ${
                        mode === 'edit' ? 'update' : mode === 'revise' ? 'revise' : 'submit'
                    } your quote. We are sorry!`
                }

                toast(errorMessage, {
                    type: 'error',
                })
            }
        },
    })

    /** Confirm dialog controlled state */
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const onConfirmDialogOpenChange = async (open: boolean) => {
        if (!open) {
            setConfirmDialogOpen(open)
            return
        }

        setSubmissionAttempted(true)
        const validateResult = await form.validateForm()
        if (Object.keys(validateResult).length <= 0) {
            setConfirmDialogOpen(open)
        }
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Editor
                editorState={form.values.editor}
                onEditorStateChange={(editorState) => {
                    form.setFieldValue('editor', editorState)
                }}
                wrapperClassName={cn(
                    'border border-border rounded-md bg-background-secondary',
                    form.errors.editor && 'border-danger',
                )}
                toolbarClassName="!min-h-[56px] !border-0 !border-b !border-border !rounded-none !rounded-t-md overflow-visible"
                editorClassName="!h-[224px] px-4 py-3 cursor [&_.public-DraftStyleDefault-block]:!m-0 *:!h-auto"
                editorStyle={{
                    wordBreak: 'break-word',
                }}
                toolbar={toolbarOptions}
            />
            {form.errors.editor ? <div className="mt-1 text-sm text-danger">{form.errors.editor}</div> : null}

            <div className="mt-5 flex justify-end gap-3 flex-col sm:flex-row">
                <div className="flex-grow lg:max-w-[360px]">
                    <CurrencyInput
                        label="Estimated Total"
                        id="total"
                        name="total"
                        value={form.values.total}
                        onValueChange={({ value }) => form.setFieldValue('total', value)}
                        allowNegative={false}
                        decimalScale={2}
                        className={cn(form.errors.total && 'border-danger')}
                        labelClassName={cn(form.errors.total && 'text-danger')}
                        prefix="$"
                    />
                    {form.errors.total ? <div className="mt-1 text-sm text-danger">{form.errors.total}</div> : null}
                </div>

                <div className="flex gap-3">
                    {isEditMode ? (
                        <Button
                            variant="outlined"
                            type="button"
                            onClick={() => dispatch(setQuoteMode('default'))}
                            className="basis-0 flex-grow sm:basis-auto sm:flex-grow-0"
                        >
                            Cancel
                        </Button>
                    ) : null}
                    <QuoteSubmitModal
                        open={confirmDialogOpen}
                        onOpenChange={onConfirmDialogOpenChange}
                        isLoading={form.isSubmitting}
                        onSubmit={form.submitForm}
                    />
                </div>
            </div>
        </form>
    )
}
