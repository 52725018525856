import { Quote } from '@/entities/quotes/model'

type GetRevisedMinimumCharge = {
    quote: Quote
    revisedQuote?: Quote
}

export const getRevisedMinimumCharge = ({ quote, revisedQuote }: GetRevisedMinimumCharge) => {
    if (!quote.sellPrice || !revisedQuote?.sellPrice) return null
    if (quote.sellPrice === revisedQuote.sellPrice) return null

    if (quote.sellPrice > revisedQuote.sellPrice) {
        return {
            value: revisedQuote.sellPrice,
            status: 1,
        }
    } else if (quote.sellPrice < revisedQuote.sellPrice) {
        return {
            value: revisedQuote.sellPrice,
            status: -1,
        }
    }

    return null
}
