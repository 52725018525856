import React from 'react'
import {
    StandardQuoteRevisedChanges,
    StandardQuoteRevisedField,
    StandardQuoteRevisedModifier,
    StandardQuoteRevisedType,
} from '../model'
import { CurrencyDisplay } from '@/shared/ui/CurrencyInput'
import { cn } from '@/shared/utils/cn'

type RenderFieldProps = {
    changes: StandardQuoteRevisedChanges
    field: StandardQuoteRevisedField

    renderRevisedType?: (type: StandardQuoteRevisedType | undefined) => React.ReactNode
    renderRevisedModifier?: (modifier: StandardQuoteRevisedModifier | undefined) => React.ReactNode
}

export const RenderField = ({ changes, field, renderRevisedModifier, renderRevisedType }: RenderFieldProps) => {
    if (field === 'price' && changes.price) {
        return (
            <CurrencyDisplay
                value={changes.price.value}
                className={cn('text-base font-semibold', changes.price.status !== 0 && 'line-through')}
            />
        )
    }

    if (field === 'type') {
        if (renderRevisedType) {
            return <>{renderRevisedType(changes.type)}</>
        }
        if (changes.type?.value) {
            return (
                <>
                    <span className="text-sm shrink-0 text-foreground-tertiary">/</span>
                    <span className={cn('font-medium capitalize', changes.type.status !== 0 && 'line-through')}>
                        {changes.type.value}
                    </span>
                </>
            )
        }
    }

    if (field === 'modifier') {
        if (renderRevisedModifier) {
            return <>{renderRevisedModifier(changes.modifier)}</>
        }
        if (changes.modifier?.value) {
            return (
                <>
                    <span className="text-sm shrink-0 text-foreground-tertiary">/</span>
                    <span className={cn('font-medium capitalize', changes.modifier.status !== 0 && 'line-through')}>
                        {changes.modifier.value}
                    </span>
                </>
            )
        }
    }

    return null
}
