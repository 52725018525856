import React from 'react'
import { LoadboardCategory } from '@/entities/loadboard-orders/model'
import { Separator } from '@/shared/ui/Separator'
import { Button } from '@/shared/ui/Button'
import { FilterSearch } from '@/pages/LoadBoard/ui/LoadBoardFilters/FilterSearch'
import { FilterLocation } from '@/pages/LoadBoard/ui/LoadBoardFilters/FilterLocation'
import { FilterDistance } from '@/pages/LoadBoard/ui/LoadBoardFilters/FilterDistance'
import { FilterWarehouse } from '@/pages/LoadBoard/ui/LoadBoardFilters/FilterWarehouse'
import { FilterDates } from '@/pages/LoadBoard/ui/LoadBoardFilters/FilterDates'
import { FilterTogglers } from '@/pages/LoadBoard/ui/LoadBoardFilters/FilterTogglers'
import { FilterParams } from '@/pages/LoadBoard/model/types'

type LoadBoardFiltersProps = {
    category: LoadboardCategory
    onParamsChanged: (
        params: Partial<FilterParams>,
        options?: {
            invokeImmediately?: boolean
            skipSettingParams?: boolean
        },
    ) => void
    onClear: () => void
    showSearchInput?: boolean
    showResetButton?: boolean
}

export const LoadBoardFilters = ({
    category,
    onParamsChanged,
    onClear,
    showSearchInput = false,
    showResetButton = false,
}: LoadBoardFiltersProps) => {
    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between px-3 pt-3">
                <div className="text-xl font-semibold">Filters</div>
                {showResetButton ? (
                    <Button variant="link" onClick={onClear}>
                        Clear
                    </Button>
                ) : null}
            </div>
            {showSearchInput ? (
                <>
                    <FilterSearch onParamsChanged={onParamsChanged} />
                    <Separator />
                </>
            ) : null}
            {category === 'new-opportunities' ? (
                <>
                    <FilterLocation onParamsChanged={onParamsChanged} />
                    <FilterDistance onParamsChanged={onParamsChanged} />
                </>
            ) : (
                <FilterWarehouse onParamsChanged={onParamsChanged} />
            )}
            <Separator />
            <FilterDates onParamsChanged={onParamsChanged} />
            <Separator />
            <FilterTogglers onParamsChanged={onParamsChanged} />
        </div>
    )
}
