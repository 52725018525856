import React from 'react'
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger } from '@/shared/ui/Accordion'
import { Toggle } from '@/shared/ui/Toggle'
import { Separator } from '@/shared/ui/Separator'
import {
    RfqService,
    serviceIcons,
    RfqFreightEnv,
    RfqFreightEnvLabel,
    RfqCertification,
    RfqCertificationLabel,
} from '@/entities/rfq/model'
import { FilterParams } from '@/pages/LoadBoard/model/types'
import { useAppSelector } from '@/app/store'

type FilterTogglersProps = {
    onParamsChanged: (params: Partial<FilterParams>) => void
}

export const FilterTogglers = ({ onParamsChanged }: FilterTogglersProps) => {
    const services = useAppSelector((state) => state.loadboard.filters?.services)
    const freightEnvironment = useAppSelector((state) => state.loadboard.filters?.freightEnvironment)
    const certifications = useAppSelector((state) => state.loadboard.filters?.certifications)

    const params = {
        services,
        certifications,
    }

    const onPressed = (pressed: boolean, value: string, field: 'services' | 'certifications') => {
        if (pressed) {
            if (!params[field]) {
                onParamsChanged({ [field]: [value] })
            } else if (!params[field]!.includes(value)) {
                onParamsChanged({ ...params, [field]: [...params[field]!, value] })
            }
        } else {
            if (params[field] && params[field]!.includes(value)) {
                const newSelected = [...params[field]!].filter((v) => v !== value)
                onParamsChanged({ [field]: newSelected })
            }
        }
    }

    return (
        <AccordionRoot
            type="multiple"
            defaultValue={[
                params.services?.length ? 'services' : '',
                freightEnvironment ? 'freightEnvironment' : '',
                params.certifications?.length ? 'certifications' : '',
            ]}
            className="!m-0"
        >
            <AccordionItem value="services" className="border-0 p-3 space-y-2">
                <AccordionTrigger className="p-0 h-auto !text-sm !font-medium">Services</AccordionTrigger>
                <AccordionContent className="flex flex-col gap-2">
                    {Object.values(RfqService).map((service) => {
                        if (service === RfqService.Handling) return null
                        const Icon = serviceIcons[service]
                        return (
                            <Toggle
                                key={service}
                                pressed={params.services?.includes(service) ?? false}
                                onPressedChange={(pressed) => onPressed(pressed, service, 'services')}
                                variant="outlined"
                                className="!text-sm h-auto p-2 border-transparent hover:bg-accent-light/50 justify-start gap-3 text-left ring-transparent focus-visible:bg-accent-light/50 data-[state=on]:focus-visible:bg-accent-light/70"
                            >
                                {Icon ? <Icon size={20} className="text-foreground-secondary" /> : null}
                                {service}
                            </Toggle>
                        )
                    })}
                </AccordionContent>
            </AccordionItem>
            <Separator />
            <AccordionItem value="freightEnvironment" className="border-0 p-3 space-y-2">
                <AccordionTrigger className="p-0 h-auto !text-sm !font-medium">Freight Environment</AccordionTrigger>
                <AccordionContent className="flex flex-col gap-2">
                    {Object.values(RfqFreightEnv).map((environment) => {
                        if (environment === RfqFreightEnv.TempControlled) return null

                        return (
                            <Toggle
                                key={environment}
                                pressed={freightEnvironment === environment}
                                onPressedChange={(pressed) => {
                                    if (!pressed) onParamsChanged({ freightEnvironment: null })
                                    else onParamsChanged({ freightEnvironment: environment })
                                }}
                                variant="outlined"
                                className="!text-sm h-auto p-2 border-transparent hover:bg-accent-light/50 justify-start gap-3 text-left ring-transparent focus-visible:bg-accent-light/50 data-[state=on]:focus-visible:bg-accent-light/70"
                            >
                                {RfqFreightEnvLabel[environment]}
                            </Toggle>
                        )
                    })}
                </AccordionContent>
            </AccordionItem>
            <Separator />
            <AccordionItem value="certifications" className="border-0 p-3 space-y-2">
                <AccordionTrigger className="p-0 h-auto !text-sm !font-medium">Certifications</AccordionTrigger>
                <AccordionContent className="flex flex-col gap-2">
                    {Object.values(RfqCertification).map((certification) => (
                        <Toggle
                            key={certification}
                            pressed={params.certifications?.includes(certification) ?? false}
                            onPressedChange={(pressed) => onPressed(pressed, certification, 'certifications')}
                            variant="outlined"
                            className="!text-sm h-auto p-2 border-transparent hover:bg-accent-light/50 justify-start gap-3 text-left ring-transparent focus-visible:bg-accent-light/50 data-[state=on]:focus-visible:bg-accent-light/70"
                        >
                            {RfqCertificationLabel[certification]}
                        </Toggle>
                    ))}
                </AccordionContent>
            </AccordionItem>
        </AccordionRoot>
    )
}
