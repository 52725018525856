import React, { useEffect, useState } from 'react'
import { Slider } from '@/shared/ui/Slider'
import { useAppSelector } from '@/app/store'
import { FilterParams } from '@/pages/LoadBoard/model/types'
import { Badge } from '@/shared/ui/Badge'
import { Separator } from '@/shared/ui/Separator'

type FilterDistanceProps = {
    onParamsChanged: (params: Partial<FilterParams>) => void
}

export const FilterDistance = ({ onParamsChanged }: FilterDistanceProps) => {
    const locationDistance = useAppSelector((state) => state.loadboard.filters!.locationDistance)

    useEffect(() => {
        setDistance(locationDistance ?? 50)
    }, [locationDistance])

    const [distance, setDistance] = useState(locationDistance ?? 50)

    return (
        <div className="space-y-3 px-3">
            <Separator className="bg-border/70" />
            <div className="font-normal text-foreground-secondary flex gap-1 items-center justify-between text-sm">
                Search Distance:
                <Badge variant="secondary" className="w-14 p-0 px-1 py-0.5 justify-center">
                    {distance} mi
                </Badge>
            </div>
            <div>
                <Slider
                    min={1}
                    max={100}
                    value={[distance]}
                    onValueChange={(v) => setDistance(v[0])}
                    onValueCommit={(v) => onParamsChanged({ locationDistance: v[0] })}
                    thumbClassName="w-4 h-4"
                />
            </div>
        </div>
    )
}
